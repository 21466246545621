import React from 'react';
import Tab from '../../Shared/Tab';
import OfferCardSlider from './OfferCardSlider';
import { useSelector } from 'react-redux';

export default function CurrencyTab({
  setIsLoading,
  isLoading,
  type,
  setType,
  keys,
  width,
  isToggle,
  salesType,
}) {
  const list = useSelector((state) => state.asset.list);

  function test() {
    return keys.findIndex((el) => list[el]?.name === type);
    // switch (type) {
    //     case 'Bitcoin':
    //         return 0
    //     case 'Ethereum':
    //         return 1
    //     case 'USDT-ETH':
    //         return 2
    //     case 'USDC':
    //         return 3
    //     case 'TRX':
    //         return 4
    //     case 'USDT-TRX':
    //         return 5
    //     case 'Remitco':
    //         return 6
    // }
  }

  return (
    <>
      <Tab
        color={'$tabColor'}
        containerCss={{
          width: '100%',
        }}
        css={{
          justifyContent: 'center',
          marginBottom: 0,
          '@fold': {
            flexWrap: 'nowrap',
          },
        }}
        titleTxtCss={{
          fontSize: 16,
          fontWeight: 700,
          paddingLeft: 0,
          paddingRight: 0,
          '@mb': {
            fontSize: 10,
          },
          '@fold': {
            fontSize: 8,
          },
        }}
        commonCss={{
          paddingLeft: 20,
          paddingRight: 20,
          '@mb': {
            padding: '0px 8px',
          },
          '@fold': {
            padding: '0px 5px',
          },
        }}
        init={test}
        tabChangeAction={(tab) => {
          keys?.forEach((el, i) => {
            if (i === tab) setType(list[el].name);
          });
          // switch (tab) {
          //         case 0:
          //             setType('Bitcoin');
          //             break;
          //         case 1:
          //             setType('Ethereum');
          //             break;
          //         case 2:
          //             setType('USDT-ETH');
          //             break;
          //         case 3:
          //             setType('USDC');
          //             break;
          //         case 4:
          //             setType('TRX');
          //             break;
          //         case 5:
          //             setType('USDT-TRX');
          //             break;
          //         case  6:
          //             setType('Remitco');
          //             break;
          //     }
        }}
      >
        {keys?.map((el, i) => {
          return (
            <Tab.Item
              key={`index - ${i}`}
              title={list[el].name}
              content={
                <OfferCardSlider
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isToggle={isToggle}
                  width={width}
                  tagTitle={el}
                  keyAssetId={keys[i]}
                  salesType={salesType}
                />
              }
            />
          );
        })}
      </Tab>
      {type?.length === 0 && (
        <OfferCardSlider
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          isToggle={isToggle}
          width={width}
          salesType={salesType}
        />
      )}
    </>
  );
}
