import { styled } from '../stitches.config';
import { Div } from './Div';

export const Row = styled(Div, {
  flexDirection: 'row',
  width: 'auto',
  flexWrap: 'wrap',
  '&[disabled]': {
    opacity: '20%',
  },
});
