import { useSelector } from 'react-redux';
import { Col } from '../../../styles/Layout/Col';
import Price from '../../Shared/Price';
import { Row } from '../../../styles/Layout/Row';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Div } from '../../../styles/Layout/Div';
import React from 'react';

function OfferPriceComponent({ item, asset, type, hoverGuide, marginTop = 0 }) {
  let currency = useSelector((state) => state.asset?.currency);

  let usd = {
    symbol: 'USD',
    unit: '$',
    price: 1,
  };

  let price = 0;
  if (item?.priceType === 'market') {
    price = asset?.price * (1 + item?.marginPrice * 0.01);
  } else if (item?.priceType === 'fixed') {
    price = asset?.price + item?.fixedPrice;
  }

  let per = calculatePercentage(asset?.price, price);
  let color = per < 0 ? '#71A1FF' : '#FF797C';

  function calculatePercentage(originalPrice, variationPrice) {
    let percentage = (variationPrice / originalPrice) * 100;
    return (Math.round(percentage * 100) / 100 - 100).toFixed(2);
  }

  return (
    <Col
      css={{
        width: '100%',
        alignItems: 'center',
        gap: 5,
        '@mb': { alignItems: 'center' },
      }}
    >
      <Price
        css={{
          '@fold': {
            fontSize: 16,
          },
        }}
        h5
        amount={price}
        symbol
        asset={usd}
        color={'$text'}
      />
      <Row
        css={{
          alignItems: 'center',
          justifyContent: 'center',
          gap: 7,
          minHeight: 30,
          minWidth: 200,
          '@mb': { justifyContent: 'normal' },
          '@fold': {
            fontSize: 12,
            minWidth: 100,
          },
        }}
      >
        <Txt
          b5
          css={{
            width: '100%',
            color: '$subTextGray',
            '@fold': {
              fontSize: 12,
            },
          }}
        >
          {`1 ${currency?.symbol} = ${(1 - per / 100).toFixed(2)} ${currency?.symbol} of ${asset?.symbol}`}
        </Txt>
        <Row
          css={{
            minWidth: 50,
            gap: 7,
            alignItems: 'center',
          }}
        >
          <Div
            css={{
              height: 25,
            }}
          >
            {per >= 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M12 19V5M12 5L5 12M12 5L19 12"
                  stroke={color}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 5V19M12 19L19 12M12 19L5 12"
                  stroke={color}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </Div>
          <Txt
            b5
            css={{
              fontWeight: 700,
              color: color,
            }}
          >
            {Math.abs(per)} %
          </Txt>{' '}
          {hoverGuide}
        </Row>
      </Row>
    </Col>
  );
}

export default OfferPriceComponent;
