import { handleActions } from 'redux-actions';
import {
  assetUpdateComplete,
  assetUpdateStart,
  updateBalanceComplete,
  updateCurrencyComplete,
} from '../actions/assetAction';

export const assetDefault = {
  currency: {
    symbol: 'USD',
    unit: '$',
    price: 1.0,
    assetId: 6,
  },
  list: {},
  listUpdated: 0,
  balance: [],
  loading: false,
};

export const assetReducer = handleActions(
  {
    [assetUpdateStart](state, data) {
      return {
        ...state,
        loading: true,
      };
    },
    [assetUpdateComplete](state, data) {
      for (let i = 0; i < data?.payload?.length; i++) {
        let item = data?.payload[i];
        state.list[item?.assetId] = item;
      }
      state.listUpdated = new Date().getTime();
      return {
        ...state,
        loading: false,
      };
    },
    [updateBalanceComplete](state, data) {
      console.log(data?.payload);
      // TODO: XRP 제외 주석
      state.balance = data?.payload.filter((item) => item?.assetId !== 7);
      return {
        ...state,
      };
    },
    [updateCurrencyComplete](state, data) {
      console.log(data?.payload);
      state.currency = data?.payload[0];
      return {
        ...state,
      };
    },
  },
  assetDefault,
);
