import React from 'react';
import Tab from '../Tab';
import { useTranslation } from 'react-i18next';
import NotificModerator from './NotificModerator';
import NotificInbox from './NotificInbox';
import { Div } from '../../../styles/Layout/Div';
function NotificTabContainer({ setVisible, navigate }) {
  const { t } = useTranslation('common');

  return (
    <Div css={{ width: '100%', alignItems: 'stretch' }}>
      <Tab
        css={{ marginBottom: 0, borderBottom: '1px solid $opacity' }}
        init={0}
      >
        <Tab.Item
          fontSize={14}
          title={t('notific_Inbox')}
          content={
            <NotificInbox navigate={navigate} setVisible={setVisible} t={t} />
          }
        />
        <Tab.Item
          fontSize={14}
          title={t('notific_moderate')}
          content={
            <NotificModerator
              t={t}
              navigate={navigate}
              setVisible={setVisible}
            />
          }
        />
      </Tab>
    </Div>
  );
}

export default NotificTabContainer;
