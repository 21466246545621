import { useTranslation } from 'react-i18next';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Input } from '../../StyleGuide/Components/Input';
import InputPasswordBox from '../../Shared/InputPasswordBox/InputPasswordBox';
import React, { useEffect, useRef, useState } from 'react';
import { Row } from '../../../styles/Layout/Row';
import { CountryPicker } from '../../Shared/CountryPicker/CountryPicker';
import { getCountryAll } from '../../../api/countryService';
import PasswordCheckBar from './PasswordCheckBar';
import NetworkButton from '../../Shared/Buttons/NetworkButton';
import { checkDuplicatedPhone, signUpPhone } from '../../../api/userService';
import { useNavigate } from 'react-router-dom';
import { endLoading, startLoading } from '../../../redux/actions/loadingAction';
import { useDispatch } from 'react-redux';
import { userLoginFromJwt } from '../../../redux/actions/userAction';
import { Col } from '../../../styles/Layout/Col';
import InputBox from '../../Shared/InputBox/InputBox';
import { createToast } from '../../../redux/actions/toastAction';

export default function PhoneForm({
  refererCodeType,
  refererCode,
  refererRef,
  refererCodeHandle,
  passwordRegex,
  checkValidate,
  pwd,
  setPwd,
  setValidate,
  validate,
  inputPhoneValue,
  setInputPhoneValue,
  matchedPhone,
  refer,
  setRefer,
  isReferralUserInfo,
}) {
  const { t } = useTranslation();

  const [list, setList] = useState([]);
  const [country, setCountry] = useState({});
  const [phoneErr, setErr] = useState('');
  const [errorState, setErrorState] = useState(false);
  const dispatch = useDispatch();

  const countryRef = useRef(country);
  const phoneRef = useRef(inputPhoneValue);
  const pwdRef = useRef(pwd);
  const navigate = useNavigate();

  function phoneNumberInputOnBlur() {
    if (!matchedPhone) {
      setErr(t('phone_valid_guide'));
    } else if (matchedPhone) {
      setErr('');
    }
  }

  const handleInputChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setInputPhoneValue(onlyNums);
    phoneRef.current = onlyNums;
    if (!matchedPhone) {
      setErr(t('phone_valid_guide'));
    } else if (matchedPhone) {
      setErr('');
    }
  };

  async function phoneNumberHandle() {
    phoneNumberInputOnBlur();
    await checkDuplicate();
  }

  function passwordHandle(pass) {
    checkValidate(pass);
    setPwd(pass);
    pwdRef.current = pass;
  }

  async function callPhoneSignupProcess() {
    if (signUpValidate() === false) {
      return;
    }

    dispatch({ type: startLoading });

    await phoneSignUp();
    // if (window?.captchaObj) {
    //     window?.captchaObj?.showCaptcha();
    // } else {
    //     dispatch({
    //         type: createToast, payload: {
    //             msg: 'not loaded object',
    //             duration: 2000,
    //             success: false,
    //         }
    //     });
    //     return false;
    // }
  }

  function signUpValidate() {
    if (phoneErr.length > 0) return false;
    if (inputPhoneValue.length === 0) {
      setErr(t('phone_valid_guide'));
      return false;
    }
    if (validate < 3) {
      return false;
    }
    if (pwd.length === 0) {
      setValidate(1);
      return false;
    }
    if (!matchedPhone) {
      setErr(t('phone_valid_guide'));
      return false;
    }
    if (!passwordRegex) {
      return false;
    }

    return true;
  }

  async function checkDuplicate() {
    const result = await checkDuplicatedPhone(inputPhoneValue);
    if (result === true) {
      setErr(t('duplicated_phone'));
    }
  }

  async function phoneSignUp() {
    //let captcha = window.captchaObj.getValidate();
    let success = await signUpPhone(
      phoneRef.current,
      pwdRef.current,
      countryRef.current,
      refererRef.current,
      'captcha',
    );
    dispatch({ type: endLoading });
    if (!!success === false) {
      dispatch({
        type: createToast,
        payload: {
          msg: t('signup_fail'),
          duration: 2000,
          success: false,
        },
      });
    } else {
      dispatch(userLoginFromJwt(success));
      navigate('/verify');
    }
  }

  useEffect(() => {
    setInputPhoneValue('');
    getCountryAll().then((res) => {
      setList(res);
      let country = res?.find((el) => {
        return el.countryCode === 'KR';
      });
      countryRef.current = country?.countryPhoneCode;
      setCountry(country);
    });
  }, []);

  useEffect(() => {
    if (phoneErr?.length > 0) {
      setErrorState(true);
    } else {
      setErrorState(false);
    }
  }, [phoneErr]);

  return (
    <Col css={{ width: '100%' }}>
      <Txt css={{ marginLeft: 15 }} b6>
        {t('phone')}
      </Txt>
      <Row
        css={{
          marginTop: 4,
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 445,
          width: '100%',
          height: 45,
          '@mb': {
            maxWidth: '100%',
          },
        }}
      >
        <CountryPicker
          list={list}
          setList={setList}
          country={country}
          setCountry={(code) => {
            countryRef.current = code?.countryPhoneCode;
            setCountry(code);
          }}
          css={{
            position: 'absolute',
            left: 15,
          }}
        />
        <Input
          onBlur={phoneNumberHandle}
          error={errorState}
          onChange={handleInputChange}
          autoComplete="off"
          type="text"
          value={inputPhoneValue}
          css={{
            maxWidth: 445,
            width: '100%',
            paddingLeft: 105,
            fontFamily: 'SpoqaHanSansNeo-Regular',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '15px',
            /* identical to box height, or 21px */
            letterSpacing: '-0.04em',
            fontFeatureSettings: "'pnum' on, 'lnum' on",
            color: '$textWeak',
            '@mb': { maxWidth: '100%' },
          }}
        />

        <Txt
          css={{
            position: 'absolute',
            left: 70,
          }}
          b4
        >
          +{country?.countryPhoneCode}
        </Txt>
      </Row>
      {errorState === true && (
        <Txt
          b6
          css={{
            color: '$lightRed',
            margin: 10,
          }}
        >
          {phoneErr}
        </Txt>
      )}
      <Txt
        css={{
          marginLeft: 15,
          marginTop: 20,
        }}
        b6
      >
        {t('create_password')}
      </Txt>
      <InputPasswordBox
        password={pwd}
        validate={validate}
        width={445}
        marginTop={4}
        autoComplete={'off'}
        setPassword={passwordHandle}
      />
      {/* 프로그레스 바 */}
      <PasswordCheckBar validateLevel={validate} />
      {refer && (
        <React.Fragment>
          <Txt
            css={{
              marginLeft: 15,
              marginTop: 20,
            }}
            b6
          >
            {t('referal_code_inputlogo')}
          </Txt>
          <InputBox
            css={{
              maxWidth: 445,
              width: '100%',
              padding: '11px 15px',
              marginTop: 4,
              '@mb': { maxWidth: '100%' },
            }}
            code={refererCodeType}
            isReferralUserInfo={isReferralUserInfo}
            max={6}
            id={refererCode}
            setId={refererCodeHandle}
          />
        </React.Fragment>
      )}
      {refer ? (
        <Txt
          onClick={() => setRefer(!refer)}
          css={{
            color: '$primary',
            marginTop: 15,
            cursor: 'pointer',
          }}
          e2
        >
          {t('referal_code_hide')}
        </Txt>
      ) : (
        <Txt
          onClick={() => setRefer(!refer)}
          css={{
            color: '$primary',
            marginTop: 15,
            cursor: 'pointer',
          }}
          e2
        >
          {t('register_code')}
        </Txt>
      )}
      <NetworkButton
        onClick={callPhoneSignupProcess}
        css={{
          maxWidth: 445,
          width: '100%',
          marginTop: 30,
          '@mb': { maxWidth: '100%' },
        }}
        lg
      >
        {t('register_createBtn')}
      </NetworkButton>
      {/*<Captcha captchaId={"f2151aa51b5b967bf76931d2e139e6f5"}*/}
      {/*         cancelCallback={() => {*/}
      {/*             dispatch({type: endLoading});*/}
      {/*             setPwd('');*/}
      {/*         }}*/}
      {/*         successCallback={phoneSignUp}/>*/}
    </Col>
  );
}
