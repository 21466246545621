import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { Div } from '../../styles/Layout/Div';

function LottieAnim({ width = 400, src, css, autoPlay = true, loop = true }) {
  const [animationData, setAnimationData] = useState(null);
  useEffect(() => {
    // Lottie JSON 데이터가 있는 URL
    const url = src;

    fetch(url)
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) => console.error(error));
  }, [src]);

  const defaultOptions = {
    loop: loop,
    autoplay: autoPlay,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Div
      css={{
        width: width,
        ...css,
      }}
    >
      {animationData && <Lottie options={defaultOptions} width={width} />}
    </Div>
  );
}

export default LottieAnim;
