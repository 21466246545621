import React from 'react';
import { Row } from '../../../../styles/Layout/Row';
import { Input } from '../../../StyleGuide/Components/Input';
import { Icon } from '../../../Shared/Icon';
import { useTranslation } from 'react-i18next';
import { formatDateToDot, getToday } from '../../../../util';

function TradeSidebarDateInput({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  const { t } = useTranslation('modal');

  const onStartHandle = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, '');
    if (value.length > 8) return;
    setValue(value, 'start');
  };

  const onEndHandle = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, '');
    if (value.length > 8) return;
    setValue(value, 'end');
  };

  function setValue(value, type) {
    const today = new Date();
    if (type === 'start') {
      if (!!endDate && value > endDate && value?.length === 8) {
        value = endDate;
      }
      setStartDate(value);
    } else {
      const year = parseInt(value?.substr(0, 4), 10);
      const month = parseInt(value?.substr(4, 2), 10) - 1; // 월은 0부터 시작하므로 1을 빼줍니다.
      const day = parseInt(value?.substr(6, 2), 10);
      const valueDate = new Date(year, month, day);
      if (valueDate > today) {
        value = getToday();
      }
      if (!!startDate && value < startDate && value?.length === 8) {
        setStartDate(value);
      }
      setEndDate(value);
    }
  }
  return (
    <Row
      css={{
        marginTop: 15,
        borderRadius: 10,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }}
    >
      <Input
        placeholder={t('sidebar_startdate')}
        onChange={onStartHandle}
        value={formatDateToDot(startDate)}
      />
      <Icon css={{ width: 24 }} src={'/images/icon-next.svg'} />
      <Input
        placeholder={t('sidebar_enddate')}
        onChange={onEndHandle}
        value={formatDateToDot(endDate)}
      />
    </Row>
  );
}

export default TradeSidebarDateInput;
