import { Page } from '../../styles/Layout/Page';
import React from 'react';
import { MainBg } from './components/MainBg';
import { OfferSection } from './components/OfferSection';
import { WaySection } from './components/WaySection';
import { MediaSection } from './components/MediaSection';
import { ReviewSection } from './components/ReviewSection';
import { FinancialSection } from './components/FinancialSection';
import { StartSection } from './components/StartSection';

export const MainPage = () => {
  const tbPadding = { '@tb': { paddingLeft: 20, paddingRight: 20 } };
  return (
    <Page>
      <MainBg />
      <OfferSection tbPadding={tbPadding} />
      <WaySection tbPadding={tbPadding} />
      <MediaSection />
      <ReviewSection />
      <FinancialSection tbPadding={tbPadding} />
      <StartSection tbPadding={tbPadding} />
    </Page>
  );
};
