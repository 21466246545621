import React, { useEffect, useState } from 'react';
import { Row } from '../../../../styles/Layout/Row';
import { styled } from '../../../../styles/stitches.config';
import { Txt } from '../../../StyleGuide/Components/Txt';
import { Icon } from '../../../Shared/Icon';
import { Button } from '../../../StyleGuide/Components/Button';
import { useTranslation } from 'react-i18next';
import { Excoinz } from '../../../Shared/Excoinz';
import { ContentsItem } from './NavContents.dt';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnimatedDiv } from '../../../../styles/Layout/AnimatedDiv';
import { LangPickerMb } from '../../../Shared/LangaugePicker/LangaugePicker.mb';
import { useSpring } from '@react-spring/web';
import { Col } from '../../../../styles/Layout/Col';
import {
  BuyContentsMb,
  MenuContentsMb,
  SellContentsMb,
} from './NavContents.mb';
import { useSelector } from 'react-redux';
import UserInterfaceMb from '../UserInterface/UserInterface.mb';
import NotificTabContainer from '../../../Shared/NotificationContainorModalTap/NotificTabContainer';
import UserIconItemMobile from '../UserInterface/UserIconItemMobile';

export const NavigateBarMb = () => {
  const { t } = useTranslation('common');
  const [isLanguageOpen, setIsLanguageOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [visibleNoti, setVisibleNoti] = useState(false);
  const [visibleUser, setVisibleUser] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const spring = useSpring({
    height: isLanguageOpen ? 330 : 0,
    right: open ? 0 : -320,
  });
  const isLogin = useSelector((state) => state.user.isLogin);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  useEffect(() => {
    setIsLanguageOpen(false);
  }, [open]);

  return (
    <NavBarContainer>
      <Excoinz />
      <Icon
        src={'/images/icon-hamburger.svg'}
        css={{
          padding: 20,
          paddingRight: 0,
          '&:active': {
            filter: 'brightness(0.8)',
          },
        }}
        onClick={() => {
          setOpen(true);
        }}
      />
      {open && (
        <AnimatedDiv
          css={{
            position: 'absolute',
            minHeight: '100vh',
            width: '100vw',
            backgroundColor: '$bg',
            top: 0,
            borderLeft: '1px solid $opacity',
            zIndex: 1000,
            overflowY: 'auto',
          }}
          style={{
            right: spring.right.to((val) => `${val}px`),
          }}
        >
          <Col
            css={{
              width: '100%',
              overflow: 'auto',
              height: '100vh',
              flexWrap: 'nowrap',
            }}
          >
            <Row
              css={{
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Icon
                src={'/images/icon-close.svg'}
                css={{
                  padding: 20,
                  '&:active': {
                    filter: 'brightness(0.8)',
                  },
                }}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Row>
            {isLogin ? (
              <UserInterfaceMb
                setVisibleNoti={setVisibleNoti}
                visibleNoti={visibleNoti}
                setVisibleUser={setVisibleUser}
                visibleUser={visibleUser}
              />
            ) : (
              <Row
                css={{
                  gap: 10,
                  justifyContent: 'center',
                  width: '100%',
                  flexWrap: 'nowrap',
                }}
              >
                <NavButton onClick={() => navigate('/login')} bordered>
                  {t('login')}
                </NavButton>
                <NavButton onClick={() => navigate('/register')}>
                  {t('join')}
                </NavButton>
                <LangPickerMb
                  height={45}
                  boxWidth={315}
                  isLanguageOpen={isLanguageOpen}
                  setIsLanguageOpen={setIsLanguageOpen}
                />
              </Row>
            )}
            <AnimatedDiv
              style={{
                minHeight: spring.height.to((val) => `${val}px`),
              }}
            />
            <Col
              css={{
                width: '100%',
                marginTop: 15,
              }}
            >
              {visibleNoti && (
                <AnimatedDiv
                  css={{
                    width: '100%',
                    background: '$bg',
                  }}
                >
                  <NotificTabContainer
                    navigate={navigate}
                    setVisible={setOpen}
                  />
                </AnimatedDiv>
              )}
              {visibleUser && (
                <UserIconItemMobile
                  navigate={navigate}
                  setVisibleUser={setVisibleUser}
                />
              )}
              <NavLabel label={t('buy')} url={'/offer/buy/bitcoin'}>
                <BuyContentsMb />
              </NavLabel>
              <NavLabel label={t('sell')} url={'/offer/sell/bitcoin'}>
                <SellContentsMb />
              </NavLabel>
              <NavLabel label={t('offer')} url={'/offer/create'} />
              <NavLabel label={t('wallet')} url={'/wallet'} />
              <NavLabel label={t('company').toUpperCase()} last={true}>
                <MenuContentsMb />
              </NavLabel>
              {/*<NavLabel label={t('convert')} url={"/offer/convert"}>*/}
              {/*    <ConvertContentsMb/>*/}
              {/*</NavLabel>*/}
            </Col>
          </Col>
        </AnimatedDiv>
      )}
    </NavBarContainer>
  );
};

const NavLabel = ({ children, label, last, url }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();

  const sp = useSpring({
    rotation: open ? 180 : 0,
    height: open ? 'auto' : 0,
  });

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Col
      css={{
        width: '100%',
        borderBottom: last ? '1px solid $opacity' : 'none',
        cursor: 'pointer',
      }}
    >
      <Row
        css={{
          justifyContent: 'space-between',
          height: 55,
          width: '100%',
          alignItems: 'center',
          borderTop: '1px solid $opacity',
          paddingLeft: 16,
          paddingRight: 16,

          '&:active': {
            background: '$boxBg',
          },
        }}
        onClick={() => {
          if (!!children === false) {
            navigate(url);
          }
          setOpen(!open);
        }}
      >
        <Txt
          d4
          css={{
            color: location.pathname.startsWith(url) ? '$primary' : '$text',
          }}
        >
          {label}
        </Txt>
        {!!children === true && (
          <AnimatedDiv
            style={{
              transform: sp.rotation.to((o) => `rotate(${o}deg)`),
            }}
          >
            <Icon src={'/images/icon-down.svg'} />
          </AnimatedDiv>
        )}
      </Row>
      {!!children === true && open && (
        <AnimatedDiv
          css={{
            paddingLeft: 16,
            paddingRight: 16,
            gap: 10,
            marginBottom: 25,
            width: '100%',
          }}
          style={{
            height: sp.height.to((o) => o),
          }}
        >
          {children}
        </AnimatedDiv>
      )}
    </Col>
  );
};

const NavButton = styled(Button, {
  height: 45,
});

const NavBarContainer = styled(Row, {
  position: 'fixed',
  width: '100vw',
  height: 75,
  zIndex: 100,
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderBottom: '1px solid $opacity',
  paddingLeft: 16,
  paddingRight: 18,
  backgroundColor: '$bg',
});
