import { request } from './base/apiCore';

export const getAddress = async (type) => {
  return await request({
    url: `/wallet/address/${type}`,
    method: 'POST',
  });
};
export const getSendTargetInfo = async (target) => {
  return await request({
    url: `/wallet/send/info`,
    method: 'POST',
    data: {
      target: target,
    },
  });
};
export const getUserBalance = async () => {
  return await request({
    url: '/wallet/balance',
    method: 'GET',
  });
};
export const getAllAddress = async () => {
  return await request({
    url: '/wallet/addresses',
    method: 'GET',
  });
};

export const getAddressBook = async (page, network) => {
  return await request({
    url: '/wallet/address-book',
    method: 'GET',
    params: {
      page: page,
      network: network,
    },
  });
};

export const getAddressBookSearch = async (search) => {
  return await request({
    url: '/wallet/address-book/search',
    method: 'GET',
    params: {
      search: search,
    },
  });
};

export const addAddressBook = async (label, address, network) => {
  return await request({
    url: '/wallet/address-book/add',
    method: 'POST',
    data: {
      address: address,
      label: label,
      network: network,
    },
  });
};

export const deleteAddressBook = async (bookId) => {
  return await request({
    url: '/wallet/address-book/delete',
    method: 'POST',
    data: {
      bookId: bookId,
    },
  });
};
