import { Div } from '../../../styles/Layout/Div';
import { Icon } from '../../Shared/Icon';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import { Row } from '../../../styles/Layout/Row';
import { useNavigate } from 'react-router-dom';

export const FinancialCard = ({ fees, vendor, wallet, icon, title, view }) => {
  const { t } = useTranslation('main');
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (vendor) return navigate('/vendor');
    if (wallet) return navigate('/wallet');
    if (fees) return navigate('/fees');
  };

  return (
    <Div
      css={{
        width: '350px',
        height: '290px',
        borderRadius: '6px',
        border: '1px solid $opacity',
        justifyContent: 'center',
        alignItems: 'center',
        '@tb': { width: '255px' },
        '@mb': {
          width: '90%',
          height: 'auto',
          paddingTop: 30,
          paddingBottom: 30,
        },
      }}
    >
      <Icon css={{ width: 60 }} src={icon} />
      <Txt
        h5
        css={{
          marginTop: 20,
          marginBottom: 10,
        }}
      >
        {t(title)}
      </Txt>
      <Txt
        b5
        css={{
          textAlign: 'center',
          whiteSpace: 'pre-line',
          color: '$subTextGray',
        }}
      >
        {t(`${title}_sub`)}
      </Txt>
      {view && (
        <Row
          onClick={onClickHandler}
          css={{
            marginTop: 40,
          }}
        >
          <Txt
            d5
            css={{
              color: '$primary',
              cursor: 'pointer',
            }}
          >
            {t('more')} ➔
          </Txt>
        </Row>
      )}
    </Div>
  );
};
