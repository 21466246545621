import React from 'react';
import { Row } from '../../../../styles/Layout/Row';
import { Col } from '../../../../styles/Layout/Col';
import { Txt } from '../../../StyleGuide/Components/Txt';
import { useSelector } from 'react-redux';
import UserIcon from './UserIcon';
import AssetView from './AssetView';
import NotiButtonMobile from './NotiButtonMobile';
import NotiButton from './NotiButton';
import UserIconMobile from './UserIconMobile';

function UserInterfaceMb({
  setVisibleNoti,
  visibleNoti,
  setVisibleUser,
  visibleUser,
}) {
  const user = useSelector((state) => state.user.data);

  return (
    <Row
      css={{
        gap: 10,
        width: '95%',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Col
        css={{
          alignItems: 'flex-end',
          marginRight: 10,
          gap: 4,
        }}
      >
        <Txt
          b3
          css={{
            marginTop: 7,
            fontSize: 13,
            fontWeight: 700,
          }}
        >
          {user?.name}
        </Txt>
        <AssetView />
      </Col>
      <UserIconMobile
        visibleUser={visibleUser}
        setVisibleUser={setVisibleUser}
        setVisibleNoti={setVisibleNoti}
      />
      <NotiButtonMobile
        setVisibleNoti={setVisibleNoti}
        visibleNoti={visibleNoti}
        setVisibleUser={setVisibleUser}
      />
    </Row>
  );
}

export default UserInterfaceMb;
