import { styled } from '../../../styles/stitches.config';

export const Txt = styled('span', {
  color: '$text',
  textAlign: 'center',

  variants: {
    d1: {
      true: {
        fontFamily: 'GmarketSansTTFBold',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0.1em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontSize: '24px',
      },
    },
    d2: {
      true: {
        fontFamily: 'GmarketSansTTFBold',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0.1em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontSize: '20px',
      },
    },
    d3: {
      true: {
        fontFamily: 'GmarketSansTTFBold',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0.1em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontSize: '16px',
      },
    },
    d4: {
      true: {
        fontFamily: 'GmarketSansTTFBold',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0.1em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontSize: '14px',
      },
    },
    d5: {
      true: {
        fontFamily: 'GmarketSansTTFBold',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0.1em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontSize: '13px',
      },
    },

    h1: {
      true: {
        fontFamily: 'GmarketSansTTFMedium',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '52px',
        /* identical to box height, or 26px */
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        /* Dark Gray */

        '@mb': {
          fontSize: '32px',
          whiteSpace: 'pre-line',
        },
      },
    },
    h2: {
      true: {
        fontFamily: 'GmarketSansTTFMedium',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '38px',
        /* identical to box height, or 26px */
        fontFeatureSettings: "'pnum' on, 'lnum' on",

        '@mb': {
          fontSize: '22px',
          whiteSpace: 'pre-line',
          textAlign: 'center',
        },
      },
    },
    h3: {
      true: {
        fontFamily: 'GmarketSansTTFMedium',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '28px',
        /* identical to box height, or 26px */
        fontFeatureSettings: "'pnum' on, 'lnum' on",
      },
    },
    h4: {
      true: {
        fontFamily: 'GmarketSansTTFMedium',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '22px',
        /* identical to box height, or 26px */
        fontFeatureSettings: "'pnum' on, 'lnum' on",
      },
    },
    h5: {
      true: {
        fontFamily: 'GmarketSansTTFMedium',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        /* identical to box height, or 26px */
        fontFeatureSettings: "'pnum' on, 'lnum' on",
      },
    },
    h6: {
      true: {
        fontFamily: 'GmarketSansTTFMedium',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        /* identical to box height, or 26px */
        fontFeatureSettings: "'pnum' on, 'lnum' on",
      },
    },
    h7: {
      true: {
        fontFamily: 'GmarketSansTTFMedium',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px',
        /* identical to box height, or 26px */
        fontFeatureSettings: "'pnum' on, 'lnum' on",
      },
    },
    e1: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Bold',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        /* identical to box height, or 21px */

        fontFeatureSettings: "'pnum' on, 'lnum' on",
      },
    },
    e2: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Bold',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '15px',
        /* identical to box height, or 21px */

        fontFeatureSettings: "'pnum' on, 'lnum' on",
      },
    },
    e3: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Bold',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        /* identical to box height, or 21px */

        fontFeatureSettings: "'pnum' on, 'lnum' on",
      },
    },
    e4: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Bold',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '13px',
        /* identical to box height, or 21px */

        fontFeatureSettings: "'pnum' on, 'lnum' on",
      },
    },
    b1: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '22px',
        /* identical to box height, or 21px */
        letterSpacing: '-0.04em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        color: '$textWeak',
      },
    },
    b2: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        /* identical to box height, or 21px */
        letterSpacing: '-0.04em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",

        color: '$textWeak',

        '@mb': {
          fontSize: '16px',
        },
      },
    },
    b3: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        /* identical to box height, or 21px */
        letterSpacing: '-0.04em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",

        color: '$textWeak',
      },
    },
    b4: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        /* identical to box height, or 21px */
        letterSpacing: '-0.04em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        color: '$textWeak',

        '@mb': {
          fontSize: '13px',
        },
      },
    },
    b5: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        /* identical to box height, or 21px */
        letterSpacing: '-0.04em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",

        color: '$textWeak',
      },
    },
    b6: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px',
        /* identical to box height, or 21px */
        letterSpacing: '-0.04em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",

        color: '$textWeak',
      },
    },
    b7: {
      true: {
        fontFamily: 'SpoqaHanSansNeo-Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        /* identical to box height, or 21px */
        letterSpacing: '-0.04em',
        fontFeatureSettings: "'pnum' on, 'lnum' on",

        color: '$textWeak',
      },
    },
  },
});
