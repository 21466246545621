import {createAction} from "redux-actions";
import {setToken} from "../../api/base/apiCore";
import {updateCurrency} from "./assetAction";
import {loadNotification} from "./notificationAction";
import { encode, decode, Base64 } from "js-base64";

/**
 * JWT을 받아서 로그인 처리
 */
export const userLoginFromJwt = (tokens) => {
  return (dispatch) => {
    try {
      let payload = tokens?.accessToken?.split('.')[1];
      let user = JSON.parse(base64Decode(payload));
      setToken(tokens);
      dispatch({ type: userLogin, payload: user });
      dispatch(updateCurrency(user?.preferenceAsset));
      dispatch(loadNotification());
    } catch (e) {
      console.log(e);
    }
  };
};

function base64Decode(base64) {
    const userString = Base64.decode(base64);
    return userString;
    //
    // const binaryString = atob(base64);
    // const bytes = new Uint8Array(binaryString.length);
    // for (let i = 0; i < binaryString.length; i++) {
    //     bytes[i] = binaryString.charCodeAt(i);
    // }
    // return new TextDecoder('utf8').decode(bytes);
}

export const userLogin = createAction('USER_LOGIN');
export const userLogout = createAction('USER_LOGOUT');
export const userNotHaveJWT = createAction('USER_NOT_HAVE_JWT');
export const userIconUpdate = createAction('USER_ICON_UPDATE');

export const updateUserCurrencyComplete = createAction(
  'UPDATE_USER_CURRENCY_COMPLETE',
);
