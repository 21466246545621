import { useSpring } from '@react-spring/web';
import { AnimatedDiv } from '../../../styles/Layout/AnimatedDiv';

export const SidebarModalContainer = ({
  css,
  onClick,
  children,
  auth = 0,
  width,
  right,
  padding = 0,
}) => {
  const sp = useSpring({
    from: {
      right: right,
      opacity: 0,
    },
    to: {
      right: 0,
      opacity: 1,
    },
  });

  return (
    <AnimatedDiv
      css={{
        position: 'absolute',
        background: '$bg',
        borderLeft: '1px solid $opacity',
        height: '100vh',
        right: right,
        width: width,
        padding: padding,
        overflowY: 'scroll',
        ...css,
        // '@mb': {
        //     width: width,
        //     height: height,
        //     padding: padding
        // }

        '@mb': {
          width: '100%',
        },
      }}
      onClick={(event) => {
        event.stopPropagation();
        if (!!onClick === true) {
          onClick();
        }
      }}
      style={{
        ...sp,
      }}
    >
      {children}
    </AnimatedDiv>
  );
};
