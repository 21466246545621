import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatedDiv } from '../../styles/Layout/AnimatedDiv';
import { Txt } from '../StyleGuide/Components/Txt';
import { Div } from '../../styles/Layout/Div';
import { useSpring } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import { endToast } from '../../redux/actions/toastAction';
import { styled } from '../../styles/stitches.config';
import { useMediaQuery } from '../Shared/useMediaQuery';

function ToastManager() {
  const toasts = useSelector((state) => state.toasts.msg);
  const isMobile = useMediaQuery('mb');

  return (
    <React.Fragment>
      {toasts.map((item, index) => {
        return (
          <ToastMsg
            key={`toast_${item.id}`}
            item={item}
            index={index}
            isMobile={isMobile}
          />
        );
      })}
    </React.Fragment>
  );
}

function ToastMsg({ item, index, isMobile }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [on, setOn] = React.useState(false);

  const desktopInitialize = useSpring({
    top: 80 + index * 80,
    right: on ? 0 : -340,
  });

  const mobileInitialize = useSpring({
    top: on ? 0 : -74,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
  });

  const d = useSpring({
    from: {
      width: 0,
    },
    to: {
      width: 100,
    },
    config: {
      duration: item.duration,
    },
  });

  useEffect(() => {
    if (!!item === false) return;
    setOn(true);
    setTimeout(() => {
      setOn(false);
      setTimeout(() => {
        dispatch({ type: endToast, payload: item?.id });
      }, 1000);
    }, item.duration + 1000);
  }, [item]);

  return (
    <ToastContainer
      css={{
        justifyContent: 'center',
        zIndex: 1000,
        '@mb': {
          borderTopLeftRadius: 0,
          borderBottomRightRadius: 5,
        },
      }}
      style={isMobile ? mobileInitialize : desktopInitialize}
      onClick={() => {
        navigate(item?.link);
      }}
    >
      <ToastLeftLine
        success={item?.success}
        css={{
          position: 'absolute',
          '@mb': {
            width: '100%',
            height: 5,
            top: 60,
            left: 0,
            borderRadius: 5,
          },
        }}
      />
      <Txt
        b3
        css={{
          marginLeft: 30,
          color: '$bg',
          zIndex: 3,
          userSelect: 'none',
          textAlign: 'left',
        }}
      >
        {item?.msg ?? 'no Msg'}
      </Txt>
      <ToastDurationBg
        css={{
          position: 'absolute',
          '@mb': {
            left: 0,
          },
        }}
        style={{
          width: d.width.to((v) => `${v}%`),
        }}
      />
    </ToastContainer>
  );
}

const ToastContainer = styled(AnimatedDiv, {
  position: 'fixed',
  right: -340,
  top: 80,
  width: 340,
  height: 67,
  backgroundColor: '$text',
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  border: '1px solid $bg',
  cursor: 'pointer',
  '&:active': {
    filter: 'brightness(0.9)',
  },
});

const ToastLeftLine = styled(Div, {
  top: 0,
  left: 0,
  width: 5,
  height: '100%',
  backgroundColor: '$primary',
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  zIndex: 3,
  variants: {
    success: {
      false: {
        backgroundColor: 'red',
      },
    },
  },
});

const ToastDurationBg = styled(AnimatedDiv, {
  top: 0,
  left: 5,
  height: '100%',
  backgroundColor: '$toastDurationGray',
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
});

export default ToastManager;
