import React from 'react';
import Lottie from 'react-lottie';

function LottieIcon({ animationData, width = 20 }) {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      width={width}
      style={{ alignItems: 'center', display: 'flex' }}
    />
  );
}

export default LottieIcon;
