import { styled } from '../stitches.config';
import { Div } from './Div';

export const Col = styled(Div, {
  flexDirection: 'column',
  height: 'auto',
  flexWrap: 'wrap',
  variants: {
    borderBox: {
      true: {
        width: 328,
        height: 120,
        borderRadius: 60,
        border: '2px solid $BoxLine',
        gap: 5,
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
});
