import React, { useRef, useState } from 'react';
import InputBox from '../../Shared/InputBox/InputBox';
import { Col } from '../../../styles/Layout/Col';
import { Txt } from '../../StyleGuide/Components/Txt';
import NetworkButton from '../../Shared/Buttons/NetworkButton';
import { Row } from '../../../styles/Layout/Row';
import { Icon } from '../../Shared/Icon';
import { Div } from '../../../styles/Layout/Div';
import { Img } from '../../StyleGuide/Components/Img';
import { useNavigate } from 'react-router-dom';
import { resendAuthPassword } from '../../../api/userService';
import { emailRegex } from '../../../util';
import { endLoading, startLoading } from '../../../redux/actions/loadingAction';
import { createToast } from '../../../redux/actions/toastAction';
import { useDispatch } from 'react-redux';
import { Captcha } from '../../Shared/Captcha/Captcha';

function ForgotLoginInputForm({ t, title, buttonTitle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [info, setInfo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const infoRef = useRef(info);

  async function postHandle() {
    await resendAuthPassword(infoRef.current)
      .then((r) => {
        dispatch({
          type: createToast,
          payload: {
            msg: t('login_resend_password'),
            duration: 2000,
            success: true,
          },
        });
      })
      .catch((error) => {
        console.error('error ::', error);
      });
    dispatch({ type: endLoading });
  }

  function callProcess() {
    const filterData = emailRegex(infoRef.current);
    if (!filterData) {
      setErrorMessage(t('email_valid_guide'));
      return;
    } else if (filterData) {
      setErrorMessage('');
    }
    dispatch({ type: startLoading });
    postHandle();
  }

  function infoHandle(info) {
    setInfo(info);
    infoRef.current = info;
  }

  return (
    <Col css={{ width: '100%' }}>
      <Txt css={{ marginLeft: 15 }} b6>
        {title}
      </Txt>
      <InputBox
        id={info}
        setId={infoHandle}
        setError={setErrorMessage}
        error={errorMessage}
        css={{
          maxWidth: 445,
          width: '100%',
          padding: '11px 15px',
          marginTop: 4,
          '@mb': { maxWidth: '100%' },
        }}
      />
      <NetworkButton
        onClick={callProcess}
        css={{
          width: '100%',
          maxWidth: 445,
          marginTop: 30,
          '@mb': { maxWidth: '100%' },
        }}
        lg
      >
        {buttonTitle}
      </NetworkButton>
      <Row
        onClick={() => navigate('/login')}
        css={{
          cursor: 'pointer',
          marginTop: 15,
          height: 'auto',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Img
          css={{ width: 24, height: 24 }}
          src={'/images/icon-prev-orange.svg'}
        />
        <Txt css={{ color: '$primary' }} e2>
          {t('login_forgot_backtologin')}
        </Txt>
      </Row>
    </Col>
  );
}

export default ForgotLoginInputForm;
