import React from 'react';
import { userLogout } from '../../../../redux/actions/userAction';
import { AnimatedDiv } from '../../../../styles/Layout/AnimatedDiv';
import { useTranslation } from 'react-i18next';
import { Row } from '../../../../styles/Layout/Row';
import { Icon } from '../../../Shared/Icon';
import { Txt } from '../../../StyleGuide/Components/Txt';
import { useDispatch } from 'react-redux';

function UserIconItemMobile({ navigate, setVisibleUser }) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  return (
    <AnimatedDiv
      css={{
        paddingTop: 10,
        paddingBottom: 10,
        width: '100%',
        minHeight: 100,
        borderRadius: 6,
        background: '$bg',
      }}
    >
      <UserMenuButton
        icon={'/images/icon-file.svg'}
        setVisible={setVisibleUser}
        onClickAction={() => {
          navigate('/my/profile');
        }}
        title={t('my_profile')}
      />
      <UserMenuButton
        icon={'/images/icon-trending-white-up.svg'}
        setVisible={setVisibleUser}
        onClickAction={() => {
          navigate('/my/tradehistory');
        }}
        title={t('my_tradehistory')}
      />
      <UserMenuButton
        icon={'/images/icon-data.svg'}
        setVisible={setVisibleUser}
        onClickAction={() => {
          navigate('/my/myoffer');
        }}
        title={t('my_myoffer')}
      />
      <UserMenuButton
        icon={'/images/icon-bookmark.svg'}
        setVisible={setVisibleUser}
        onClickAction={() => {
          navigate('/my/favoriteoffer');
        }}
        title={t('my_favorite')}
      />
      <UserMenuButton
        icon={'/images/icon-share.svg'}
        setVisible={setVisibleUser}
        onClickAction={() => {
          navigate('/my/invite');
        }}
        title={t('my_invite')}
      />
      <UserMenuButton
        icon={'/images/icon-bell.svg'}
        setVisible={setVisibleUser}
        onClickAction={() => {
          navigate('/my/notification');
        }}
        title={t('my_notifi')}
      />
      <UserMenuButton
        icon={'/images/icon-badge.svg'}
        setVisible={setVisibleUser}
        onClickAction={() => {
          navigate('/my/verification');
        }}
        title={t('my_verification')}
      />
      <UserMenuButton
        icon={'/images/icon-download.svg'}
        setVisible={setVisibleUser}
        onClickAction={() => {
          navigate('/my/tradestatistics');
        }}
        title={t('my_tradestatistics')}
      />

      <UserMenuButton
        icon={'/images/icon-log-out.svg'}
        setVisible={setVisibleUser}
        onClickAction={() => {
          dispatch({ type: userLogout });
          navigate('/login');
        }}
        title={t('logout')}
      />
    </AnimatedDiv>
  );
}

const UserMenuButton = ({ icon, onClickAction, title, setVisible }) => {
  return (
    <Row
      css={{
        width: '100%',
        padding: '10px 20px',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 10,
        '&:hover': {
          background: '$boxBg',
        },
      }}
      onClick={() => {
        setVisible(false);
        onClickAction();
      }}
    >
      <Icon
        src={icon}
        css={{
          width: 20,
          height: 20,
        }}
      />
      <Txt b5>{title}</Txt>
    </Row>
  );
};

export default UserIconItemMobile;
