import { useLocation, useNavigate } from 'react-router-dom';
import {
  ContentsButton,
  ContentsText,
} from '../../../Shared/ContentsButton/ContentsButton';
import React, { useEffect, useRef } from 'react';

export const NavBarContentsButton = ({ children, url, contents }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    if (!!ref.current === true) {
      ref.current.setOn(false);
    }
  }, [location, ref]);

  return (
    <ContentsButton
      margin={15}
      contents={contents}
      ref={ref}
      isFocus={location.pathname.startsWith(url)}
      isHover={true}
      onClick={() => {
        navigate(url);
      }}
    >
      {children}
    </ContentsButton>
  );
};

export const NavBarContentsDiv = ({ children, contents, title }) => {
  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    if (!!ref.current === true) {
      ref.current.setOn(false);
    }
  }, [location, ref]);

  return (
    <ContentsText
      margin={15}
      contents={contents}
      ref={ref}
      title={title}
      isHover={true}
    >
      {children}
    </ContentsText>
  );
};
