import { request } from './base/apiCore';

export const getNotifications = async (page = 1) => {
  return await request({
    method: 'GET',
    url: '/notification',
    params: {
      page,
    },
  });
};

export const readNotification = async (notificationId) => {
  return await request({
    method: 'POST',
    url: '/notification/read',
    data: {
      id: notificationId,
    },
  });
};

export const readAllNotificationRequest = async () => {
  return await request({
    method: 'POST',
    url: '/notification/read/all',
  });
};
