import { handleActions } from 'redux-actions';
import {
  updateUserCurrencyComplete,
  userIconUpdate,
  userLogin,
  userLogout,
  userNotHaveJWT,
} from '../actions/userAction';
import { clearToken } from '../../api/base/apiCore';

export const userDefaultState = {
  onLoad: false,
  isLogin: false,
  data: null,
  tradeStat: null,
  config: null,
};

export default handleActions(
  {
    [userLogin](state, data) {
      return {
        onLoad: true,
        isLogin: true,
        data: data?.payload,
      };
    },
    [userLogout](state, data) {
      console.log('logout dispatch');
      clearToken();
      return {
        onLoad: true,
        isLogin: false,
        data: null,
      };
    },
    [userNotHaveJWT](state, data) {
      console.log('userNotHaveJWT dispatch');
      return {
        onLoad: true,
        isLogin: false,
        data: null,
      };
    },
    [userIconUpdate](state, data) {
      return {
        ...state,
        data: {
          ...state?.data,
          icon: data?.payload?.icon,
        },
      };
    },
    [updateUserCurrencyComplete](state, data) {
      return {
        ...state,
        data: {
          ...state?.data,
          preferenceAsset: data?.payload.assetId,
          preferenceAssetSymbol: data?.payload.symbol,
        },
      };
    },
  },
  userDefaultState,
);
