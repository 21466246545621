import React, { useState } from 'react';
import { Div } from '../../../styles/Layout/Div';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Col } from '../../../styles/Layout/Col';
import { Button } from '../../StyleGuide/Components/Button';
import { useNavigate } from 'react-router-dom';
import { styled } from '../../../styles/stitches.config';
import { Icon } from '../Icon';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from '../../../styles/Layout/Row';
import { getTimeText } from '../../../util';
import NotiContainerItem from './NotiContainerItem';
import { readAllNotificationAction } from '../../../redux/actions/notificationAction';

function NotificInbox({ t, setVisible, navigate }) {
  const notification = useSelector((state) => state.noti.notifications);
  const dispatch = useDispatch();

  function clickMessage(message) {
    if (message?.link !== '') navigate(message?.link);
  }

  function readAll() {
    let unReadNoti = notification.filter((item) => item.isRead === false);
    if (unReadNoti.length === 0) return;
    dispatch(readAllNotificationAction());
  }

  return (
    <Div css={{ padding: 15, width: '100%' }}>
      <Row
        css={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Txt css={{ color: '$subTextGray' }} b6>
          {t('notific_notifications')}
        </Txt>
        <Txt
          css={{
            color: '$primary',
            cursor: 'pointer',
          }}
          e4
          onClick={readAll}
        >
          {t('make_read')}
        </Txt>
      </Row>
      <Col
        css={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          marginTop: 20,
        }}
      >
        {notification.length === 0 && (
          <React.Fragment>
            <Icon
              css={{ width: 60, height: 60 }}
              src={'/images/icon-search-result.svg'}
            />
            <Txt css={{ color: '$subTextGray', marginBottom: 10 }} b4>
              {t('notific_alarm')}
            </Txt>
          </React.Fragment>
        )}
        {notification.slice(0, 4).map((message, index) => {
          return (
            <NotiContainerItem
              msg={message}
              key={`noti-${index}`}
              clickMessage={clickMessage}
            />
          );
        })}
        <Button
          onClick={() => {
            navigate('/my/notification');
            setVisible(false);
          }}
          css={{ width: 'inherit' }}
          bordered
        >
          {t('notific_show')}
        </Button>
      </Col>
    </Div>
  );
}

export default NotificInbox;
