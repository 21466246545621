import { request } from './base/apiCore';

export const findOffers = async (findDto, isLogin = false) => {
  console.log('findOffers', JSON.stringify(findDto), isLogin);
  return await request({
    url: isLogin ? '/offers/auth' : `/offers`,
    method: 'GET',
    params: {
      ...findDto,
    },
  });
};

export const getOfferCardList = async (searchOption) => {
  const { page, salesType, keyAssetId } = searchOption;

  return await request({
    url: `/offers/mainPage`,
    method: 'GET',
    params: {
      type: salesType,
      page,
      assetId: keyAssetId,
    },
  });
};

export const getFavoriteOffers = async (favoriteOfferFind) => {
  return await request({
    url: `/offers/favorite/list`,
    method: 'GET',
    params: favoriteOfferFind,
  });
};
export const getFavoriteOffersCnt = async (favoriteOfferFind) => {
  return await request({
    url: `/offers/favorite/cnt`,
    method: 'GET',
    params: favoriteOfferFind,
  });
};
export const getAlreadyActiveOffer = async (offerId) => {
  return await request({
    url: `/offers/already/sub`,
    method: 'GET',
    params: {
      offerId,
    },
  });
};

export const getMyOffers = async (type, page) => {
  return await request({
    url: `/offers/my`,
    method: 'GET',
    params: {
      type,
      page,
    },
  });
};

export const getMyOffersCnt = async () => {
  return await request({
    url: `/offers/my/cnt`,
    method: 'GET',
  });
};

export const activeOffer = async (offerId, isActive) => {
  return await request({
    url: `/offers/active`,
    method: 'POST',
    data: {
      offerId,
      isActive,
    },
  });
};

export const createOffer = async (offer) => {
  return await request({
    url: `/offers/create`,
    method: 'POST',
    data: {
      ...offer,
    },
  });
};

export const createSubOffer = async (offerId, amount, displayLegal) => {
  return await request({
    url: `/offers/create/sub`,
    method: 'POST',
    data: {
      offerId,
      amount,
      displayLegal,
    },
  });
};

export const getOfferDetail = async (offerId) => {
  console.log('getOfferDetail', offerId);
  return await request({
    url: `/offers/detail`,
    method: 'GET',
    params: {
      offerId,
    },
  });
};

export const getSubOfferDetail = async (subOfferId) => {
  return await request({
    url: '/offers/detail/sub',
    method: 'GET',
    params: {
      subOfferId,
    },
  });
};

export const getOfferFeedback = async (offerId, page, type) => {
  return await request({
    url: '/offers/feedback',
    method: 'GET',
    params: {
      offerId,
      page,
      type,
    },
  });
};

export const addOfferFeedback = async (data) => {
  return await request({
    url: '/offers/feedback/add',
    method: 'POST',
    data: data,
  });
};

export const updateFavoriteOffer = async (offerId, isFavorite) => {
  return await request({
    url: '/offers/favorite',
    method: 'POST',
    data: {
      offerId,
      isFavorite,
    },
  });
};

export const getHistoryTrade = async (obj) => {
  return await request({
    url: '/offers/trade/history',
    method: 'GET',
    params: obj,
  });
};

export const getHistoryTradeCnt = async (obj) => {
  return await request({
    url: '/offers/trade/history-cnt',
    method: 'GET',
    params: obj,
  });
};

export const getSubOfferReceipt = async (subOfferId) => {
  return await request({
    url: '/offers/receipt',
    method: 'GET',
    params: {
      subOfferId,
    },
  });
};

export const getEditOfferInfo = async (offerId) => {
  return await request({
    url: '/offers/edit',
    method: 'GET',
    params: {
      offerId,
    },
  });
};

export const editOffer = async (offerId, offer) => {
  return await request({
    url: '/offers/edit',
    method: 'POST',
    data: {
      offerId,
      ...offer,
    },
  });
};

export const deleteOffer = async (offerId) => {
  return await request({
    url: '/offers/delete',
    method: 'POST',
    data: {
      offerId,
    },
  });
};
export const getTargetOffer = async (userId, assetId, type) => {
  return await request({
    url: '/offers/target',
    method: 'GET',
    params: {
      userId,
      assetId,
      type,
    },
  });
};

export const getOffersCnt = async (userId, assetId) => {
  return await request({
    url: '/offers/cnt',
    method: 'GET',
    params: {
      userId,
      assetId,
    },
  });
};
