import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeDarkMode,
  changeLightMode,
} from '../../redux/actions/themeAction';
import { Toggle } from './Toggle/Toggle';
import { Row } from '../../styles/Layout/Row';
import { Txt } from '../StyleGuide/Components/Txt';
import { capitalizeFirstLetter } from '../../util';
import { Icon } from './Icon';
import { useTranslation } from 'react-i18next';

export const ThemeToggle = () => {
  const { t } = useTranslation('common');

  const theme = useSelector((state) => state.theme.type);
  const dispatch = useDispatch();

  function onClick() {
    if (theme !== 'light') {
      dispatch({ type: changeLightMode });
    } else {
      dispatch({ type: changeDarkMode });
    }
  }

  function filterThemeText() {
    let filteredText = '';
    const data = capitalizeFirstLetter(theme);
    if (data === 'Dark') {
      filteredText = t('filter_dark_theme');
    } else if (data === 'Light') {
      filteredText = t('filter_light_theme');
    }
    return filteredText;
  }

  return (
    <Row
      css={{
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
      }}
    >
      <Row
        css={{
          gap: 10,
          alignItems: 'center',
          justifyContent: 'center',
          '@mb': { flexDirection: 'column' },
        }}
      >
        <Icon src={`/images/icon-moon.svg`} />

        <Txt b4>{filterThemeText()}</Txt>
      </Row>
      <Toggle value={theme !== 'light'} callback={onClick} />
    </Row>
  );
};
