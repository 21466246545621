import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import themeReducer from '../../redux/reducers/themeReducer';
import userReducer from '../../redux/reducers/userReducer';
import modalReducer from '../../redux/reducers/modalReducer';
import loadingReducer from '../../redux/reducers/loadingReducer';
import { assetReducer } from '../../redux/reducers/assetReducer';
import { paymentReducer } from '../../redux/reducers/paymentMethodReducer';
import { countryReducer } from '../../redux/reducers/countryReducer';
import toastReducer from '../../redux/reducers/toastReducer';
import notificationReducer from '../../redux/reducers/notificationReducer';

const reducer = combineReducers({
  theme: themeReducer,
  asset: assetReducer,
  loading: loadingReducer,
  user: userReducer,
  modal: modalReducer,
  method: paymentReducer,
  country: countryReducer,
  toasts: toastReducer,
  noti: notificationReducer,
});

//const store = createStore(reducer, applyMiddleware(thunk));
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
