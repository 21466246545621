import React, { useEffect, useState } from 'react';
import { SidebarModalContainer } from '../../../Shared/Modals/SidebarModalContainer';
import { Txt } from '../../../StyleGuide/Components/Txt';
import { Icon } from '../../../Shared/Icon';
import { offModal } from '../../../../redux/actions/modalAction';
import { Row } from '../../../../styles/Layout/Row';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SidebarCommonDropdown from '../TradeHistoryFilter/sidebarCommonDropdown';
import { Col } from '../../../../styles/Layout/Col';
import TradeSidebarDateInput from '../TradeHistoryFilter/TradeSidebarDateInput';
import { Button } from '../../../StyleGuide/Components/Button';
import { getLastMonthDate, getToday } from '../../../../util';
import { CheckBox } from '../../../Shared/CheckBox/CheckBox';

function FavoriteOfferFilter({
  assets,
  setAssets,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
}) {
  const { t } = useTranslation('modal');
  const dispatch = useDispatch();
  const asset = useSelector((state) => state.asset);
  const [assetIds, setAssetIds] = useState([]);

  const [currencyList, setCurrencyList] = useState([]);
  const [displayStartDate, setDisplayStartDate] = useState(startDate);
  const [displayEndDate, setDisplayEndDate] = useState(endDate);
  useEffect(() => {
    if (assets?.length > 0) {
      setAssetIds(assets);
    }
  }, [assets]);

  useEffect(() => {
    setCurrencyList(Object.keys(asset.list));
  }, [asset]);

  async function onApplyHandler() {
    setAssets(assetIds);
    setStartDate(displayStartDate);
    setEndDate(displayEndDate);
    dispatch({ type: offModal });
  }

  async function onClearHandler() {
    setAssets([]);
    setStartDate(getLastMonthDate());
    setEndDate(getToday());
    dispatch({ type: offModal });
  }

  return (
    <SidebarModalContainer width={320} right={-320}>
      <FilterHeader />
      <SidebarCommonDropdown
        defaultValue={true}
        title={t('sidebar_cryptocurrency')}
        data={currencyList}
        render={(el, i) => (
          <CheckBoxContainer
            asset={asset?.list[el]}
            key={i}
            t={t}
            checkBox={assetIds}
            setCheckBox={setAssetIds}
          />
        )}
      />

      <Col css={{ padding: 20, width: '100%' }}>
        <SelectDateRange>
          {/*<TradeSidebarDatePicker  />*/}
          <TradeSidebarDateInput
            startDate={displayStartDate}
            setStartDate={setDisplayStartDate}
            endDate={displayEndDate}
            setEndDate={setDisplayEndDate}
          />
        </SelectDateRange>
        <ButtonList>
          <CustomButton onClickHandler={onApplyHandler}>
            {t('sidebar_apply')}
          </CustomButton>
          <CustomButton onClickHandler={onClearHandler} bordered>
            {t('sidebar_clearall')}
          </CustomButton>
        </ButtonList>
      </Col>
    </SidebarModalContainer>
  );
}

export default FavoriteOfferFilter;

function SelectDateRange({ children }) {
  const { t } = useTranslation('modal');
  return (
    <>
      <Txt css={{ fontSize: 14 }} e2>
        {t('sidebar_daterange')}
      </Txt>
      {children}
      <Row css={{ width: '100%', gap: 10, marginTop: 15, flexWrap: 'nowrap' }}>
        <Icon css={{ width: 16 }} src={'/images/icon-info.svg'} />
        <Txt css={{ textAlign: 'left', color: '$subText' }}>
          {t('sidebar_intro0')}
        </Txt>
      </Row>
      <Row css={{ width: '100%', gap: 10, flexWrap: 'nowrap', marginTop: 15 }}>
        <Icon css={{ width: 16 }} src={'/images/icon-info.svg'} />
        <Txt css={{ textAlign: 'left', color: '$subText' }}>
          {t('sidebar_intro1')}
        </Txt>
      </Row>
    </>
  );
}

function FilterHeader() {
  const dispatch = useDispatch();
  const { t } = useTranslation('modal');
  return (
    <Row
      css={{
        padding: '20px 15px',
        justifyContent: 'space-between',
        width: '100%',
        borderBottom: '1px solid $BoxLine',
      }}
    >
      <Txt css={{ fontWeight: 500 }} h6>
        {t('sidebar_filters')}
      </Txt>
      <Icon
        onClick={() => {
          dispatch({ type: offModal });
        }}
        css={{ cursor: 'pointer', width: 16 }}
        src={'/images/icon-close.svg'}
      />
    </Row>
  );
}

function ButtonList({ children }) {
  return <Col css={{ width: '100%', gap: 8, marginTop: 80 }}>{children}</Col>;
}

function CustomButton({ children, onClickHandler, bordered }) {
  return (
    <Button
      css={{ width: '100%', height: 45 }}
      onClick={onClickHandler}
      bordered={bordered}
    >
      {children}
    </Button>
  );
}
const CheckBoxContainer = ({ asset, setCheckBox, checkBox }) => {
  const assetId = asset?.assetId;
  const name = asset?.name;

  let isExist = !!checkBox.find((el) => el === assetId);

  function handleSetTag(value) {
    if (value === false) {
      let newTags = checkBox.filter((el) => el !== assetId);
      setCheckBox([...newTags]);
    } else {
      checkBox.push(assetId);
      setCheckBox([...checkBox]);
    }
  }

  return <CheckBox label={name} check={isExist} setCheck={handleSetTag} />;
};
