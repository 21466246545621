import { handleActions } from 'redux-actions';
import {
  paymentMethodUpdateComplete,
  paymentMethodUpdateStart,
} from '../actions/paymentMethodAction';

export const paymentMethodDefault = {
  method: [],
  loading: false,
};

export const paymentReducer = handleActions(
  {
    [paymentMethodUpdateStart](state, data) {
      console.log('paymentMethodUpdateStart');
      return {
        ...state,
        loading: true,
      };
    },
    [paymentMethodUpdateComplete](state, data) {
      return {
        method: data.payload,
        loading: false,
      };
    },
  },
  paymentMethodDefault,
);
