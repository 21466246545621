import { Img } from '../StyleGuide/Components/Img';
import { useSelector } from 'react-redux';

export const Icon = ({ src, css, ...props }) => {
  let type = useSelector((state) => state.theme.type);
  let str = String(src).split('.');

  return (
    <Img
      src={`${str[0]}-${type}.${str[1]}`}
      css={{
        ...css,
      }}
      {...props}
    />
  );
};
