import { Section } from '../../../styles/Layout/Section';
import { styled } from '../../../styles/stitches.config';
import { Row } from '../../../styles/Layout/Row';
import React from 'react';
import { Button } from '../../StyleGuide/Components/Button';
import { Excoinz } from '../../Shared/Excoinz';
import { LangPickerDt } from '../../Shared/LangaugePicker/LangaugePicker.dt';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Col } from '../../../styles/Layout/Col';
import { useTranslation } from 'react-i18next';
import { SocialGroup } from './SocialGroup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeToggle } from '../../Shared/ThemaToggle';

export const FooterDt = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const isTrade = location.pathname.startsWith('/offer/trade');

  if (isTrade === true) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <FooterContainer>
      <Row
        css={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Excoinz />
        <Row
          css={{
            gap: 10,
            alignItems: 'center',
          }}
        >
          <ThemeToggle />
          <Button
            onClick={() => {
              window.location.href = 'https://help.excoinz.com';
            }}
            bordered
          >
            {t('faq_helpcenter')}
          </Button>
          <LangPickerDt />
        </Row>
      </Row>
      <Row
        css={{
          marginTop: 40,
        }}
      >
        <Col
          css={{
            minWidth: 174,
          }}
        >
          <FooterLabel h5>{t('legal')}</FooterLabel>
          <FooterLink url={'/policy/aml'}>{t('policy_aml')}</FooterLink>
          <FooterLink url={'/policy/cookie'}>{t('policy_cookie')}</FooterLink>
          <FooterLink url={'/policy/privacy'}>{t('policy_privacy')}</FooterLink>
          <FooterLink url={'/policy/terms'}>{t('policy_terms')}</FooterLink>
          <FooterLink url={'/policy/vendor'}>{t('policy_vendor')}</FooterLink>
        </Col>
        <Col
          css={{
            minWidth: 174,
          }}
        >
          <FooterLabel h5>{t('company')}</FooterLabel>
          {/*ToDO:임의*/}
          <FooterLink url={'/about'}>{t('about')}</FooterLink>
          <FooterLink url={'/about?contact=true'}>{t('contact')}</FooterLink>
          {/*<FooterLink url={"/media"}>{t('media')}</FooterLink>*/}
          <FooterLink url={'/afilliate'}>{t('afilliate')}</FooterLink>
          <FooterLink url={'/payment-program'}>
            {t('payment-program')}
          </FooterLink>
        </Col>
        <Col>
          <FooterLabel h5>{t('tutorial')}</FooterLabel>
          {/*ToDO:임의*/}
          <OutFooterLink url={'https://youtu.be/iHmH8k_OWCo'}>
            {t('youtube_1')}
          </OutFooterLink>
          <OutFooterLink url={'https://youtu.be/6bkIOtIINqI'}>
            {t('youtube_2')}
          </OutFooterLink>
          <OutFooterLink url={'https://youtu.be/UL15h7sY04k'}>
            {t('youtube_3')}
          </OutFooterLink>
          <OutFooterLink url={'https://youtu.be/djqEZ7TYDos'}>
            {t('youtube_4')}
          </OutFooterLink>
          <OutFooterLink
            url={'https://youtu.be/KDKZs16r0Rg?si=HwPSS80cNf6KUdwp'}
          >
            {t('youtube_5')}
          </OutFooterLink>
          <OutFooterLink
            url={'https://youtu.be/SQn61Wyh084?si=t3-Av6caPUAkBV6C'}
          >
            {t('youtube_6')}
          </OutFooterLink>
        </Col>
      </Row>
      <Row
        css={{
          marginTop: 80,
          justifyContent: 'space-between',
        }}
      >
        <Txt
          b7
          css={{
            color: '$subText',
            textAlign: 'left',
            width: '70%',
            '@mb': {
              textAlign: 'center',
            },
          }}
        >
          {t('guide')}
        </Txt>
        <SocialGroup />
      </Row>
    </FooterContainer>
  );
};

const FooterContainer = styled(Section, {
  background: '$boxBg',
  minHeight: 520,
  padding: 40,
  borderTop: '1px solid $opacity',
});

const FooterLabel = styled(Txt, {
  marginBottom: 10,
});
const A = styled('a', {
  margin: 0,
  fontStyle: 'none',
  textDecoration: 'none',
  padding: '10px 0 0 0 ',
});
const FooterToSend = ({ children, url }) => {
  return (
    <A href={url} css={{}}>
      <Txt
        b3
        css={{
          width: '100%',
          cursor: 'pointer',
          color: '$textPlaceholder',
          textAlign: 'left',
          '&:hover': {
            color: '$primary',
          },
        }}
      >
        {children}
      </Txt>
    </A>
  );
};

const FooterLink = ({ children, url }) => {
  const navigate = useNavigate();

  return (
    <Txt
      b3
      css={{
        paddingTop: 10,
        width: '100%',
        cursor: 'pointer',
        color: '$subText',
        textAlign: 'left',
        '&:hover': {
          color: '$primary',
        },
      }}
      onClick={() => {
        if (url) {
          navigate(url);
        }
      }}
    >
      {children}
    </Txt>
  );
};

const OutFooterLink = ({ children, url }) => {
  return (
    <Txt
      b3
      css={{
        paddingTop: 10,
        width: '100%',
        cursor: 'pointer',
        color: '$subText',
        textAlign: 'left',
        '&:hover': {
          color: '$primary',
        },
      }}
      onClick={() => {
        window.open(url, '_blank');
      }}
    >
      {children}
    </Txt>
  );
};
