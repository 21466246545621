import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Section } from '../../styles/Layout/Section';
import { styled } from '../../styles/stitches.config';
import { offModal } from '../../redux/actions/modalAction';
import { updateAssetInfo } from '../../redux/actions/assetAction';

export default function ModalManager() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  function offModalAction() {
    dispatch({ type: offModal });
  }

  useEffect(() => {
    console.log('update asset info');
    dispatch(updateAssetInfo());
  }, []);

  return (
    <React.Fragment>
      {modal?.modals?.map((modal, index) => {
        let mandatory = modal?.props?.mandatory ?? false;
        return (
          <ModalBg
            onClick={mandatory ? () => {} : offModalAction}
            key={`modal${index}`}
          >
            {modal}
          </ModalBg>
        );
      })}
    </React.Fragment>
  );
}

const ModalBg = styled(Section, {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1000,
  backgroundColor: 'rgba(0,0,0,0.3)',
  backdropFilter: 'blur(4px)',
  justifyContent: 'center',
  alignItems: 'center',
});
