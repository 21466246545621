import React, { useState } from 'react';
import { Div } from '../../../../styles/Layout/Div';
import { Icon } from '../../../Shared/Icon';
import { AnimatedDiv } from '../../../../styles/Layout/AnimatedDiv';
import { useNavigate } from 'react-router-dom';
import { Row } from '../../../../styles/Layout/Row';
import { Txt } from '../../../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import { Hr } from '../../../StyleGuide/Components/Hr';
import { useGesture } from '@use-gesture/react';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../../../redux/actions/userAction';

function UserIcon() {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const bind = useGesture({
    onHover: () => {
      setVisible(true);
    },
    onHoverEnd: () => {
      setVisible(false);
    },
  });

  return (
    <Div {...bind()}>
      <Div
        css={{
          width: 45,
          height: 45,
          borderRadius: 10,
          backgroundColor: '$primary',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            background: '$gradient',
          },
        }}
        onClick={() => {
          setVisible(!visible);
          navigate('/my/profile');
        }}
      >
        <Icon src={'/images/icon-user.svg'} />
      </Div>
      {visible && (
        <AnimatedDiv
          css={{
            position: 'absolute',
            paddingTop: 10,
            paddingBottom: 10,
            top: 50,
            right: 0,
            width: 260,
            minHeight: 100,
            borderRadius: 6,
            background: '$bg',
            border: '1px solid $opacity',
            zIndex: 99,
          }}
        >
          <Div
            css={{
              position: 'absolute',
              top: -10,
              width: '100%',
              height: 10,
            }}
          />
          <UserMenuButton
            icon={'/images/icon-file.svg'}
            setVisible={setVisible}
            onClickAction={() => {
              navigate('/my/profile');
            }}
            title={t('my_profile')}
          />
          <UserMenuButton
            icon={'/images/icon-trending-white-up.svg'}
            setVisible={setVisible}
            onClickAction={() => {
              navigate('/my/tradehistory');
            }}
            title={t('my_tradehistory')}
          />
          <UserMenuButton
            icon={'/images/icon-data.svg'}
            setVisible={setVisible}
            onClickAction={() => {
              navigate('/my/myoffer');
            }}
            title={t('my_myoffer')}
          />
          <UserMenuButton
            icon={'/images/icon-bookmark.svg'}
            setVisible={setVisible}
            onClickAction={() => {
              navigate('/my/favoriteoffer');
            }}
            title={t('my_favorite')}
          />
          <UserMenuButton
            icon={'/images/icon-share.svg'}
            setVisible={setVisible}
            onClickAction={() => {
              navigate('/my/invite');
            }}
            title={t('my_invite')}
          />
          <UserMenuButton
            icon={'/images/icon-bell.svg'}
            setVisible={setVisible}
            onClickAction={() => {
              navigate('/my/notification');
            }}
            title={t('my_notifi')}
          />
          <UserMenuButton
            icon={'/images/icon-badge.svg'}
            setVisible={setVisible}
            onClickAction={() => {
              navigate('/my/verification');
            }}
            title={t('my_verification')}
          />
          <UserMenuButton
            icon={'/images/icon-download.svg'}
            setVisible={setVisible}
            onClickAction={() => {
              navigate('/my/tradestatistics');
            }}
            title={t('my_tradestatistics')}
          />

          <Hr
            css={{
              margin: '10px 0',
              width: '100%',
            }}
          />
          <UserMenuButton
            icon={'/images/icon-log-out.svg'}
            setVisible={setVisible}
            onClickAction={() => {
              dispatch({ type: userLogout });
              navigate('/login');
            }}
            title={t('logout')}
          />
        </AnimatedDiv>
      )}
    </Div>
  );
}

const UserMenuButton = ({ icon, onClickAction, title, setVisible }) => {
  return (
    <Row
      css={{
        width: '100%',
        padding: '10px 20px',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 10,
        '&:hover': {
          background: '$boxHoverBg',
        },
      }}
      onClick={() => {
        setVisible(false);
        onClickAction();
      }}
    >
      <Icon
        src={icon}
        css={{
          width: 20,
          height: 20,
        }}
      />
      <Txt b5>{title}</Txt>
    </Row>
  );
};

export default UserIcon;
