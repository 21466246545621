import { useRef } from 'react';
import { Txt } from '../../StyleGuide/Components/Txt';
import { ContentsButton } from '../ContentsButton/ContentsButton';
import { Section } from '../../../styles/Layout/Section';
import { Div } from '../../../styles/Layout/Div';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../../../locale/locale';
import { EnableLang } from './LangaugePicker.dt';

export const LangPickerMb = ({
  height = 31,
  boxHeight = 320,
  boxWidth = 300,
  isLanguageOpen,
  setIsLanguageOpen,
  css,
}) => {
  const { i18n } = useTranslation();
  const btnRef = useRef();

  function setOffBox() {
    setIsLanguageOpen(false);
    btnRef.current.setOn(false);
  }

  return (
    <ContentsButton
      ref={btnRef}
      height={height}
      blurOff={false}
      width={110}
      left={-boxWidth + 100}
      onClick={() => {
        setIsLanguageOpen(!isLanguageOpen);
      }}
      contents={
        <LangPickerContainer
          width={boxWidth}
          height={boxHeight}
          css={css}
          setOffBox={setOffBox}
          curr={i18n.language}
        />
      }
    >
      <Txt>{EnableLang[i18n.language]}</Txt>
    </ContentsButton>
  );
};

const LangPickerContainer = ({ curr, height, width, setOffBox, css }) => {
  const keys = Object.keys(EnableLang);

  return (
    <Section
      css={{
        width: width,
        height: height,
        padding: '16px 50px',
        marginTop: -16,
        background: 'transparent',
        overflowY: 'auto',
        ...css,
      }}
    >
      {keys.map((key, index) => {
        return (
          <LangPickerItem
            key={`lng_${index}`}
            title={EnableLang[key]}
            setOffBox={setOffBox}
            langKey={key}
            curr={curr}
          />
        );
      })}
    </Section>
  );
};

const LangPickerItem = ({ langKey, title, setOffBox, curr }) => {
  return (
    <Div
      css={{
        width: '50%',
        height: 46,
        cursor: 'pointer',
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 10,
        paddingTop: 10,

        ':active': {
          background: '$bg',
        },
      }}
      onClick={() => {
        changeLanguage(langKey);
        setOffBox();
      }}
    >
      <Txt
        b5
        css={{
          color: curr === langKey ? '$primary' : '$text',
          '&:hover': {
            color: '$primary',
          },
        }}
      >
        {title}
      </Txt>
    </Div>
  );
};
