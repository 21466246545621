import { Icon } from './Icon';
import { loadingRotate, styled } from '../../styles/stitches.config';

export const LoadingIndicator = ({ css }) => {
  return <Loading css={{ ...css }} src={'/images/icon-loading.svg'} />;
};

const Loading = styled(Icon, {
  animation: `${loadingRotate} 1s infinite linear`,
  width: 20,
});

// const rotation = keyframes({
//     from: {
//         transform: 'rotate(0deg)'
//
//     },
//     to: {
//         transform: 'rotate(360deg)'
//     }
// })
