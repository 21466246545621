import React from 'react';
import { Row } from '../../styles/Layout/Row';
import { Col } from '../../styles/Layout/Col';
import { LoadingIndicator } from '../Shared/LoadingIndicator';
import { Txt } from '../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import { Button } from '../StyleGuide/Components/Button';
import { styled } from '@stitches/react';

export function Loading({ text }) {
  return (
    <Row
      css={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Col css={{ gap: 10, alignItems: 'center' }}>
        <LoadingIndicator />
        <Txt b1>{text}</Txt>
      </Col>
    </Row>
  );
}

export function CheckSetterCnt({ files }) {
  const { t } = useTranslation('common');
  function getCnt() {
    return files?.length === undefined ? 0 : files.length;
  }
  getCnt();
  return (
    <Row css={{ '@mb': { width: '100%', marginTop: '10px' } }}>
      <Txt b4 css={{ '@mb': { fontSize: 15 } }}>
        {t('page_uploaded_cnt', { number: getCnt() })}
      </Txt>
    </Row>
  );
}
export function ConfirmBtn({ text, onClickHandler, disabled }) {
  return (
    <Button
      disabled={disabled}
      css={{
        padding: '12px 15px 14px',
        height: '45px',
        maxWidth: '195px',
        width: '100%',
        borderRadius: '10px',
        '@mb': { flex: 1 },
        '@tb': { flex: 1 },
      }}
      onClick={onClickHandler}
      lg
    >
      {text}
    </Button>
  );
}

export const TextLi = styled(Txt, {
  '&::before': {
    content: '"•"' /* 불릿 대신 사용할 문자 */,
    display: 'span',
    width: '1em',
    marginLeft: '-1em' /* 불릿 위치 조정 */,
    textAlign: 'center' /* 불릿 중앙 정렬 */,
  },
  textAlign: 'start',
});
