import { Txt } from '../../StyleGuide/Components/Txt';
import { ContentsButton } from '../ContentsButton/ContentsButton';
import { Section } from '../../../styles/Layout/Section';
import { Div } from '../../../styles/Layout/Div';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../../../locale/locale';

export const EnableLang = {
  ar: 'Arabic',
  zh: 'Chinese',
  nl: 'Dutch',
  en: 'English',
  fr: 'French',
  de: 'German',
  hi: 'Hindi',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  kr: 'Korean',
  ms: 'Malay',
  pl: 'Polish',
  pt: 'Portuguese',
  ru: 'Russian',
  es: 'Spanish',
  th: 'Thai',
  tr: 'Turkish',
  vi: 'Vietnamese',
};

export const LangPickerDt = ({ height = 31 }) => {
  const { i18n } = useTranslation();

  return (
    <ContentsButton
      height={height}
      contents={<LangPickerContainer curr={i18n.language} />}
      left={-450}
    >
      <Txt>{EnableLang[i18n.language]}</Txt>
    </ContentsButton>
  );
};

const LangPickerContainer = ({ curr }) => {
  const keys = Object.keys(EnableLang);

  return (
    <Section
      css={{
        width: 550,
        maxHeight: 370,
        background: '$bg',
        border: '1px solid $opacity',
        padding: 25,
        borderRadius: 6,
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: 'auto',
        overflowY: 'auto',
      }}
    >
      {keys.map((key, index) => {
        return (
          <LangPickerItem
            key={`lng_${index}`}
            title={EnableLang[key]}
            langKey={key}
            curr={curr}
          />
        );
      })}
    </Section>
  );
};

const LangPickerItem = ({ langKey, title, setLang, curr }) => {
  return (
    <Div
      css={{
        width: '50%',
        height: 46,
        cursor: 'pointer',
      }}
      onClick={() => {
        changeLanguage(langKey);
      }}
    >
      <Txt
        b4
        css={{
          color: curr === langKey ? '$primary' : '$text',
          '&:hover': {
            color: '$primary',
          },
        }}
      >
        {title}
      </Txt>
    </Div>
  );
};
