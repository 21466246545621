import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../Shared/useMediaQuery';
import { Row } from '../../../styles/Layout/Row';
import { Col } from '../../../styles/Layout/Col';
import { Txt } from '../../StyleGuide/Components/Txt';
import Tab from '../../Shared/Tab';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import WarningBox from '../../Shared/WarningBox';
import { Img } from '../../StyleGuide/Components/Img';
import { getRecommendUserInfo } from '../../../api/userService';
import { Div } from '../../../styles/Layout/Div';
import { Strong } from '../../StyleGuide/Components/Strong';
import FormatTxt from '../../StyleGuide/Components/FormatTxt';
import { Loading } from '../../Verification/KycComponent';

export default function Register({ inputPhoneValue, setInputPhoneValue }) {
  const [email, setEmail] = useState('');
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{6,}$/;
  const phoneRegex =
    /^(?=(?:\D*\d){5,})\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;

  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation('auth');

  const [pwd, setPwd] = useState('');

  const matchedEmail = emailRegex.test(email);
  const matchedPhone = phoneRegex.test(inputPhoneValue);

  const [validate, setValidate] = useState(0);
  const [refer, setRefer] = useState(false);

  const [refererCode, setRefererCode] = useState('');
  const [refererCodeType, setRefererCodeType] = useState(false);
  const refererRef = useRef(refererCode);

  const [referralUserInfo, setReferralUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function checkValidate(pwd) {
    let level = 0;
    // 1 단계 (대문자 소문자)
    const oneStep = /(?=.*[a-z])(?=.*[A-Z])/;
    const firstResult = oneStep.test(pwd);
    if (pwd.length > 0) {
      level = 1;
    }
    // 2 단계
    const twoStep = /(?=.*[!@#$%^&*])(?=.*\d)/;
    const secondResult = twoStep.test(pwd);
    if (pwd.length >= 6 && pwd.length > 0 && firstResult) {
      level = 2;
    }
    // 성공
    if (pwd.length >= 8 && pwd.length > 0 && firstResult && secondResult) {
      level = 3;
    }
    setValidate(level);
  }

  function refererCodeHandle(code) {
    code = code.slice(0, 6);
    setRefererCode(code);
    refererRef.current = code;
  }

  useEffect(() => {
    if (location.search.includes('r')) {
      let referer = location.search.split('=')[1];
      referer = referer.slice(0, 6);
      setRefer(true);
      refererCodeHandle(referer);
      setRefererCodeType(true);

      setIsLoading(true);
      getRecommendUserInfo(referer)
        .then((r) => {
          console.log(r);
          setReferralUserInfo(r);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [location]);

  useEffect(() => {
    if (refererCode.length < 6) return;
    refererCodeHandle(refererCode);
    setRefererCodeType(true);

    setIsLoading(true);
    getRecommendUserInfo(refererCode)
      .then((r) => {
        setReferralUserInfo(r);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [refererCode]);
  return (
    <Row
      css={{
        // marginBottom:223,
        width: '100%',
      }}
    >
      <Col
        css={{
          width: '100%',
        }}
      >
        <Txt
          css={{
            '@mb': {
              fontSize: 22,
            },
          }}
          h3
        >
          {t('register_create')}{' '}
        </Txt>
        {refererCodeType === true && referralUserInfo?.result !== false && (
          <RefererContainer>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Div css={{ width: 50, height: 50 }}>
                  <Img
                    css={{
                      borderRadius: 40,
                      width: 'inherit',
                      height: 'inherit',
                    }}
                    src={
                      referralUserInfo?.icon
                        ? referralUserInfo?.icon
                        : './android-chrome-192x192.png'
                    }
                  />
                </Div>
                <FormatTxt
                  t={t}
                  i18nKey="register_refer_userInfotitle"
                  css={{
                    textAlign: 'left',
                    width: '100%',
                  }}
                  textCss={{
                    color: '$white',
                  }}
                >
                  <Strong e2>
                    {{
                      userName: !!referralUserInfo.name
                        ? referralUserInfo.name
                        : referralUserInfo?.name,
                    }}
                  </Strong>
                  is inviting you to join Excoinz!
                </FormatTxt>
              </>
            )}
          </RefererContainer>
        )}
        <Tab
          color={'$primary'}
          alignLeft
          containerCss={{ width: '100%' }}
          css={{
            maxWidth: '100%',
            width: '100%',
            flexWrap: 'nowrap',
          }}
        >
          <Tab.Item
            title={t('phone')}
            fontSize={14}
            content={
              <PhoneForm
                refererCodeType={refererCodeType}
                refererCode={refererCode}
                refererRef={refererRef}
                refererCodeHandle={refererCodeHandle}
                passwordRegex={passwordRegex}
                checkValidate={checkValidate}
                setValidate={setValidate}
                validate={validate}
                isReferralUserInfo={referralUserInfo?.result !== false}
                refer={refer}
                setRefer={setRefer}
                inputPhoneValue={inputPhoneValue}
                setInputPhoneValue={setInputPhoneValue}
                matchedPhone={matchedPhone}
                setPwd={setPwd}
                pwd={pwd}
              />
            }
          />
          <Tab.Item
            title={t('email')}
            fontSize={14}
            content={
              <EmailForm
                refererCodeType={refererCodeType}
                refererCode={refererCode}
                refererRef={refererRef}
                isReferralUserInfo={referralUserInfo?.result !== false}
                refererCodeHandle={refererCodeHandle}
                emailForm
                passwordRegex={passwordRegex}
                refer={refer}
                setRefer={setRefer}
                checkValidate={checkValidate}
                setValidate={setValidate}
                validate={validate}
                matchedEmail={matchedEmail}
                email={email}
                setEmail={setEmail}
                pwd={pwd}
                setPwd={setPwd}
              />
            }
          />
        </Tab>
        <Row css={{ marginTop: 15, gap: 5 }}>
          <Txt css={{ color: '$subText' }} b4>
            {t('register_intro1')}
          </Txt>
          <Txt
            onClick={() => navigate('/login')}
            css={{
              color: '$primary',
              cursor: 'pointer',
              '@mb': { fontSize: 15 },
            }}
            e2
          >
            {t('register_login')}
          </Txt>
        </Row>
      </Col>
    </Row>
  );
}

function RefererContainer({ children }) {
  return (
    <Col css={{ width: '100%', margin: '20px 0' }}>
      <WarningBox css={{ width: '100%' }} symbol={false}>
        <Row
          css={{
            width: '100%',
            alignItems: 'center',
            gap: 10,
            flexWrap: 'nowrap',
          }}
        >
          {children}
        </Row>
      </WarningBox>
    </Col>
  );
}
