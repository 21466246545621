import React, { useState } from 'react';
import { Row } from '../../styles/Layout/Row';
import { Icon } from './Icon';

function WarningBox({
  warning,
  error,
  css,
  children,
  symbol = true,
  closable = false,
  icon = '/images/icon-info.svg',
  iconCss = {},
}) {
  const [close, setClose] = useState(false);

  function getBorderColor() {
    switch (error) {
      case 'error':
        return '$red';
      case 'warning':
        return '$orange';
      default:
        return '$orange';
    }
  }

  return (
    <React.Fragment>
      {close === false && (
        <Row
          css={{
            width: '95%',
            borderRadius: 10,
            flexWrap: 'nowrap',
            padding: 25,
            paddingTop: 15,
            paddingBottom: 15,
            gap: 15,
            border: `1px solid ${getBorderColor()}`,
            ...css,
          }}
        >
          {symbol && <Icon src={icon} css={iconCss} />}
          {children}
          {closable && (
            <Icon
              css={{
                cursor: 'pointer',
                position: 'absolute',
                width: 12,
                height: 12,
                top: 15,
                right: 15,
              }}
              src={'/images/icon-close.svg'}
              onClick={() => {
                setClose(true);
              }}
            />
          )}
        </Row>
      )}
    </React.Fragment>
  );
}

export default WarningBox;
