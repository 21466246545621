import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

function AuthGuard({ children, onlyLogin = false }) {
  const onLoad = useSelector((state) => state.user.onLoad);
  const isLogin = useSelector((state) => state.user.isLogin);
  const navigate = useNavigate();
  const [view, setView] = useState(false);
  const location = useLocation();

  console.log('AuthGuard', isLogin, onlyLogin, onLoad, location.pathname);

  useEffect(() => {
    if (location.pathname === '/login') {
      setView(true);
    }
  }, []);

  useEffect(() => {
    console.log('AuthGuard', isLogin, onlyLogin, onLoad);
    if (onLoad === false) {
      //navigate('/login', { replace: true });
      return;
    }

    //로그인 하고 들어와야하는 페이지에 로그인 안한상태로 들어오면 로그인 페이지로 이동
    if (isLogin === false) {
      navigate('/login', { replace: true });
    }
    //로그인 안하고 들어와야하는 페이지에 로그인 한상태로 들어오면 메인페이지로 이동
    if (isLogin === true && onlyLogin === true) {
      navigate('/my/tradehistory');
    }
    setView(true);
  }, [isLogin, onLoad]);

  return <React.Fragment>{view ? children : <></>}</React.Fragment>;
}

export default AuthGuard;
