import React from 'react';
import { Div } from '../../../styles/Layout/Div';
import { useTranslation } from 'react-i18next';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Row } from '../../../styles/Layout/Row';
import { FinancialCard } from './FinancialCard';
import { ScrollAlphaComponent } from '../../Shared/AnimateComponents/ScrollAlphaComponent';
import { useMediaQuery } from '../../Shared/useMediaQuery';

export const FinancialSection = ({ tbPadding }) => {
  const { t } = useTranslation('main');
  const isMobile = useMediaQuery('mb');

  return (
    <Div
      css={{
        width: '100%',
        minHeight: '768px',
        background: '$boxBg',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 160,
        '@tb': { paddingBottom: 80 },
        '@mb': { paddingBottom: 80 },
      }}
    >
      <ScrollAlphaComponent
        scrollStart={isMobile ? 3280 : 2700}
        scrollEnd={isMobile ? 3600 : 3250}
      >
        <Div
          css={{
            ...tbPadding,
            width: '95%',
            alignItems: 'center',
          }}
        >
          <Txt
            h2
            css={{
              marginTop: 80,
              '@tb': { fontSize: 30 },
            }}
          >
            {t('reason')}
          </Txt>
          <Row
            css={{
              marginTop: 60,
              marginBottom: 30,
              gap: 30,
              justifyContent: 'center',
              '@dt': { maxWidth: 1400 },
              '@tb': { maxWidth: 920 },
              '@mb': { marginTop: 40, gap: 16 },
            }}
          >
            <FinancialCard
              icon={'/images/icon-anti-fraud.svg'}
              title={'reason01'}
            />
            <FinancialCard
              icon={'/images/icon-security.svg'}
              title={'reason02'}
            />
            <FinancialCard
              fees
              icon={'/images/icon-low-fees.svg'}
              title={'reason03'}
            />
            <FinancialCard
              icon={'/images/icon-goods-and-services.svg'}
              title={'reason04'}
            />
            <FinancialCard
              vendor
              icon={'/images/icon-all-payment.svg'}
              title={'reason05'}
            />
            <FinancialCard
              wallet
              icon={'/images/icon-electronic-money.svg'}
              title={'reason06'}
            />
          </Row>
        </Div>
      </ScrollAlphaComponent>
    </Div>
  );
};
