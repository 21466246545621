import React from 'react';
import { Row } from '../../../styles/Layout/Row';
import { Txt } from './Txt';
import { Trans } from 'react-i18next';

function FormatTxt({ t, i18nKey, children, css, textCss, values, ...props }) {
  return (
    <Row
      css={{
        ...css,
      }}
    >
      <Txt
        {...props}
        css={{
          textAlign: 'left',
          ...textCss,
        }}
      >
        <Trans t={t} i18nKey={i18nKey} values={values}>
          {children}
        </Trans>
      </Txt>
    </Row>
  );
}

export default FormatTxt;
