import { request } from './base/apiCore';

export const getPaymentMethodInfo = async () => {
  return await request({
    method: 'GET',
    url: '/payment-method',
  });
};

export const getAllPaymentMethod = async () => {
  return await request({
    method: 'GET',
    url: '/payment-method/all',
  });
};

export const getPaymentMethodSearch = async (search) => {
  console.log(search);
  return await request({
    method: 'GET',
    url: '/payment-method/search',
    params: {
      search: search,
    },
  });
};

export const getPaymentMethodFromGroup = async (group) => {
  return await request({
    method: 'GET',
    url: '/payment-method/group',
    params: {
      group: group,
    },
  });
};

export const getPaymentMethodInfoFromId = async (methodId) => {
  return await request({
    method: 'GET',
    url: '/payment-method/info',
    params: {
      methodId: methodId,
    },
  });
};

export const getPaymentMethodFeeInfo = async (method, assetId) => {
  return await request({
    method: 'GET',
    url: '/payment-method/fee',
    params: {
      methodId: method,
      assetId: assetId,
    },
  });
};
