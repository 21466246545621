import { Div } from '../../../styles/Layout/Div';
import { Row } from '../../../styles/Layout/Row';
import React, { useEffect, useState } from 'react';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import { Col } from '../../../styles/Layout/Col';
import { useDispatch, useSelector } from 'react-redux';
import { updateAssetInfo } from '../../../redux/actions/assetAction';
import CurrencyTab from './CurrencyTab';
import { Button } from '../../StyleGuide/Components/Button';
import { Toggle } from '../../Shared/Toggle/Toggle';
import { useNavigate } from 'react-router-dom';

export const OfferBox = ({ width }) => {
  const asset = useSelector((state) => state.asset);
  const [keys, setKeys] = useState([]);

  const { t } = useTranslation('main');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [type, setType] = useState('');
  const [salesType, setSalesType] = useState('buy');
  const [isToggle, setToggle] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  function toggleHandle() {
    setToggle(!isToggle);
    if (isToggle === true) {
      setSalesType('sell');
    }
  }

  useEffect(() => {
    dispatch(updateAssetInfo());
  }, []);

  useEffect(() => {
    setKeys(Object.keys(asset.list));
  }, [asset]);

  useEffect(() => {
    if (isToggle === true) {
      setSalesType('sell');
    } else if (isToggle === false) {
      setSalesType('buy');
    }
  }, [isToggle]);

  return (
    <Div
      css={{
        width: '100%',
        maxWidth: 1110,
        height: '497px',
        marginTop: 50,
        zIndex: 10,
        alignItems: 'center',
        '@mb': {
          width: '100%',
          marginTop: 30,
        },
        '@tb': {
          width: '90%',
          marginTop: 30,
        },
      }}
    >
      <Col
        css={{
          width: '100%',
          flexWrap: 'nowrap',
        }}
      >
        <CurrencyTab
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          salesType={salesType}
          isToggle={isToggle}
          width={width}
          keys={keys}
          type={type}
          setType={setType}
        />
        <Row
          css={{
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 330,
            '@tb': {
              gap: 20,
              justifyContent: 'center',
              flexDirection: 'column',
            },
            '@mb': {
              gap: 20,
              justifyContent: 'center',
              flexDirection: 'column',
            },
          }}
        >
          {!isLoading && (
            <Button
              onClick={() => navigate(`/offer/${salesType}/${type}`)}
              css={{ width: 180, height: 45 }}
              bordered
            >
              <Txt e2>{t('btn_searchMore')}</Txt>
            </Button>
          )}
          {!isLoading && (
            <Row css={{ alignItems: 'center', gap: 10 }}>
              <Txt e1>{t('buy')}</Txt>
              <Toggle value={isToggle} setValue={toggleHandle} />
              <Txt e1>{t('sell')}</Txt>
            </Row>
          )}
        </Row>
      </Col>
    </Div>
  );
};
