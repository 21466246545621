import React from 'react';
import { Row } from '../../styles/Layout/Row';
import { Txt } from '../StyleGuide/Components/Txt';
import { Col } from '../../styles/Layout/Col';
import { useTranslation } from 'react-i18next';
import Tab from '../Shared/Tab';
import PhoneForm from './components/PhoneForm';
import EmailForm from './components/EmailForm';
import ForgotLoginInputForm from './components/ForgotLoginInputForm';
import SingleTab from '../Shared/SingleTab';

function ForgotLogin() {
  const { t } = useTranslation('auth');

  return (
    <Row
      css={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Col
        css={{
          gap: 20,
          width: '100%',
          maxWidth: 445,
          '@mb': { maxWidth: '100%' },
        }}
      >
        <Txt
          css={{
            width: '100%',
            textAlign: 'left',
            '@mb': {
              fontSize: 22,
              textAlign: 'left',
            },
          }}
          h3
        >
          {t('login_forgot_title')}
        </Txt>
        <Txt
          css={{
            width: '100%',
            textAlign: 'left',
          }}
          h6
        >
          {t('login_forgot_reset')}
        </Txt>
        <SingleTab
          color={'$primary'}
          alignLeft
          containerCss={{ width: '100%' }}
          css={{
            maxWidth: '100%',
            width: '100%',
            flexWrap: 'nowrap',
          }}
        >
          <SingleTab.Item
            title={t('email')}
            fontSize={14}
            content={
              <ForgotLoginInputForm
                title={t('register_email')}
                buttonTitle={t('login_forgot_requestpassword')}
                t={t}
              />
            }
          />
        </SingleTab>
      </Col>
    </Row>
  );
}

export default ForgotLogin;
