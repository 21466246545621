import React from 'react';
import { Row } from '../../../../styles/Layout/Row';
import { styled } from '../../../../styles/stitches.config';
import { Txt } from '../../../StyleGuide/Components/Txt';
import { Button } from '../../../StyleGuide/Components/Button';
import { useTranslation } from 'react-i18next';
import { Excoinz } from '../../../Shared/Excoinz';
import { LangPickerDt } from '../../../Shared/LangaugePicker/LangaugePicker.dt';
import { BuyContents, MenuContents, SellContents } from './NavContents.dt';
import { Span } from '../../../../styles/Layout/Span';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  NavBarContentsButton,
  NavBarContentsDiv,
} from './NavBarContentsButton';
import { useSelector } from 'react-redux';
import UserInterface from '../UserInterface/UserInterface';

export const NavigateBarDt = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state.user.isLogin);

  return (
    <NavBarContainer>
      <Excoinz css={{ position: 'relative', marginRight: 40 }} />
      <Row
        css={{
          flex: 1,
        }}
      >
        <Row
          css={{
            gap: 40,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}
        >
          <NavBarContentsButton
            contents={<BuyContents />}
            url={'/offer/buy/bitcoin'}
          >
            <NavBtnText>{t('buy')}</NavBtnText>
          </NavBarContentsButton>
          <NavBarContentsButton
            contents={<SellContents />}
            url={'/offer/sell/bitcoin'}
          >
            <NavBtnText>{t('sell')}</NavBtnText>
          </NavBarContentsButton>
          <NavText d5 url={'/offer/create'}>
            {t('offer')}
          </NavText>
          <NavText d5 url={'/wallet'}>
            {t('wallet')}
          </NavText>
          <NavBarContentsDiv
            contents={<MenuContents />}
            title={t('company').toUpperCase()}
          ></NavBarContentsDiv>
          {/*<NavBarContentsButton contents={<ConvertContents/>} url={"/offer/convert"}>*/}
          {/*    <NavBtnText>{t('convert')}</NavBtnText>*/}
          {/*</NavBarContentsButton>*/}
        </Row>
      </Row>
      {isLogin ? (
        <UserInterface css={{ position: 'relative', marginLeft: 40 }} />
      ) : (
        <Row
          css={{
            gap: 10,
            position: 'relative',
            right: 20,
            marginLeft: 40,
          }}
        >
          <NavButton onClick={() => navigate('/login')} bordered>
            {t('login')}
          </NavButton>
          <NavButton onClick={() => navigate('/register')}>
            {t('join')}
          </NavButton>
          <LangPickerDt height={45} />
        </Row>
      )}
    </NavBarContainer>
  );
};

const NavText = ({ children, url }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Txt
      d5
      css={{
        color: location.pathname.startsWith(url) ? '$primary' : '$text',
        cursor: 'pointer',

        '&:hover': {
          color: '$primary',
        },
      }}
      onClick={() => {
        navigate(url);
      }}
    >
      {children}
    </Txt>
  );
};

const NavBtnText = styled(Span, {
  fontFamily: 'GmarketSansTTFBold',
  fontStyle: 'normal',
  fontWeight: 500,
  letterSpacing: '0.1em',
  fontFeatureSettings: "'pnum' on, 'lnum' on",
  fontSize: '13px',
  cursor: 'pointer',
});

const NavButton = styled(Button, {
  height: 45,
  cursor: 'pointer',
});

const NavBarContainer = styled(Row, {
  position: 'fixed',
  width: '100vw',
  height: 75,
  zIndex: 100,
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid $mypageLine',
  backgroundColor: '$bg',
});
