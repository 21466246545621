import React from 'react';
import { LoadingIndicator } from './LoadingIndicator';
import { Txt } from '../StyleGuide/Components/Txt';
import { Col } from '../../styles/Layout/Col';
import { useTranslation } from 'react-i18next';

function LoadingComponent({ css }) {
  const { t } = useTranslation('common');

  return (
    <Col
      css={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        marginTop: 30,
        minHeight: 300,
        ...css,
      }}
    >
      <LoadingIndicator />
      <Txt>{t('loading')}</Txt>
    </Col>
  );
}

export default LoadingComponent;
