import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  request,
  setToken,
} from './base/apiCore';

let refreshTokenPromise = null;

// export const refreshToken = async () =>{
//     // If a refresh is already in progress, return the same promise
//     if (refreshTokenPromise) return refreshTokenPromise;
//     // Create a new promise for the refresh token request
//     refreshTokenPromise = new Promise(async (resolve, reject) => {
//         let refresh = window.localStorage.getItem(REFRESH_TOKEN_KEY);
//         let access = window.localStorage.getItem(ACCESS_TOKEN_KEY);
//
//         if (!access || !refresh || access === 'undefined' || refresh === 'undefined') {
//             console.log("refreshToken", "token is null");
//             reject('Token is null');
//         }else {
//             try {
//                 let response = await request({
//                     url: '/auth/refresh',
//                     method: 'POST',
//                     data: {
//                         access: access,
//                         refresh: refresh,
//                     },
//                 });
//                 setToken(response);
//                 resolve(response);
//             } catch (e) {
//                 return null;
//             } finally {
//                 // Once the request is complete, reset the promise
//                 refreshTokenPromise = null;
//             }
//         }
//     });
//
//     return refreshTokenPromise;
// }

export const refreshToken = async () => {
  let refresh = window.localStorage.getItem(REFRESH_TOKEN_KEY);
  let access = window.localStorage.getItem(ACCESS_TOKEN_KEY);

  if (
    !access ||
    !refresh ||
    access === 'undefined' ||
    refresh === 'undefined'
  ) {
    return;
  }

  try {
    console.log('------------------- refresh request -------------------');
    let response = await request({
      url: '/auth/refresh',
      method: 'POST',
      data: {
        access: access,
        refresh: refresh,
      },
    });
    setToken(response);
    return response;
  } catch (e) {
    return false;
  }
};

export const checkAuthCode = async (code, type) => {
  return await request({
    method: 'GET',
    url: '/auth/verify',
    params: {
      code: code,
      type: type,
    },
  });
};

export const resendPhone = async (captcha) => {
  return await request({
    method: 'POST',
    url: '/auth/resend/phone',
    data: {
      captcha: captcha,
    },
  });
};

export const resendEmail = async (captcha) => {
  return await request({
    method: 'POST',
    url: '/auth/resend/email',
    data: {
      captcha: captcha,
    },
  });
};

export const addEmailVerification = async (captcha, email) => {
  return await request({
    method: 'POST',
    url: '/auth/add/email',
    data: {
      captcha: captcha,
      email: email,
    },
  });
};

export const addPhoneVerification = async (captcha, phone, country) => {
  return await request({
    method: 'POST',
    url: '/auth/add/phone',
    data: {
      captcha: captcha,
      phone,
      country,
    },
  });
};

export const resendID = async () => {
  return await request({
    method: 'POST',
    url: '/auth/verification/kyc',
  });
};
export const resendAddress = async () => {
  return await request({
    method: 'POST',
    url: '/auth/verification/address',
  });
};

export const checkAuthState = async (authType) => {
  console.log('authType::', authType);
  return await request({
    method: 'GET',
    url: '/auth/verify/state',
    params: {
      type: authType,
    },
  });
};
export const uploadKycImage = async (file, docType, country) => {
  console.log('file::', file);
  const formData = new FormData();
  formData.append('file[]', file[0]);
  formData.append('file[]', file[1]);
  formData.append('docType', docType);
  formData.append('country', country);
  return await request({
    method: 'POST',
    url: '/image-upload/kyc',
    data: formData,
    contentType: 'multipart/form-data',
    onUploadProgress: (progressEvent) => {
      console.log(
        `Upload Progress: ${Math.round((progressEvent.loaded / progressEvent.total) * 100)}%`,
      );
    },
  });
};

export const uploadAddressImage = async (
  file,
  docType,
  country,
  region,
  city,
  address1,
  address2,
  zipCode,
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('docType', docType);
  formData.append('country', country);
  formData.append('region', region);
  formData.append('city', city);
  formData.append('address1', address1);
  formData.append('address2', address2);
  formData.append('zipCode', zipCode);

  return await request({
    method: 'POST',
    url: '/image-upload/address',
    data: formData,
    onUploadProgress: (progressEvent) => {
      console.log(
        `Upload Progress: ${Math.round((progressEvent.loaded / progressEvent.total) * 100)}%`,
      );
    },
  });
};

export const otpRegister = async () => {
  return await request({
    method: 'POST',
    url: '/auth/otp/register',
  });
};

export const validateOtp = async (otp) => {
  return await request({
    url: '/auth/otp/validation',
    method: 'POST',
    data: {
      otp: otp,
    },
  });
};

export const otpRegisterComplete = async (otp) => {
  return await request({
    url: '/auth/otp/register/complete',
    method: 'POST',
    data: {
      otp: otp,
    },
  });
};

export const isOtpUser = async () => {
  return await request({
    url: '/auth/otp/user',
    method: 'GET',
  });
};

export const isPaymentPwdActive = async () => {
  return await request({
    url: '/auth/payment-pwd/user',
    method: 'GET',
  });
};

export const paymentPwdRegisterComplete = async (pwd) => {
  return await request({
    url: '/auth/payment-pwd/user',
    method: 'POST',
    data: {
      pwd: pwd,
    },
  });
};

export const requestPhoneChange = async (phone, country, pwd) => {
  return await request({
    url: '/auth/send/verify/phone',
    method: 'POST',
    data: {
      phone: phone,
      country: country,
      pwd: pwd,
    },
  });
};

export const requestEmailChange = async (email, pwd) => {
  return await request({
    url: '/auth/send/verify/email',
    method: 'POST',
    data: {
      email: email,
      pwd: pwd,
    },
  });
};
