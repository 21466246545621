import { styled } from '../../../styles/stitches.config';

export const Button = styled('button', {
  backgroundColor: '$primary',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  cursor: 'pointer',
  padding: '7px 15px',
  userSelect: 'none',

  color: '$darkGray',
  fontFamily: 'SpoqaHanSansNeo-Bold',
  fontSize: 13,
  fontWeight: 700,

  minWidth: 75,
  height: 31,
  borderRadius: 6,
  border: 'none',

  '&:hover': {
    background: 'linear-gradient(356.91deg, #EF484C 5.12%, #F7941D 94.88%);',
  },
  '&:active': {
    transform: 'translate(0.5px,0.5px)',
  },
  '&[disabled]': {
    opacity: '20%',
  },

  '@dt': {
    borderRadius: 6,
  },
  '@mb': {
    borderRadius: 10,
  },

  variants: {
    lg: {
      true: {
        width: '90%',
        maxWidth: 328,
        height: 45,
        borderRadius: 10,
        fontSize: 15,
        padding: '12px 15px 14px',
      },
    },
    bordered: {
      true: {
        color: '$text',
        backgroundColor: 'transparent',
        border: '1px solid $opacity',

        '&:hover': {
          background: 'rgba(0, 0, 0, 0.1);',
          border: '1px solid $border;',
        },
        '&:focus': {
          background: 'rgba(0, 0, 0, 0.1);',
          border: '1px solid $border;',
        },
        '&[disabled]': {
          opacity: '20%',
        },
      },
    },
  },
});
