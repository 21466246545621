import { handleActions } from 'redux-actions';
import { countryUpdateComplete } from '../actions/countryAction';

export const countryDefault = {
  list: [],
};

export const countryReducer = handleActions(
  {
    [countryUpdateComplete](state, data) {
      return {
        list: data.payload,
      };
    },
  },
  countryDefault,
);
