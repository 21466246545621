import React from 'react';
import { Div } from '../../../styles/Layout/Div';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Col } from '../../../styles/Layout/Col';
import { Icon } from '../Icon';
import { Button } from '../../StyleGuide/Components/Button';
import { useSelector } from 'react-redux';

function NotificModerator({ setVisible, navigate, t }) {
  const moderator = useSelector((state) => state.noti.moderator);

  return (
    <Div css={{ padding: 15, width: '100%' }}>
      <Txt css={{ color: '$subTextGray' }} b6>
        {t('notific_notifications')}
      </Txt>
      <Col
        css={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          marginTop: 20,
        }}
      >
        {moderator.length === 0 && (
          <React.Fragment>
            <Icon
              css={{ width: 60, height: 60 }}
              src={'/images/icon-search-result.svg'}
            />
            <Txt css={{ color: '$subTextGray', marginBottom: 10 }} b4>
              {t('notific_alarm')}
            </Txt>
          </React.Fragment>
        )}
        <Button
          onClick={() => {
            navigate('/my/notification');
            setVisible(false);
          }}
          css={{ width: 'inherit' }}
          bordered
        >
          {t('notific_show')}
        </Button>
      </Col>
    </Div>
  );
}

export default NotificModerator;
