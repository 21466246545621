import React from 'react';
import { Div } from '../../styles/Layout/Div';
import { AnimatedDiv } from '../../styles/Layout/AnimatedDiv';

export function CurrencyDropDownAnimationBox({
  sp,
  margin = 0,
  left = 0,
  children,
}) {
  return (
    <AnimatedDiv
      css={{ position: 'absolute' }}
      style={{
        zIndex: 1,
        opacity: sp.opacity.to((o) => o),
        top: sp.top.to((o) => o),
        left: left,
        width: '100%',
      }}
    >
      <Div
        css={{
          width: '100%',
          height: margin,
          top: -margin,
        }}
      />
      {children}
    </AnimatedDiv>
  );
}
