import React from 'react';
import { Col } from '../../../styles/Layout/Col';
import { Row } from '../../../styles/Layout/Row';
import { Icon } from '../Icon';
import { offModal } from '../../../redux/actions/modalAction';
import { Button } from '../../StyleGuide/Components/Button';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export default function VerificationModal({ width, height }) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Col
        css={{
          alignItems: 'center',
          width: '100%',
          gap: 30,
          '@mb': {
            width: '90%',
            // gap: 170
          },
        }}
      >
        <Row
          css={{
            justifyContent: 'space-between',
            width: '100%',
            '@mb': { paddingTop: 20 },
          }}
        >
          <Txt h5>{t('verifcation_request')}</Txt>
          <Icon
            onClick={() => {
              dispatch({ type: offModal });
            }}
            css={{ width: 16, height: 16, cursor: 'pointer' }}
            src={'/images/icon-close.svg'}
          />
        </Row>
        <Col
          css={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <Icon
            src={'/images/icon-digital-currencies.svg'}
            css={{
              marginBottom: 15,
            }}
          />
          <Txt>{t('verify_guide01')}</Txt>
          <Txt>{t('verify_guide02')}</Txt>
        </Col>
        <Button
          onClick={() => {
            dispatch({ type: offModal });
            window.location.href = '/verify';
          }}
          css={{
            width: '100%',
            height: 45,
            padding: '12px 15px 14px',
            '@mb': {
              minWidth: 328,
              height: 45,
            },
          }}
        >
          <Txt css={{ color: '$darkGray' }} e2>
            {t('start_verification02')}
          </Txt>
        </Button>
      </Col>
    </React.Fragment>
  );
}
