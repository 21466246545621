import React from 'react';
import { Div } from '../../../styles/Layout/Div';
import { useTranslation } from 'react-i18next';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Button } from '../../StyleGuide/Components/Button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const StartSection = ({ tbPadding }) => {
  const { t } = useTranslation('main');
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state.user.isLogin);

  return (
    <Div
      css={{
        width: '100%',
        height: '676px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 16px',
        '@tb': { marginTop: 160, marginBottom: 160, height: 'auto' },
        '@mb': { marginTop: 80, marginBottom: 80, height: 'auto' },
      }}
    >
      <Txt h2 css={{ '@tb': { fontSize: 30 } }}>
        {t('start')}
      </Txt>
      <Txt
        b2
        css={{
          color: '$subText',
          marginTop: 15,
          marginBottom: 40,
          '@tb': { fontSize: 16 },
        }}
      >
        {t('start_sub')}
      </Txt>
      <Button onClick={() => navigate(isLogin ? '/offer/buy' : '/register')} lg>
        {isLogin ? t('search') : t('create')}
      </Button>
    </Div>
  );
};
