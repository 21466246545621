import { styled } from '../../../styles/stitches.config';

export const Hr = styled('hr', {
  border: 'none',
  borderTop: '1px solid $border',
  maxWidth: 1110,
  width: '90%',
  height: 1,
  opacity: '20%',
  marginTop: 80,
  marginBottom: 50,
});
