import React from 'react';
import { Div } from '../../../styles/Layout/Div';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import Tab from '../../Shared/Tab';
import { BuyCardList, SellCardList } from './WayCardList';
import { Button } from '../../StyleGuide/Components/Button';
import { useNavigate } from 'react-router-dom';
import { Col } from '../../../styles/Layout/Col';
import { useSelector } from 'react-redux';

export const WaySection = ({ tbPadding }) => {
  const { t } = useTranslation('main');
  const navigate = useNavigate();

  const isLogin = useSelector((state) => state.user.isLogin);

  return (
    <Div
      css={{
        width: '100%',
        background: '$boxBg',
        minHeight: '1028px',
        marginTop: -52,
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 192,
        '@mb': {
          marginTop: 0,
          paddingTop: 50,
        },
      }}
    >
      <Col
        css={{
          width: '100%',
          alignItems: 'center',
          gap: 15,
          paddingBottom: 40,
          '@tb': {
            paddingBottom: 30,
            ...tbPadding,
          },
          '@mb': {
            width: '100%',
            padding: '0 16px 30px 16px',
          },
        }}
      >
        <Txt h2 css={{ '@tb': { fontSize: 30 } }}>
          {t('ways1')}
        </Txt>
        <Txt h2 css={{ '@tb': { fontSize: 30 } }}>
          {t('ways2')}
        </Txt>
        <Txt b2 css={{ color: '$subTextGray' }}>
          {t('ways_sub')}
        </Txt>
      </Col>

      <Tab color={'$text'}>
        <Tab.Item title={t('payment')} content={<SellCardList />} />
        <Tab.Item title={t('usage')} content={<BuyCardList />} />
      </Tab>
      <Button
        onClick={() => navigate(isLogin ? '/offer/create' : '/login')}
        lg
        css={{
          marginTop: 40,
        }}
      >
        {t('trading')}
      </Button>
    </Div>
  );
};
