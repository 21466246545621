import { handleActions } from 'redux-actions';
import {
  addNotification,
  initNotification,
  readAllNotification,
  readNotification,
} from '../actions/notificationAction';

const defaultState = {
  notifications: [],
  moderator: [],
  page: 1,
  lastNotiId: 0,
  noReadNoti: false,
};

export default handleActions(
  {
    [initNotification](state, data) {
      return {
        ...state,
        notifications: [...data.payload],
        noReadNoti: checkIsNoRead(data.payload),
      };
    },
    [addNotification](state, data) {
      return {
        ...state,
        notifications: [...state.notifications, ...data.payload],
        noReadNoti: checkIsNoRead(data.payload),
        page: state.page + 1,
      };
    },
    [readNotification](state, data) {
      let newList = state.notifications.map((notification, index) => {
        if (index === data.payload) {
          notification.isRead = true;
        }
        return notification;
      });

      return {
        ...state,
        notifications: newList,
        noReadNoti: checkIsNoRead(newList),
      };
    },
    [readAllNotification](state, data) {
      return {
        ...state,
        notifications: state.notifications.map((notification, index) => {
          notification.isRead = true;
          return notification;
        }),
        noReadNoti: false,
      };
    },
  },
  defaultState,
);

function checkIsNoRead(data) {
  let isNoRead = false;
  data.forEach((item) => {
    if (!item.isRead) {
      isNoRead = true;
    }
  });
  return isNoRead;
}
