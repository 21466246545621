import React, { useEffect } from 'react';
import { Div } from '../../../../styles/Layout/Div';
import { Icon } from '../../../Shared/Icon';

function UserIconMobile({ setVisibleUser, visibleUser, setVisibleNoti }) {
  useEffect(() => {
    return () => setVisibleUser(false);
  }, []);

  return (
    <Div>
      <Div
        css={{
          width: 45,
          height: 45,
          borderRadius: 10,
          backgroundColor: '$primary',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            background: '$gradient',
          },
        }}
        onClick={() => {
          setVisibleUser(!visibleUser);
          setVisibleNoti(false);
        }}
      >
        <Icon src={'/images/icon-user.svg'} />
      </Div>
    </Div>
  );
}

export default UserIconMobile;
