import React, { useState } from 'react';
import { Row } from '../../../../styles/Layout/Row';
import { Txt } from '../../../StyleGuide/Components/Txt';
import { Icon } from '../../../Shared/Icon';
import { AnimatedDiv } from '../../../../styles/Layout/AnimatedDiv';
import { useSpring } from '@react-spring/web';
import { styled } from '@stitches/react';
import { Col } from '../../../../styles/Layout/Col';

export default function SidebarCommonDropdown({
  defaultValue = false,
  title,
  data,
  render,
}) {
  const [on, setOn] = useState(defaultValue);

  const sp = useSpring({
    opacity: on ? 1 : 0,
    rotate: on ? 180 : 0,
  });

  return (
    <Container>
      <TitleBox
        show={on}
        css={{
          justifyContent: 'space-between',
          width: '100%',
        }}
        onClick={() => setOn(!on)}
      >
        <Txt css={{ fontSize: 14 }} e2>
          {title}
        </Txt>
        <AnimatedDiv
          style={{
            cursor: 'pointer',
            transform: sp.rotate.to((o) => `rotate(${o}deg)`),
          }}
        >
          <Icon css={{ cursor: 'pointer' }} src={'/images/icon-down.svg'} />
        </AnimatedDiv>
      </TitleBox>
      {on && (
        <TitleContent show={on} css={{ width: '100%' }}>
          <Col css={{ gap: 15 }}>{data?.length > 0 && data?.map(render)}</Col>
        </TitleContent>
      )}
    </Container>
  );
}

const Container = styled(Col, {
  width: '100%',
});
const TitleBox = styled(Row, {
  display: 'flex',
  cursor: 'pointer',
  flex: '1',
  padding: '15px',
  '&:active': {
    filter: 'brightness(0.8)',
  },
  variants: {
    show: {
      false: {
        borderBottom: '1px solid $mypageLine',
      },
    },
  },
});
const TitleContent = styled(Row, {
  width: '100%',
  padding: '0 15px',
  variants: {
    show: {
      true: {
        borderBottom: '1px solid $mypageLine',
        paddingBottom: '15px',
      },
    },
  },
});
