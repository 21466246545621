import React, { Suspense, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { MainPage } from '../Main/MainPage';
import { Header } from './components/Header';
import { NotFound } from './NotFound';
import { AuthPage } from '../Auth/AuthPage';
import { useDispatch, useSelector } from 'react-redux';
import { ToastProvider } from '../Toast/ToastProvider';
import { refreshToken } from '../../api/authService';
import {
  userLoginFromJwt,
  userNotHaveJWT,
} from '../../redux/actions/userAction';
import AuthGuard from '../Auth/AuthGuard';
import { LoadingIndicator } from '../Shared/LoadingIndicator';
import { Txt } from '../StyleGuide/Components/Txt';
import { Col } from '../../styles/Layout/Col';
import { Row } from '../../styles/Layout/Row';
import { Icon } from '../Shared/Icon';
// import SystemMaintenance from "../SystemMaintenance";

const GuidePage = React.lazy(() => import('../StyleGuide/GuidePage'));
const VendorPage = React.lazy(
  () => import('../../containers/Vendor/VendorPage'),
);
const WalletPage = React.lazy(() => import('../Wallet/WalletPage'));
const UserWalletPage = React.lazy(() => import('../Wallet/UserWalletPage'));
const OfferPage = React.lazy(() => import('../Offer/OfferPage'));
const CardPage = React.lazy(() => import('../Card/CardPage'));
const MyPage = React.lazy(() => import('../My/MyPage'));
const OfferTradePage = React.lazy(() => import('../OfferTrade/OfferTradePage'));
const NotificationPage = React.lazy(
  () => import('../Notification/NotificationPage'),
);
const VerifyPage = React.lazy(() => import('../Auth/VerifyPage'));
const PolicyPage = React.lazy(() => import('../Policy/PolicyPage'));
const Afilliate = React.lazy(() => import('../Afilliate/Afilliate'));
const OfferDetailPage = React.lazy(
  () => import('../OfferDetail/OfferDetailPage'),
);
const ReceiptPage = React.lazy(() => import('../Offer/ReceiptPage'));
const AddressPage = React.lazy(() => import('../Verification/AddressPage'));
const KycPage = React.lazy(() => import('../Verification/KycPage'));
const About = React.lazy(() => import('../About/About'));
const Profile = React.lazy(() => import('../Profile/Profile'));
const CreateOfferPage = React.lazy(
  () => import('../CreateOffer/CreateOfferPage'),
);
const PaymentProgram = React.lazy(
  () => import('../PaymentProgram/PaymentProgram'),
);

export default function RootRouter() {
  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state.user.isLogin);
  const user = useSelector((state) => state.user.data);

  // const [isMaintenance, setIsMaintenance] = React.useState(true);
  // const [sequence, setSequence] = useState('');
  //
  // useEffect(() => {
  //     const handleKeydown = (event) => {
  //         setSequence((prevSequence) => {
  //             const newSequence = prevSequence + event.key;
  //             if (newSequence.endsWith('abcdef')) {
  //                 setIsMaintenance((prev) => !prev);
  //                 return '';
  //             }
  //             return newSequence;
  //         });
  //     };
  //
  //     window.addEventListener('keydown', handleKeydown);
  //
  //     return () => {
  //         window.removeEventListener('keydown', handleKeydown);
  //     };
  // }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  useEffect(() => {
    //alert('call refreshToken');
    refreshToken()
      .then((result) => {
        //alert('refreshToken'+ result);
        if (!!result === false) {
          console.log('refreshToken false');
          dispatch({ type: userNotHaveJWT });
        } else {
          dispatch(userLoginFromJwt(result));
        }
      })
      .catch((e) => {
        //alert('refreshToken error'+ e);
      })
      .finally((e) => {
        //alert('refreshToken finally'+ e);
      });
  }, []);

  useEffect(() => {
    if (user?.authLevel === 0) {
      navigate('/verify');
    }
  }, [user]);

  // if(isMaintenance) {
  //     return <SystemMaintenance />;
  // }

  return (
    <Suspense
      fallback={
        <Col
          css={{
            background: '$bg',
            width: '100%',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
          }}
        >
          <Icon
            src={'/images/excoinz-logo.svg'}
            css={{
              marginBottom: '10px',
            }}
          />
          <Row
            css={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            <Txt
              e4
              css={{
                color: '$subText',
              }}
            >
              Loading...
            </Txt>
            <LoadingIndicator />
          </Row>
        </Col>
      }
    >
      <Routes>
        <Route path={'/'} element={<ToastProvider />}>
          <Route path={'/'} element={<Header />}>
            <Route path={'/'} element={<MainPage></MainPage>} />
            <Route
              path={'/wallet'}
              element={
                isLogin ? (
                  <AuthGuard>
                    <UserWalletPage />
                  </AuthGuard>
                ) : (
                  <WalletPage />
                )
              }
            />
            <Route path={'/vendor'} element={<VendorPage />} />
            <Route path={'/afilliate'} element={<Afilliate />} />
            <Route path={'/card'} element={<CardPage />} />
            <Route path={'/offer/create'} element={<CreateOfferPage />} />
            <Route path={'/offer/edit/*'} element={<CreateOfferPage />} />
            <Route
              path={'/offer/trade/*'}
              element={
                <AuthGuard>
                  <OfferTradePage />
                </AuthGuard>
              }
            />
            <Route
              path={'/offer/detail/*'}
              element={
                <AuthGuard>
                  <OfferDetailPage />
                </AuthGuard>
              }
            />
            {/*<Route path={"/offer/receipt/*"} element={<AuthGuard><ReceiptPage/></AuthGuard>}/>*/}
            <Route path={'/offer/receipt/*'} element={<ReceiptPage />} />
            <Route
              path={'/offer/:offerType/:assetName'}
              element={<OfferPage />}
            />
            <Route
              path={'/offer/:offerType/'}
              element={<Navigate replace to="/offer/buy/bitcoin" />}
            />
            <Route path={'/card'} element={<CardPage />} />
            <Route
              path={'/my/*'}
              element={
                <AuthGuard>
                  <MyPage />
                </AuthGuard>
              }
            />
            <Route path={'/notification'} element={<NotificationPage />} />
            <Route path={'/policy/*'} element={<PolicyPage />} />
            <Route path={'/about'} element={<About />} />
            <Route path={'/payment-program'} element={<PaymentProgram />} />
            <Route path={'/profile/*'} element={<Profile />} /> {/*임시프로필*/}
          </Route>
          <Route path={'*'} element={<NotFound />} />
          <Route
            path={'/login'}
            element={
              <AuthGuard onlyLogin>
                <AuthPage login />
              </AuthGuard>
            }
          />
          <Route path={'/register'} element={<AuthPage register />} />
          <Route path={'/login/forgot'} element={<AuthPage forgotLogin />} />
          <Route
            path={'/verify'}
            element={
              <AuthGuard>
                <VerifyPage />
              </AuthGuard>
            }
          />
          <Route path={'/guide'} element={<GuidePage />} />
          <Route
            path={'/verify/address'}
            element={
              <AuthGuard>
                <AddressPage />
              </AuthGuard>
            }
          />
          <Route
            path={'/verify/kyc'}
            element={
              <AuthGuard>
                <KycPage />
              </AuthGuard>
            }
          />
        </Route>
      </Routes>
    </Suspense>
  );
}
