import React, { useEffect, useState } from 'react';
import { AnimatedDiv } from '../../../styles/Layout/AnimatedDiv';
import { useSpring } from '@react-spring/web';

export const ScrollAlphaComponent = ({ children, scrollStart, scrollEnd }) => {
  const [opacity, setOpacity] = useState(0);

  const props = useSpring({
    opacity: opacity,
  });

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY <= scrollStart) {
        setOpacity(0);
      } else if (currentScrollY >= scrollEnd) {
        setOpacity(1);
      } else {
        const ratio =
          (currentScrollY - scrollStart) / (scrollEnd - scrollStart);
        setOpacity(ratio);
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    // remove event listener when the component is unmounted or before it is updated
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollStart, scrollEnd]);

  return (
    <AnimatedDiv
      css={{
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      style={props}
    >
      {children}
    </AnimatedDiv>
  );
};
