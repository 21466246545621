import { Section } from '../../../styles/Layout/Section';
import { NavigateBarDt } from './NavBar/NavigateBar.dt';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from '../../Shared/useMediaQuery';
import { NavigateBarMb } from './NavBar/NavigateBar.mb';
import { FooterDt } from './Footer.dt';
import { FooterMb } from './Footer.mb';
import { Div } from '../../../styles/Layout/Div';
import ScrollButton from '../../Shared/ScrollButton';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { onModal } from '../../../redux/actions/modalAction';
import FavoriteOfferFilter from '../../My/components/FavoriteOffer/FavoriteOfferFilter';
import { Col } from '../../../styles/Layout/Col';
import { NoticeModal } from '../../Shared/Modals/NoticeModal';
export const Header = () => {
  const isDesktop = useMediaQuery('dt');
  const dispatch = useDispatch();

  // useEffect(() => {
  //     onNoticeModal();
  // }, []);

  // function onNoticeModal(){
  //     let isNoShow = localStorage.getItem('isNoShow');
  //     if(isNoShow === 'true') return;
  //     dispatch({
  //         type: onModal,
  //         payload: <NoticeModal/>
  //     })
  // }

  return (
    <Section>
      {isDesktop ? <NavigateBarDt /> : <NavigateBarMb />}
      <Div
        css={{
          marginTop: 75,
          width: '100%',
        }}
      >
        <Outlet />
      </Div>
      <ScrollButton />
      {isDesktop ? <FooterDt /> : <FooterMb />}
    </Section>
  );
};
