import { styled } from '../../../styles/stitches.config';
import { useSpring, animated } from '@react-spring/web';
import { useSelector } from 'react-redux';
import VerificationModal from './VerificationModal';

export const ModalContainer = ({
  css,
  onClick,
  children,
  auth = 0,
  width = '100%',
  height = '100%',
  padding = 0,
}) => {
  const user = useSelector((state) => state.user.data);

  const sp = useSpring({
    from: {
      opacity: 0,
      transform: 'scale(0.1)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
  });

  return (
    <AnimateModalSection
      css={{
        ...css,
        '@mb': {
          width: width,
          height: height,
          padding: padding,
          ...css,
        },
      }}
      onClick={(event) => {
        event.stopPropagation();
        if (!!onClick === true) {
          onClick();
        }
      }}
      style={{
        ...sp,
      }}
    >
      {(user?.authLevel ?? 0) < auth ? (
        <VerificationModal width={760} />
      ) : (
        children
      )}
    </AnimateModalSection>
  );
};

const AnimateModalSection = styled(animated.div, {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  backgroundColor: '$bg',
  zIndex: 1001,
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: 6,
  border: 'solid 1px $opacity',
  padding: 30,
  overflow: 'hidden',
});
