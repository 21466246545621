import { styled } from '../stitches.config';
import { animated } from '@react-spring/web';

export const AnimatedDiv = styled(animated.div, {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});
