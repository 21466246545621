import { Row } from '../../../styles/Layout/Row';
import { Icon } from '../../Shared/Icon';

export const SocialGroup = ({ css }) => {
  return (
    <Row
      css={{
        gap: 25,
        ...css,
      }}
    >
      <SocialBtn
        icon={'/images/icon-instagram.svg'}
        url={'https://www.instagram.com/excoinz/'}
      />
      <SocialBtn
        icon={'/images/icon-facebook.svg'}
        url={'https://www.facebook.com/profile.php?id=100092692820765'}
      />
      <SocialBtn
        icon={'/images/icon-twitter.svg'}
        url={'https://twitter.com/excoinz'}
      />
      <SocialBtn
        icon={'/images/icon-youtube.svg'}
        url={'https://www.youtube.com/channel/UCLw584uUgkBenHwQ-gpE_pQ'}
      />
      <SocialBtn
        icon={'/images/icon-tiktok.svg'}
        url={'https://tiktok.com/@excoinz'}
      />
      <SocialBtn
        icon={'/images/icon-threads.svg'}
        url={'https://www.threads.net/@excoinz'}
      />
      <SocialBtn
        icon={'/images/icon-blogger.svg'}
        url={'https://excoinz.blogspot.com/'}
      />
    </Row>
  );
};

const SocialBtn = ({ url, icon }) => {
  return (
    <Icon
      src={icon}
      css={{
        width: 24,
        height: 24,
        cursor: 'pointer',

        '&:hover': {
          filter: 'brightness(0.5)',
        },
      }}
      onClick={() => {
        window.open(url);
      }}
    />
  );
};
