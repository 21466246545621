import React, { useState } from 'react';
import { Button } from '../../StyleGuide/Components/Button';
import { Div } from '../../../styles/Layout/Div';
import { Icon } from '../Icon';
import { AnimatedDiv } from '../../../styles/Layout/AnimatedDiv';
import { useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import { CurrencyDropDownAnimationBox } from '../AnimationDivDown';
import { Txt } from '../../StyleGuide/Components/Txt';

export const ContentsButton = React.forwardRef(
  (
    {
      width = 'auto',
      height = 31,
      margin = 10,
      left,
      isFocus = false,
      isHover = false,
      blurOff = true,
      onClick,
      contents,
      children,
    },
    ref,
  ) => {
    const [on, setOn] = useState(false);

    const sp = useSpring({
      opacity: on ? 1 : 0,
      rotate: on ? 180 : 0,
      top: on ? height + margin : 0,
    });

    const bind = useGesture({
      onHover: () => {
        if (isHover === true) {
          setOn(true);
        }
      },
      onHoverEnd: () => {
        if (isHover === true) {
          setOn(false);
        }
      },
    });

    // Attach someMethod to the ref
    React.useImperativeHandle(ref, () => ({
      setOn,
    }));

    return (
      <Div
        onBlur={() => {
          if (blurOff === true) {
            setTimeout(() => {
              setOn(false);
              console.log('done');
            }, 200);
          }
        }}
        {...bind()}
      >
        <Button
          bordered
          css={{
            height: height,
            width: width,
            borderColor: isFocus && '$primary',
            color: isFocus && '$primary',

            '&:hover': {
              borderColor: isHover && '$primary',
              color: isHover && '$primary',
            },
          }}
          onClick={() => {
            setOn(!on);
            if (onClick !== undefined) {
              onClick();
            }
          }}
        >
          {children}
          <AnimatedDiv
            style={{
              transform: sp.rotate.to((o) => `rotate(${o}deg)`),
            }}
          >
            <Icon
              src={'/images/icon-down.svg'}
              css={{
                width: 11,
              }}
            />
          </AnimatedDiv>
        </Button>
        {on && (
          <CurrencyDropDownAnimationBox left={left} sp={sp} margin={margin}>
            {contents}
          </CurrencyDropDownAnimationBox>
        )}
      </Div>
    );
  },
);

export const ContentsText = React.forwardRef(
  (
    {
      width = 'auto',
      height = 31,
      margin = 10,
      left,
      title,
      isFocus = false,
      isHover = false,
      blurOff = true,
      onClick,
      contents,
      children,
    },
    ref,
  ) => {
    const [on, setOn] = useState(false);

    const sp = useSpring({
      opacity: on ? 1 : 0,
      rotate: on ? 180 : 0,
      top: on ? height + margin : 0,
    });

    const bind = useGesture({
      onHover: () => {
        if (isHover === true) {
          setOn(true);
        }
      },
      onHoverEnd: () => {
        if (isHover === true) {
          setOn(false);
        }
      },
    });

    // Attach someMethod to the ref
    React.useImperativeHandle(ref, () => ({
      setOn,
    }));

    return (
      <Div
        onBlur={() => {
          if (blurOff === true) {
            setTimeout(() => {
              setOn(false);
              console.log('done');
            }, 200);
          }
        }}
        {...bind()}
      >
        <Txt
          css={{
            height: height,
            display: 'flex',
            alignItems: 'center',
            width: width,
            borderColor: isFocus && '$primary',
            color: isFocus && '$primary',
            cursor: 'pointer',

            '&:hover': {
              borderColor: isHover && '$primary',
              color: isHover && '$primary',
            },
          }}
          onClick={() => {
            setOn(!on);
            if (onClick !== undefined) {
              onClick();
            }
          }}
          d5
        >
          {title}
        </Txt>
        {on && (
          <CurrencyDropDownAnimationBox left={left} sp={sp} margin={margin}>
            {contents}
          </CurrencyDropDownAnimationBox>
        )}
      </Div>
    );
  },
);
