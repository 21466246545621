import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { updatePaymentMethod } from '../../redux/actions/paymentMethodAction';

export function usePaymentMethod(paymentId) {
  const method = useSelector((state) => state?.method?.method);
  const dispatch = useDispatch();

  useEffect(() => {
    if (method.length === 0) {
      dispatch(updatePaymentMethod());
    }
  }, []);

  return method.find((item) => item?.methodId === paymentId);
}
