import { request } from './base/apiCore';

export const postTimezone = async (tzCode, assetId) => {
  return await request({
    method: 'POST',
    url: '/users/profile/config',
    data: {
      timezone: tzCode,
      preferenceAsset: assetId,
    },
  });
};

export const updateNameOption = async (nameOption) => {
  return await request({
    method: 'POST',
    url: '/users/profile/config',
    data: {
      nameViewOption: nameOption,
    },
  });
};

export const updateRealName = async (realName) => {
  return await request({
    method: 'POST',
    url: '/users/profile/config',
    data: {
      realName: realName,
    },
  });
};

export const getReferralCode = async () => {
  return await request({
    method: 'GET',
    url: '/users/referral',
  });
};

export const postUserSuggestion = async (suggestion) => {
  return await request({
    method: 'POST',
    url: '/suggestion',
    data: {
      content: suggestion,
    },
  });
};
export const getUserProfile = async () => {
  return await request({
    method: 'GET',
    url: '/users/profile',
  });
};
export const getUserNameChanged = async () => {
  return await request({
    method: 'GET',
    url: '/users/profile/flag',
    params: {
      flag: 'isNameChanged',
    },
  });
};

export const postUserProfile = async (inputName) => {
  return await request({
    method: 'POST',
    url: '/users/profile/name',
    data: {
      name: inputName,
    },
  });
};

export const postPromotionValue = async (promotion) => {
  return await request({
    url: '/users/profile/bio',
    method: 'POST',
    data: {
      bio: promotion,
    },
  });
};

export const signUpEmail = async (email, pwd, referral, captcha) => {
  return await request({
    url: '/users/signUp/email',
    method: 'POST',
    data: {
      email: email,
      password: pwd,
      referral: referral,
      captcha: captcha,
    },
  });
};

export const signUpPhone = async (
  phone,
  pwd,
  countryCode,
  referral,
  captcha,
) => {
  return await request({
    url: '/users/signUp/phone',
    method: 'POST',
    data: {
      phone: phone,
      password: pwd,
      referral: referral,
      countryCode: countryCode,
      captcha: captcha,
    },
  });
};

export const resendAuthPassword = async (id) => {
  return await request({
    method: 'POST',
    url: 'users/forgot-password',
    data: {
      id,
    },
  });
};

export const loginExcoinz = async (id, pwd, captcha) => {
  return await request({
    url: '/users/signIn',
    method: 'POST',
    data: {
      id: id,
      password: pwd,
      captcha: captcha,
    },
  });
};

export const getRecommendUserInfo = async (referralCode) => {
  return await request({
    url: '/users/referral/url',
    method: 'GET',
    params: {
      referralCode,
    },
  });
};

export const checkDuplicatedPhone = async (phone) => {
  return await request({
    url: '/users/isExistedPhone',
    method: 'GET',
    params: {
      phone: phone,
    },
  });
};
export const checkDuplicatedEmail = async (email) => {
  return await request({
    url: '/users/isExistedEmail',
    method: 'GET',
    params: {
      email: email,
    },
  });
};

export const getTradeInfo = async (target) => {
  return await request({
    url: '/users/trade-stat',
    method: 'GET',
    params: {
      target: target,
    },
  });
};

export const getTradeLimit = async () => {
  return await request({
    url: '/users/trade/limit',
    method: 'GET',
  });
};

export const getTradeAnalysis = async (userId) => {
  return await request({
    url: '/users/trade-analysis',
    method: 'GET',
    params: {
      target: userId,
    },
  });
};

export const getUserWebNotification = async (page = 1) => {
  return await request({
    url: '/notification',
    method: 'GET',
    params: {
      page: page,
    },
  });
};

export const readWebNotification = async (userId) => {
  return await request({
    url: '/notification/read',
    method: 'POST',
    body: {
      data: userId,
    },
  });
};

export const readAllWebNotification = async () => {
  return await request({
    url: '/notification/read/all',
    method: 'POST',
  });
};

export const updateUserPreferenceAsset = async (assetId) => {
  return await request({
    url: '/users/preference-asset',
    method: 'GET',
    params: {
      assetId,
    },
  });
};

export const changeUserPassword = async (
  oldPassword,
  newPassword,
  checkNewPassword,
) => {
  return await request({
    url: '/users/change-password',
    method: 'POST',
    data: {
      currentPassword: oldPassword,
      newPassword: newPassword,
      passwordConfirm: checkNewPassword,
    },
  });
};

export const userSignOut = async () => {
  return await request({
    url: '/users/signOut/request',
    method: 'POST',
  });
};

export const changeEmail = async (email, code) => {
  return await request({
    url: '/users/email/change',
    method: 'POST',
    data: {
      email: email,
      code: code,
    },
  });
};

export const changePhone = async (phone, country, code) => {
  return await request({
    url: '/users/phone/change',
    method: 'POST',
    data: {
      phone: phone,
      country: country,
      code: code,
    },
  });
};

export const getRealName = async (type, userId) => {
  return await request({
    url: '/users/real-name',
    method: 'GET',
    params: {
      type: type,
      userId: userId,
    },
  });
};
