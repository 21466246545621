import React from 'react';
import { Row } from '../../../styles/Layout/Row';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { styled } from '../../../styles/stitches.config';
import { Icon } from '../../Shared/Icon';
import { useMediaQuery } from '../../Shared/useMediaQuery';

function MediaSliderContainer() {
  const isDesktop = useMediaQuery('dt');
  const isTablet = useMediaQuery('tb');

  const imageInfoList = [
    // {
    //     href: 'https://www.digitaljournal.com/pr/news/getnews/introduction-of-p2p-crypto-market-status-and-excoinz',
    //     src: '/images/newslogo/mainSliderImage1.png'
    // },
    {
      href: 'https://kwwl.marketminute.com/article/getnews-2023-5-26-introduction-of-p2p-crypto-market-status-and-excoinz',
      src: '/images/newslogo/mainSliderImage8.png',
    },
    {
      href: 'https://news.iresearch.cn/yx/2023/05/471230.shtml',
      src: '/images/newslogo/mainSliderImage2.png',
    },
    {
      href: 'https://markets.financialcontent.com/stocks/article/getnews-2023-6-5-cryptocurrencys-market-status-and-two-leading-p2p-platforms-paxful-and-excoinz',
      src: '/images/newslogo/mainSliderImage3.png',
    },
    {
      href: 'http://yuesi360.com/news/419.html',
      src: '/images/newslogo/mainSliderImage4.png',
    },
    // {
    //     href: 'https://www.wicz.com/story/49085907/how-to-use-cryptocurrency-p2p-platform-for-ofw',
    //     src: '/images/newslogo/mainSliderImage5.png'
    // },
    {
      href: 'https://kwwl.marketminute.com/article/abnewswire-2023-6-27-strengthening-financial-security-and-trust-excoinz-leads-the-way-with-robust-kyc-certification',
      src: '/images/newslogo/mainSliderImage8.png',
    },
    {
      href: 'http://qkl.ruanjinbi.com/109a6/e4634974385.html',
      src: '/images/newslogo/mainSliderImage6.png',
    },

    {
      href: 'https://fwnbc.marketminute.com/article/abnewswire-2023-6-21-excoinz-revolutionizes-the-p2p-cryptocurrency-exchange-landscape-expanding-to-asian-powerhouses',
      src: '/images/newslogo/mainSliderImage7.png',
    },

    {
      href: 'https://news.northamericanreport.com/story/401099/empowering-cryptocurrency-traders-excoinz-p2p-elevates-crypto-trading-with-unparalleled-peertopeer-advantages.html',
      src: '/images/newslogo/mainSliderImage9.png',
    },

    // {
    //     href: 'https://www.wtnzfox43.com/story/49085907/how-to-use-cryptocurrency-p2p-platform-for-ofw',
    //     src: '/images/newslogo/mainSliderImage10.png'
    // },
    {
      href: 'https://business.am-news.com/am-news/markets/article/getnews-2023-6-15-how-to-use-cryptocurrency-p2p-platform-for-ofw//',
      src: '/images/newslogo/mainSliderImage11.png',
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isDesktop ? 5 : isTablet ? 3 : 2,
    autoplay: true,
    autoplaySpeed: 3500,
    centerMode: true,
    centerPadding: 0,
    nextArrow: (
      <Icon
        src={'/images/icon-next.svg'}
        css={{
          cursor: 'pointer',
          '&:hover': {
            filter: 'brightness(0.8)',
          },
          '&:active': {
            filter: 'brightness(0.6)',
          },
        }}
      />
    ),
    prevArrow: (
      <Icon
        src={'/images/icon-prev.svg'}
        css={{
          cursor: 'pointer',
          '&:hover': {
            filter: 'brightness(0.8)',
          },
          '&:active': {
            filter: 'brightness(0.6)',
          },
        }}
      />
    ),
  };

  return (
    <Row
      css={{
        maxWidth: '1100px',
        width: '90%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        zIndex: 9,
        '@mb': { marginTop: 30 },
      }}
    >
      <StyleSlider {...settings} style={{ width: '100%' }}>
        {imageInfoList.map((el, i) => {
          return (
            <a
              target={'_blank'}
              style={{ textDecoration: 'none' }}
              key={i}
              href={el.href}
            >
              <Icon
                src={el.src}
                css={{
                  width: 180,
                  margin: '0 auto',
                  height: 60,
                  '@mb': {
                    width: '110px',
                  },
                }}
              />
            </a>
          );
        })}
      </StyleSlider>
    </Row>
  );
}

const StyleSlider = styled(Slider, {
  padding: '40px 0px',
  '& .slick-list': {
    width: '100%',
    margin: '0 auto',
  },
  '& .slick-slide div': {
    margin: '0 auto',
    cursor: 'pointer',
  },
  '& .slick-prev::before': {
    opacity: 0,
    display: 'none',
  },
  '& .slick-next::before': {
    opacity: 0,
    display: 'none',
  },
  '@mb': { padding: '20px 0px' },
});

export default MediaSliderContainer;
