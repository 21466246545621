import { Input } from '../../StyleGuide/Components/Input';
import React, { useEffect, useState } from 'react';
import { Row } from '../../../styles/Layout/Row';
import { Icon } from '../Icon';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Col } from '../../../styles/Layout/Col';

const InputBox = ({
  code,
  max,
  emailForm,
  emailHandle,
  error,
  id,
  setId,
  isReferralUserInfo,
  css,
  placeholder,
}) => {
  const [errorState, setErrorState] = useState(false);

  async function onBlurHandler() {
    await emailHandle();
  }

  const onChangeHandler = (e) => {
    const { value } = e.target;
    //함수처리
    setId(value);
  };

  useEffect(() => {
    if (error?.length > 0) {
      setErrorState(true);
    } else {
      setErrorState(false);
    }
  }, [error]);

  return (
    <Col css={{ width: '100%' }}>
      <Row
        css={{
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Input
          disabled={!!(code && isReferralUserInfo)}
          maxLength={max ? max : null}
          onBlur={emailForm ? onBlurHandler : null}
          error={errorState}
          placeholder={placeholder}
          onChange={onChangeHandler}
          value={id}
          css={css}
        />
        {code ||
          (id && id.length > 0 && (
            <Icon
              onClick={() => setId('')}
              css={{ position: 'absolute', right: 14 }}
              src={'/images/icon-delete.svg'}
            />
          ))}
      </Row>
      {errorState === true && (
        <Txt
          b6
          css={{
            color: '$lightRed',
            margin: 10,
          }}
        >
          {error}
        </Txt>
      )}
    </Col>
  );
};

export default InputBox;
