import { Page } from '../../styles/Layout/Page';
import { Col } from '../../styles/Layout/Col';
import { Row } from '../../styles/Layout/Row';
import { LangPickerDt } from '../Shared/LangaugePicker/LangaugePicker.dt';
import { useNavigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import React, { useState } from 'react';
import useMeasure from 'react-use-measure';
import { Excoinz } from '../Shared/Excoinz';
import loginAnimationData from '../../lottie/membership-hero.json';
import Lottie from 'react-lottie';
import { useMediaQuery } from '../Shared/useMediaQuery';
import { LangPickerMb } from '../Shared/LangaugePicker/LangaugePicker.mb';
import { AnimatedDiv } from '../../styles/Layout/AnimatedDiv';
import { useSpring } from '@react-spring/web';
import registerAnimationData from '../../lottie/sign-up-hero.json';
import ForgotLogin from './ForgotLogin';

export const AuthPage = ({
  login,
  register,
  verifyPhone,
  verifyEmail,
  forgotLogin,
}) => {
  const [inputPhoneValue, setInputPhoneValue] = useState('');
  const isFold = useMediaQuery('fold');
  const isMobile = useMediaQuery('mb');
  const navigate = useNavigate();
  const [pageRef, { width }] = useMeasure();

  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const spring = useSpring({
    height: isLanguageOpen ? 320 : 0,
  });

  const showLottie = ({ width }) => {
    if (width >= 1240 && login) {
      return <LoginAnimation width={730} />;
    }
    if (width >= 1240 && register) {
      return <RegisterAnimation width={730} />;
    }
    if (width >= 1240 && forgotLogin) {
      return <RegisterAnimation width={730} />;
    }
  };
  const lottieDisplay = ({ width }) => {
    return width <= 1240 ? 'none' : 'flex';
  };
  const mainContent = ({ width }) => {
    return width <= 1240 ? 'center' : 'normal';
  };
  const mainMarginTop = ({ width }) => {
    return width <= 1240 ? 20 : 70;
  };
  return (
    <Page
      css={{
        minHeight: '100vh',
        width: '100vw',
      }}
      ref={pageRef}
    >
      <Col
        css={{
          maxWidth: 1240,
          width: '100%',
          '@tb': { marginTop: 50, alignItems: 'center' },
          '@mb': { marginTop: 0, paddingTop: 16, alignItems: 'center' },
        }}
      >
        <Row
          css={{
            width: '90%',
            gap: 30,
            paddingTop: 80,
            marginBottom: 120,
            justifyContent: mainContent({ width }),
            '@tb': { marginBottom: 80 },
            '@mb': { marginBottom: 60 },
          }}
        >
          <Row
            css={{
              maxWidth: 445,
              width: '100%',
              justifyContent: 'right',
              '@mb': { maxWidth: '100%' },
            }}
          >
            <Excoinz
              onClick={() => navigate('/')}
              css={{
                left: 0,
                cursor: 'pointer',
                '@fold': { width: 120 },
              }}
            />
            {isFold || isMobile ? (
              <LangPickerMb
                boxWidth={width - 5}
                css={{ top: 25 }}
                isLanguageOpen={isLanguageOpen}
                setIsLanguageOpen={setIsLanguageOpen}
              />
            ) : (
              <LangPickerDt height={45} />
            )}
            <AnimatedDiv
              style={{
                height: spring.height.to((val) => `${val}px`),
              }}
            />
          </Row>
          <Row
            css={{
              width: '100%',
              flex: 1,
              display: lottieDisplay({ width }),
            }}
          ></Row>
        </Row>
      </Col>
      <Col
        css={{
          maxWidth: 1240,
          width: '90%',
          marginTop: -80,
          '@tb': { marginTop: 50 },
          '@mb': { marginTop: 0, paddingTop: 16 },
        }}
      >
        <Row
          css={{
            width: '100%',
            height: '100%',
            gap: 30,
            paddingBottom: 120,
            justifyContent: mainContent({ width }),
          }}
        >
          <Row
            css={{
              maxWidth: 445,
              width: '100%',
              justifyContent: 'right',
              marginTop: 70,
              '@tb': { marginTop: mainMarginTop({ width }) },
              '@mb': { maxWidth: '100%', marginTop: 0 },
            }}
          >
            {login && <Login width={width} />}
            {register && (
              <Register
                width={width}
                inputPhoneValue={inputPhoneValue}
                setInputPhoneValue={setInputPhoneValue}
              />
            )}
            {forgotLogin && <ForgotLogin width={width} />}
          </Row>
          <Row
            css={{
              width: '100%',
              maxHeight: 590,
              height: '100%',
              flex: 1,
              display: lottieDisplay({ width }),
            }}
          >
            {showLottie({ width })}
          </Row>
        </Row>
      </Col>
    </Page>
  );
};

function LoginAnimation({ width }) {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: loginAnimationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      style={{
        width: width,
      }}
    />
  );
}

function RegisterAnimation({ width }) {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: registerAnimationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      style={{
        width: width,
      }}
    />
  );
}
