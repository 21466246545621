import { createAction } from 'redux-actions';
import { getCountryAll } from '../../api/countryService';

export const updateCountry = () => {
  return async (dispatch) => {
    try {
      console.log('updateCountry');
      let res = await getCountryAll();
      dispatch({ type: countryUpdateComplete, payload: res });
    } catch (e) {
      console.log(e);
    }
  };
};

export const countryUpdateComplete = createAction('COUNTRY_UPDATE_COMPLETE');
