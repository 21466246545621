import React from 'react';
import { useSpring } from '@react-spring/web';
import { Row } from '../../../styles/Layout/Row';
import { Icon } from '../Icon';
import { AnimatedDiv } from '../../../styles/Layout/AnimatedDiv';
import { Div } from '../../../styles/Layout/Div';
import { CurrencyDropDownAnimationBox } from '../AnimationDivDown';

export const CountryButton = React.forwardRef(
  (
    {
      setOn,
      on,
      css,
      width = 'auto',
      height = 23,
      margin = 10,
      isFocus = false,
      isHover = false,
      blurOff = true,
      left,
      onClick,
      contents,
      children,
    },
    ref,
  ) => {
    // const [on, setOn] = useState(false);

    const sp = useSpring({
      opacity: on ? 1 : 0,
      rotate: on ? 180 : 0,
      top: on ? height + margin : 0,
    });

    // React.useImperativeHandle(ref, () => ({
    //     setOn
    // }));

    return (
      <Div
        tabIndex={0}
        css={{ justifyContent: 'center', flexDirection: 'row', gap: 5, ...css }}
        // onBlur={(event) => {
        //     setTimeout(() => {
        //         setOn(false)
        //     }, 200);
        // }}
      >
        <Row
          css={{
            height: height,
            width: width,
            gap: 5,
            cursor: 'pointer',
          }}
          onClick={() => {
            setOn(!on);
          }}
        >
          {children}
          <AnimatedDiv
            style={{
              transform: sp.rotate.to((o) => `rotate(${o}deg)`),
            }}
          >
            <Icon src={'/images/icon-down.svg'} />
          </AnimatedDiv>
        </Row>
        {on && (
          <CurrencyDropDownAnimationBox left={left} sp={sp} margin={margin}>
            {contents}
          </CurrencyDropDownAnimationBox>
        )}
      </Div>
    );
  },
);
