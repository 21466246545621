import { handleActions } from 'redux-actions';
import { endLoading, startLoading } from '../actions/loadingAction';

export const loadingDefault = {
  isLoading: false,
};

export default handleActions(
  {
    [startLoading]() {
      return {
        isLoading: true,
      };
    },
    [endLoading]() {
      return {
        isLoading: false,
      };
    },
  },
  loadingDefault,
);
