import { decode } from 'bs58check';
import { bech32 } from 'bech32';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertTimestampToText(timestamp) {
  // calculate the difference in minutes
  let diffInMinutes = Math.floor(timestamp / (1000 * 60));

  if (diffInMinutes < 1) {
    return 'Just now';
  }

  if (diffInMinutes < 60) {
    return diffInMinutes + ' Min';
  }

  // calculate the difference in hours
  let diffInHours = Math.floor(timestamp / (1000 * 60 * 60));
  if (diffInHours < 24) {
    return diffInHours + ' Hour';
  }

  // calculate the difference in days
  let diffInDays = Math.floor(timestamp / (1000 * 60 * 60 * 24));
  return diffInDays + ' Day';
}

export function getTimeText(lastAccessTimestamp) {
  // calculate the difference in milliseconds
  let diff = new Date().getTime() - lastAccessTimestamp;

  // calculate the difference in minutes
  let diffInMinutes = Math.floor(diff / (1000 * 60));

  if (diffInMinutes < 1) {
    let diffSeconds = Math.floor(diff / 1000);
    return diffSeconds + ' seconds ago';
  } else if (diffInMinutes < 60) {
    return diffInMinutes + ' minutes ago';
  }

  // calculate the difference in hours
  let diffInHours = Math.floor(diff / (1000 * 60 * 60));
  if (diffInHours < 24) {
    return diffInHours + ' hours ago';
  }

  // calculate the difference in days
  let diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  if (diffInDays < 7) {
    return diffInDays + ' days ago';
  }

  // calculate the difference in weeks
  let diffInWeeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
  if (diffInWeeks < 4) {
    return diffInWeeks + ' weeks ago';
  }

  // calculate the difference in months
  let diffInMonths = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
  if (diffInMonths < 12) {
    return diffInMonths + ' months ago';
  }

  // calculate the difference in years
  let diffInYears = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
  return diffInYears + ' years ago';
}

export function convertToCurrency(amount, usdPrice, targetCurrency) {
  return (amount * usdPrice) / targetCurrency?.price;
}

export function convertAddressToShort(address, start = 14, end = -4) {
  return address.slice(0, start) + '...' + address.slice(end);
}

export function currencyStr(amount, currencyPrice) {
  return (amount / currencyPrice).toLocaleString();
}

export function getAddressAddURIScheme(network, address) {
  switch (network) {
    case 'eth':
      return `ethereum:${address}`;
    case 'btc':
      return `bitcoin:${address}`;
    case 'trx':
      return `tron:${address}`;
    case 'xrp':
      return `ripple:${address}`;
    default:
      return '';
  }
}

export function getNetworkFromAddress(address) {
  // Check Ethereum address format

  if (/^0x[a-fA-F0-9]{40}$/.test(address)) {
    return 'eth';
  }

  // Check TRON address format
  if (address.startsWith('T') || address.startsWith('t')) {
    return 'trx';
  }

  // Check Bitcoin address format
  try {
    decode(address);
    return 'btc';
  } catch (e) {}

  try {
    const decoded = bech32.decode(address);
    if (decoded.prefix === 'bc') {
      return 'btc';
    }
  } catch (error) {}

  // Check Ripple address format
  if (/^r[1-9A-HJ-NP-Za-km-z]{25,34}$/.test(address)) {
    return 'xrp';
  }

  // If no format matches, return false
  return false;
}

export function getAddressType(address, network) {
  switch (network) {
    case 'eth':
      if (/^0x[a-fA-F0-9]{40}$/.test(address)) {
        return 'eth';
      }
      break;
    case 'btc':
      try {
        decode(address);
        return 'btc';
      } catch (e) {}
      try {
        const decoded = bech32.decode(address);
        if (decoded.prefix === 'bc') {
          return 'btc';
        }
      } catch (error) {}
      break;
    case 'trx':
      if (address.startsWith('T') || address.startsWith('t')) {
        return 'trx';
      }
      break;
    case 'xrp':
      if (/^r[1-9A-HJ-NP-Za-km-z]{25,34}$/.test(address)) {
        return 'xrp';
      }
      break;
    default:
      return false;
  }
}

export function getPrice(price, fixedPrice, marginPrice, priceType) {
  if (priceType === 'market') {
    return price * (1 + marginPrice * 0.01);
  } else if (priceType === 'fixed') {
    return price + fixedPrice;
  }
}

export function bitToBoolArray(bits, length) {
  const bools = [];
  for (let i = length - 1; i >= 0; i--) {
    bools.push((bits & (1 << i)) !== 0);
  }
  return bools;
}

export function toLocaleString(value) {
  let str = value.toString();
  if (!!str === false) {
    return '';
  }
  let sp = str.split('.');
  let num = sp[0].replace(/[^0-9]/g, '');
  let num2 = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  let fixed = 4;
  if (num.length > 6) {
    fixed = 2;
  } else if (num.length > 9) {
    fixed = 0;
  }

  if (sp.length > 1) {
    return num2 + '.' + sp[1].slice(0, fixed);
  } else {
    return num2;
  }
}

export function emailRegex(info) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
  const matchedEmail = emailRegex.test(info);
  return matchedEmail;
}

export function checkSameValueObject(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function getToday() {
  const date = new Date();
  const year = date.getFullYear();
  const month = ('0' + (1 + date.getMonth())).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return year + month + day;
}
export function getLastMonthDate() {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const year = date.getFullYear();
  const month = ('0' + (1 + date.getMonth())).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return year + month + day;
}
export function formatDateToDot(value) {
  if (!value) return;
  value = value?.replace(/\D/g, '');

  if (value?.length > 4) {
    value = value?.slice(0, 4) + '.' + value?.slice(4);
  }
  if (value?.length > 7) {
    value = value?.slice(0, 7) + '.' + value?.slice(7);
  }
  // Limit length
  if (value?.length > 10) {
    value = value?.slice(0, 10);
  }
  return value;
}

export const changeComma = (currencyPrice) => {
  let result = '';
  if (currencyPrice.includes('.')) {
    result =
      currencyPrice
        .split('.')[0]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      '.' +
      currencyPrice.split('.')[1];
  } else {
    result = currencyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return result;
};

export function formatDate(inputDate, format = 'comma') {
  // Date 객체를 생성하고 월(month)과 일(day)을 추출
  const year = parseInt(inputDate?.substr(0, 4), 10);
  const month = parseInt(inputDate?.substr(4, 2), 10) - 1; // 월은 0부터 시작하므로 1을 빼줍니다.
  const day = parseInt(inputDate?.substr(6, 2), 10);
  // Date 객체 생성
  const date = new Date(year, month, day);

  // 월의 이름을 가져오기
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthName = monthNames[date.getMonth()];
  const transferDate = date.getDate().toString().padStart(2, '0');
  // 'July 1, 2023' 형식으로 날짜를 포맷팅
  if (format === 'comma') {
    return `${monthName} ${transferDate}, ${date.getFullYear()}`;
  }
  if (format === 'dot') {
    return `${monthName}.${transferDate}.${date.getFullYear()}`;
  }
  if (format === 'dash') {
    return `${monthName}-${transferDate}-${date.getFullYear()}`;
  }
  if (format === 'default') {
    return `${date.getMonth() + 1}-${transferDate}-${date.getFullYear()}`;
  }
}

export function moneyFormat(value, digit = 2) {
  // 숫자로 변환 (입력값이 문자열일 경우 대비)
  const num = Number(value);

  // 정수인 경우 그대로 로케일 문자열로 변환
  if (Number.isInteger(num)) {
    return num.toLocaleString('en-US');
  }

  // 소수인 경우, 10의 digit 제곱수로 나눈 후, Math.floor를 사용하여 소수점 이하를 버림 처리
  const factor = Math.pow(10, digit);
  const trimmed = Math.floor(num * factor) / factor;

  // 실제 소수점 자리수 계산
  const actualDigits = (num.toString().split('.')[1] || '').length;

  // 로케일 문자열로 변환하여 콤마와 자리수를 조정
  return trimmed.toLocaleString('en-US', {
    minimumFractionDigits: Math.min(actualDigits, digit),
    maximumFractionDigits: digit,
  });
}
