import React, { useEffect, useState } from 'react';
import { Div } from '../../../styles/Layout/Div';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '../../Shared/useMediaQuery';

/**
 * 현재 스크롤 상태에 따라 배경 변경
 * @returns {JSX.Element}
 * @constructor
 */
export const MainBg = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = useSelector((state) => state.theme.type);
  const isMobile = useMediaQuery('mb');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrolled(currentScrollY >= 1500);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function getBg() {
    if (isScrolled === true) {
      if (theme === 'light') {
        return (
          <img
            src={'/images/service-start-light.png'}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: 0,
            }}
          />
        );
      } else {
        return (
          <img
            src={'/images/service-start-lg.jpg'}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: 0,
            }}
          />
        );
      }
    }

    if (theme === 'light') {
      return (
        <img
          src={'/images/Backplate-light.png'}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 0,
          }}
        />
      );
    } else if (isMobile === true) {
      return (
        <img
          src={'/images/service-start-lg.jpg'}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 0,
          }}
        />
      );
    } else {
      return (
        <video
          autoPlay
          loop
          muted
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 0,
          }}
        >
          <source src="/video/hero-lg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
  }

  return (
    <Div
      css={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
      }}
    >
      {getBg()}
    </Div>
  );
};
