import React from 'react';
import { Row } from '../../../../styles/Layout/Row';
import { Col } from '../../../../styles/Layout/Col';
import { Txt } from '../../../StyleGuide/Components/Txt';
import { useSelector } from 'react-redux';
import UserIcon from './UserIcon';
import NotiButton from './NotiButton';
import AssetView from './AssetView';

function UserInterface({ css }) {
  const user = useSelector((state) => state?.user?.data);

  return (
    <Row
      css={{
        gap: 10,
        position: 'absolute',
        right: 20,
        alignItems: 'center',
        ...css,
      }}
    >
      <Col
        css={{
          alignItems: 'flex-end',
          marginRight: 10,
          gap: 4,
        }}
      >
        <Txt
          b3
          css={{
            marginTop: 7,
            fontSize: 13,
            fontWeight: 700,
          }}
        >
          {user?.name}
        </Txt>
        <AssetView />
      </Col>
      <UserIcon />
      <NotiButton />
    </Row>
  );
}

export default UserInterface;
