import React from 'react';
import { Div } from '../../../styles/Layout/Div';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../Shared/useMediaQuery';
import { ScrollAlphaComponent } from '../../Shared/AnimateComponents/ScrollAlphaComponent';
import ReviewSliderContainer from './ReviewSliderContainer';
import useMeasure from 'react-use-measure';

export const ReviewSection = () => {
  const { t } = useTranslation('main');
  const isMobile = useMediaQuery('mb');
  const [ref, { width }] = useMeasure();
  return (
    <Div
      ref={ref}
      css={{
        width: '100%',
        height: '780px',
        alignItems: 'center',
        marginTop: 130,
        '@tb': { height: '600px' },
        '@mb': {
          marginTop: 130,
          height: '500px',
        },
      }}
    >
      <ScrollAlphaComponent
        scrollStart={isMobile ? 2500 : 1940}
        scrollEnd={isMobile ? 2900 : 2400}
      >
        <Div
          css={{
            gap: 15,
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0 16px',
          }}
        >
          <Txt h2 css={{ '@tb': { fontSize: 30 } }}>
            {t('reviews')}
          </Txt>
          <Txt
            b2
            css={{
              marginBottom: 40,
              color: '$subTextGray',
              '@tb': { fontSize: 17 },
              '@mb': {
                width: '100%',
                marginBottom: 30,
              },
            }}
          >
            {t('reviews_sub')}
          </Txt>
        </Div>
        <ReviewSliderContainer width={width} />
      </ScrollAlphaComponent>
    </Div>
  );
};
