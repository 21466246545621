import { Row } from '../../../styles/Layout/Row';
import { AnimatedDiv } from '../../../styles/Layout/AnimatedDiv';
import { Txt } from '../../StyleGuide/Components/Txt';
import { styled } from '../../../styles/stitches.config';
import { useGesture } from '@use-gesture/react';
import { useState } from 'react';
import { useSpring } from '@react-spring/web';

export const CheckBox = ({ label, check, setCheck, disable }) => {
  const [hover, setHover] = useState(false);
  const sp = useSpring({
    stroke: getStrokeColor(),
    check: getCheckStrokeColor(),
    bg: check ? '#F7941D' : '#FFFFFF',
  });

  const bind = useGesture({
    onHover: () => {
      setHover(true);
    },
    onHoverEnd: () => {
      setHover(false);
    },
    onClick: () => {
      if (disable === true) return;
      setCheck(!check);
    },
  });

  function getStrokeColor() {
    if (disable) return '#B0B0B9';
    if (check === true) return '#F7941D';
    if (hover) return '#F7941D';
    return '#B0B0B9';
  }

  function getCheckStrokeColor() {
    if (disable) return '#B0B0B9';
    if (check === true) return '#FFFFFF';
    if (hover) return '#F7941D';
    return '#B0B0B9';
  }

  return (
    <Row
      css={{
        flexWrap: 'nowrap',
        opacity: disable ? 0.2 : 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: disable ? 'not-allowed' : 'pointer',
      }}
      {...bind()}
    >
      <CBox
        css={{
          border: `1px solid`,
        }}
        style={{
          borderColor: sp.stroke.to((o) => o),
          background: sp.bg.to((o) => o),
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
        >
          <polyline
            points="5 12 11 18 20 9"
            stroke={getCheckStrokeColor()}
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
          />
        </svg>
      </CBox>
      {label && (
        <Txt
          b4
          css={{
            textAlign: 'left',
            marginLeft: 10,
          }}
        >
          {label}
        </Txt>
      )}
    </Row>
  );
};

const CBox = styled(AnimatedDiv, {
  width: 26,
  height: 26,
  borderRadius: 6,
  background: '$white',
});
