import React from 'react';
import { ModalContainer } from '../../Shared/Modals/ModalContainer';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Col } from '../../../styles/Layout/Col';
import { Img } from '../../StyleGuide/Components/Img';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Icon } from '../../Shared/Icon';
import { styled } from '../../../styles/stitches.config';

function ReviewInfoModal({
  width,
  text,
  icon,
  element,
  textArray,
  index,
  nameList,
  t,
}) {
  let filter = nameList.sort((item, i) => {});

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: 0,
    nextArrow: (
      <Icon
        src={'/images/icon-next.svg'}
        css={{
          cursor: 'pointer',
          right: -50,
          '&:hover': {
            filter: 'brightness(0.8)',
          },
          '&:active': {
            filter: 'brightness(0.6)',
          },
          '@fold': {
            position: 'absolute',
            right: 40,
          },
          '@mb': {
            position: 'absolute',
            right: 40,
          },
        }}
      />
    ),
    prevArrow: (
      <Icon
        src={'/images/icon-prev.svg'}
        css={{
          cursor: 'pointer',
          '&:hover': {
            filter: 'brightness(0.8)',
          },
          '&:active': {
            filter: 'brightness(0.6)',
          },
          '@fold': {
            position: 'absolute',
            left: 40,
          },
          '@mb': {
            position: 'absolute',
            left: 40,
          },
        }}
      />
    ),
  };

  return (
    <ModalContainer
      css={{
        maxWidth: width,
        height: 'auto',
        width: '100%',
        padding: 30,
        background: 'rgba(255, 255, 255, 0.2)',
        borderRadius: 10,
        '@mb': { width: '90%' },
      }}
    >
      <Col
        css={{
          gap: 30,
          alignItems: 'center',
          '@mb': { justifyContent: 'center', width: '100%', height: 'auto' },
          '@fold': { gap: 15 },
        }}
      >
        <Img
          src={icon}
          css={{
            height: 100,
          }}
        />
        <Txt h5>{element}</Txt>
        <Txt
          b5
          css={{
            textAlign: 'left',
          }}
        >
          {text}
        </Txt>
      </Col>
    </ModalContainer>

    // <ModalContainer css={{
    //     border: 'none',
    //     maxWidth: width,
    //     height: height,
    //     width: '100%',
    //     background: 'none',
    //     overflow: 'visible',
    //     justifyContent: 'center',
    //     alignItems: "center",
    //     '@mb': {height: 'auto'}
    // }}>
    //     <ExSlider {...settings} style={{width: '100%'}}>
    //         {
    //             nameList.map((el,i) => {
    //                 return (
    //                     <ReviewCard css={{
    //                         height: height
    //                     }}>
    //                         <Img src={`/images/avatar-${el}.png`} css={{
    //                             width: 150,
    //                             height: 150
    //                         }}/>
    //                         <Txt css={{width:'100%'}} h5>{el}</Txt>
    //                         <Txt b5 css={{
    //                             width:'100%',
    //                             textAlign: 'left'
    //                         }}>{t(textArray[i])}</Txt>
    //                     </ReviewCard>
    //                 )
    //             })
    //         }
    //     </ExSlider>
    // </ModalContainer>
  );
}

const ReviewCard = styled(Col, {
  width: '100%',
  border: '1px solid $BoxLine',
  padding: 30,
  borderRadius: 10,
  gap: 30,
  alignItems: 'center',
});

const ExSlider = styled(Slider, {
  '& .slick-list': {
    width: '100%',
    margin: '0 auto',
  },
  '& .slick-slide div': {
    margin: '0 auto',
    cursor: 'pointer',
  },
  '& .slick-prev::before': {
    opacity: 0,
    display: 'none',
  },
  '& .slick-next::before': {
    opacity: 0,
    display: 'none',
  },
});

export default ReviewInfoModal;
