import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { changeServerLang, fileURL } from '../api/base/apiCore';

const version = 'v1.2.5';

export function initI18n() {
  const langList = [
    'KR',
    'EN',
    'JA',
    'ZH',
    'AR',
    'FR',
    'DE',
    'IT',
    'RU',
    'ES',
    'TR',
    'VI',
    'ID',
    'TH',
    'HI',
    'PT',
    'MS',
    'NL',
    'PL',
  ];
  let lang;
  if (localStorage.getItem('lang')) {
    lang = localStorage.getItem('lang');
    if (!lang) localStorage.removeItem('lang');
  } else {
    lang = (navigator.language || navigator.userLanguage).split('-')[0];
    if (lang === 'ko') lang = 'kr';
  }
  const userLang =
    langList
      .find((l) => l?.toLowerCase() === lang?.toLowerCase())
      ?.toLowerCase() || 'en';

  i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      lng: userLang,
      fallbackLng: userLang,
      ns: [
        'auth',
        'common',
        'main',
        'modal',
        'offer',
        'vendor',
        'wallet',
        'mypage',
        'afilliate',
        'card',
        'help',
        'paymentprogram',
      ],
      debug: true,
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${fileURL}/locales/{{lng}}/{{ns}}_${version}.json`,
      },
    })
    .then((r) => {
      console.log('i18n initialized');
    });
}

export function changeLanguage(lang) {
  console.log('changeLanguage', lang);
  localStorage.setItem('lang', lang);
  document.documentElement.lang = lang;
  i18n.changeLanguage(lang).then((r) => {
    changeServerLang(lang);
  });
}
