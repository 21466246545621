import React from 'react';
import { Col } from '../../../styles/Layout/Col';
import { Div } from '../../../styles/Layout/Div';
import { Txt } from '../../StyleGuide/Components/Txt';
import { styled } from '../../../styles/stitches.config';
import { useSpring, animated } from '@react-spring/web';
import { useTranslation } from 'react-i18next';

function PasswordCheckBar({ validateLevel }) {
  const { t } = useTranslation('auth');

  const widths = [20, 50, 100];
  const colors = ['#EF484C', '#F7941D', '#71A1FF'];

  const sp = useSpring({
    width: getSpringWidth(),
    backgroundColor: getSpringColor(),
  });

  function getSpringWidth() {
    if (validateLevel === 0) {
      return '0%';
    } else {
      return `${widths[validateLevel - 1]}%`;
    }
  }

  function getSpringColor() {
    if (validateLevel === 0) {
      return '#FFFFFF';
    } else {
      return colors[validateLevel - 1];
    }
  }

  function getValidateMsg() {
    switch (validateLevel) {
      case 0:
        break;
      case 1:
        return (
          <LightGrayText b6>
            Must contain at least<LightRedTxt e3> 6 characters</LightRedTxt> ,{' '}
            <LightRedTxt e3>1 uppercase letter</LightRedTxt>,{' '}
            <LightRedTxt e3>1 lowercase letter</LightRedTxt>,{' '}
            <LightRedTxt e3>1 special character </LightRedTxt>, and{' '}
            <LightRedTxt e3>1 number.</LightRedTxt>
          </LightGrayText>
          // <FormatTxt t={t} i18nKey={'password_checkbar'} b6>
          //     Must contain at least<LightRedTxt e3>{{characters: '6 characters'}}</LightRedTxt> , <LightRedTxt
          //         e3>{{uppercase: '1 uppercase letter'}}</LightRedTxt>, <LightRedTxt e3>{{lowercase: '1 lowercase letter'}}</LightRedTxt>,
          //     <LightRedTxt e3>{{special:'1 special character'}}</LightRedTxt>, and <LightRedTxt e3>{{oneNum:'1 number.'}}</LightRedTxt>
          //
          //     {/*Must contain at least <1>{{characters}}</1> , <2>{{uppercase}}</2>, <3>{{lowercase}}</3>, <4>{{special}}</4> , and <5>{{oneNum}}</5>/!**!/*/}
          // </FormatTxt>
        );
      case 2:
        return (
          <LightGrayText b6>
            Must contain at least<LightBlueTxt e3> 6 characters</LightBlueTxt> ,{' '}
            <LightBlueTxt e3>1 uppercase letter</LightBlueTxt>,{' '}
            <LightBlueTxt e3>1 lowercase letter</LightBlueTxt>,{' '}
            <LightRedTxt e3>1 special character </LightRedTxt>, and{' '}
            <LightRedTxt e3>1 number.</LightRedTxt>
          </LightGrayText>
        );
      case 3:
        return (
          <LightGrayText b6>
            Must contain at least<LightBlueTxt e3> 6 characters</LightBlueTxt> ,{' '}
            <LightBlueTxt e3>1 uppercase letter</LightBlueTxt>,{' '}
            <LightBlueTxt e3>1 lowercase letter</LightBlueTxt>,{' '}
            <LightBlueTxt e3>1 special character </LightBlueTxt>, and{' '}
            <LightBlueTxt e3>1 number.</LightBlueTxt>
          </LightGrayText>
        );
      default:
    }
  }

  return (
    <Col
      css={{
        maxWidth: 445,
        width: '100%',
        alignItems: 'flex-start',
        '@mb': { maxWidth: '100%' },
      }}
    >
      {validateLevel !== 0 && (
        <React.Fragment>
          <PasswordCheckBarContainer>
            <ProgressBar
              style={{
                ...sp,
              }}
            />
          </PasswordCheckBarContainer>
          {getValidateMsg()}
        </React.Fragment>
      )}
    </Col>
  );
}

const LightBlueTxt = styled(Txt, {
  color: '$lightBlue',
});

const LightRedTxt = styled(Txt, {
  color: '$lightRed',
});

const LightGrayText = styled(Txt, {
  color: '$lightGray',
  width: '100%',
  textAlign: 'left',
});

const ProgressBar = styled(animated.div, {
  height: 6,
  borderRadius: 2,
  width: '50%',
  backgroundColor: '$primary',
});

const PasswordCheckBarContainer = styled(Div, {
  // width: '100%',
  // height: 6,
  // borderRadius: 2,
  // backgroundColor: '$BoxLine',
  marginBottom: 10,
  marginTop: 10,
  maxWidth: 445,
  width: '100%',
  height: 6,
  background: '$BoxLine',
  borderRadius: 5,
  // opacity: '20%',
  '@tb': {
    maxWidth: '100%',
  },
  '@mb': {
    maxWidth: '100%',
  },
});

export default PasswordCheckBar;
