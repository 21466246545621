import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import RootRouter from './RootRouter';
import ThemeProvider from './ThemeProvider';
import ModalManager from './ModalManager';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { initI18n } from '../../locale/locale';
import React from 'react';

 console.log = ()=>{};
 console.dir = ()=>{};
initI18n();

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider>
          <ModalManager />
          <BrowserRouter basename="/">
            <RootRouter />
          </BrowserRouter>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
