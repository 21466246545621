import React, { useState } from 'react';
import { Div } from '../../../../styles/Layout/Div';
import { Icon } from '../../../Shared/Icon';
import { AnimatedDiv } from '../../../../styles/Layout/AnimatedDiv';
import NotificTabContainer from '../../../Shared/NotificationContainorModalTap/NotificTabContainer';
import { useGesture } from '@use-gesture/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function NotiButton(props) {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const noReadNoti = useSelector((state) => state.noti.noReadNoti);

  const bind = useGesture({
    onHover: () => {
      setVisible(true);
    },
    onHoverEnd: () => {
      setVisible(false);
    },
  });

  return (
    <Div {...bind()}>
      <Div
        css={{
          width: 45,
          height: 45,
          borderRadius: 10,
          border: '1px solid $opacity',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            border: '1px solid $border',
          },
        }}
        onClick={() => {
          setVisible(!visible);
          navigate('/my/notification');
        }}
      >
        <Icon src={'/images/icon-bell.svg'} />
        {noReadNoti === true && (
          <Div
            css={{
              position: 'absolute',
              top: 12,
              right: 12,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="4" fill="#F7941D" />
            </svg>
          </Div>
        )}
      </Div>
      {visible && (
        <AnimatedDiv
          css={{
            position: 'absolute',
            top: 51,
            right: 0,
            width: 340,
            borderRadius: 6,
            background: '$bg',
            border: '1px solid $opacity',
            zIndex: 99,
          }}
        >
          <Div
            css={{
              position: 'absolute',
              top: -10,
              width: '100%',
              height: 10,
            }}
          />
          <NotificTabContainer navigate={navigate} setVisible={setVisible} />
        </AnimatedDiv>
      )}
    </Div>
  );
}

export default NotiButton;
