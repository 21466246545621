import React, { useState } from 'react';
import { Col } from '../../../styles/Layout/Col';
import { Row } from '../../../styles/Layout/Row';
import { Txt } from '../../StyleGuide/Components/Txt';
import { getTimeText } from '../../../util';

function NotiContainerItem({ msg, clickMessage }) {
  return (
    <Col
      onClick={() => clickMessage(msg)}
      css={{
        background: '$hoverBg',
        borderRadius: 10,
        cursor: 'pointer',
        padding: '20px',
        width: '100%',
        '&:active': {
          filter: 'brightness(0.8)',
        },
        '@mb': { flexDirection: 'column', gap: 5 },
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.4);',
        },
      }}
    >
      <Row
        css={{
          display: 'flex',
          flex: 1,
          minWidth: '120px',
          maxWidth: '100%',
          '@mb': { width: '100%' },
        }}
      >
        <Txt
          e4
          css={{
            textAlign: 'start',
            color: msg.isRead === false ? '$primary' : '$white',
          }}
        >
          {msg.message}
        </Txt>
      </Row>
      <Row css={{ display: 'flex', '@mb': { width: '100%' } }}>
        <Txt
          b7
          css={{
            textAlign: 'left',
            color: '$textPlaceholder',
          }}
        >
          {getTimeText(new Date(msg.createdAt).getTime())}
        </Txt>
      </Row>
    </Col>
  );
}

export default NotiContainerItem;
