import { styled } from '../../../styles/stitches.config';

export const Input = styled('input', {
  width: '100%',
  height: 45,
  borderRadius: 10,
  padding: '11px 15px',
  color: '$text',
  border: '1px solid $opacity',
  background: 'transparent',
  outline: 'none',
  '&:hover': {
    border: '1px solid $border',
    background: '$inputBg',
  },
  '&:focus': {
    border: '1px solid $border',
    background: '$inputBg',
  },
  '&[disabled]': {
    opacity: '20%',
  },

  fontFamily: 'GmarketSansTTFMedium',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',

  variants: {
    error: {
      true: {
        color: '$text',
        border: '1px solid $red;',
        '&:hover': {
          border: '1px solid $red',
        },
        '&:focus': {
          border: '1px solid $red',
        },
        '&[disabled]': {
          opacity: '20%',
        },
        '&::placeholder': {
          color: '#text',
        },
      },
    },
    lg: {
      true: {
        height: 45,
        width: 810,
        padding: '11px 45px',
        '&:hover': {
          background: '$inputBg',
          border: '1px solid $border;',
        },
        '&:focus': {
          border: '1px solid $border;',
          background: '$inputBg',
        },
        '&[disabled]': {
          opacity: '20%',
        },
      },
    },
  },
});
