import { Row } from '../../../styles/Layout/Row';
import { Col } from '../../../styles/Layout/Col';
import { Input } from '../../StyleGuide/Components/Input';
import { Icon } from '../Icon';
import React, { useEffect, useState } from 'react';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useSpring } from '@react-spring/web';

const InputPasswordBox = ({
  error,
  width,
  marginTop,
  placeholder = '',
  password,
  setPassword,
  autoComplete = 'off',
  pressEnter = () => {},
}) => {
  const [showPswd, setShowPswd] = useState(false);
  const [errorState, setErrorState] = useState(false);

  const sp = useSpring({});

  const onChangeHandler = (e) => {
    const { value } = e.target;
    //함수 setPassword
    setPassword(value);
  };

  useEffect(() => {
    if (error?.length > 0) {
      setErrorState(true);
    } else {
      setErrorState(false);
    }
  }, [error]);

  return (
    <Col css={{ width: '100%' }}>
      <Row css={{ alignItems: 'center', width: '100%' }}>
        <Input
          // onBlur={}
          css={{
            width: '100%',
            maxWidth: width,
            marginTop: marginTop,
            '@mb': {
              maxWidth: '100%',
            },
          }}
          error={errorState}
          type={showPswd ? 'text' : 'password'}
          onChange={onChangeHandler}
          value={password}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              pressEnter();
            }
          }}
        />
        {showPswd ? (
          <Icon
            onClick={() => setShowPswd(!showPswd)}
            css={{ position: 'absolute', right: 14 }}
            src={'/images/icon-eye-on.svg'}
          />
        ) : (
          <Icon
            onClick={() => setShowPswd(!showPswd)}
            css={{ position: 'absolute', right: 14 }}
            src={'/images/icon-eye-off.svg'}
          />
        )}
      </Row>
      {errorState === true && (
        <Txt
          b6
          css={{
            color: '$lightRed',
            margin: 10,
          }}
        >
          {error}
        </Txt>
      )}
    </Col>
  );
};

export default InputPasswordBox;
