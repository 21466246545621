import { Div } from '../../../styles/Layout/Div';
import { Img } from '../../StyleGuide/Components/Img';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { onModal } from '../../../redux/actions/modalAction';
import { useDispatch } from 'react-redux';
import ReviewInfoModal from './ReviewInfoModal';

export const ReviewCard = ({ icon, element, index, nameList }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('main');
  const textArray = [
    'Mateo_said',
    'Musa_said',
    'Nare_said',
    'Wing_said',
    'Aom_said',
    'Antonio_said',
  ];
  const text = t(`${textArray[index]}`);
  const slicedText = t(`${textArray[index]}`).slice(0, 70) + '...';

  return (
    <Div
      onClick={() => {
        dispatch({
          type: onModal,
          payload: (
            <ReviewInfoModal
              t={t}
              nameList={nameList}
              index={index}
              textArray={textArray}
              width={450}
              icon={icon}
              element={element}
              text={text}
            />
          ),
        });
      }}
      css={{
        width: '300px',
        height: '280px',
        border: '1px solid $opacity',
        borderRadius: 10,
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: 'auto',
        paddingTop: 45,
        '@mb': {
          width: 244,
        },
        '@tb': {
          minWidth: 150,
          maxWidth: 300,
          width: '100%',
        },
        '@fold': {
          width: 200,
        },
        '&:hover': {
          background: '$BoxHover',
        },
      }}
    >
      <Img
        src={icon}
        css={{
          height: 100,
          marginTop: -30,
        }}
      />
      <Txt
        h5
        css={{
          marginTop: 27,
          marginBottom: 10,
        }}
      >
        {element}
      </Txt>
      <Txt
        b5
        css={{
          width: '200px',
          marginBottom: 5,
        }}
      >
        {slicedText}
      </Txt>
    </Div>
  );
};
