import { Outlet } from 'react-router-dom';
import ToastManager from './ToastManager';
import React from 'react';

export const ToastProvider = () => {
  return (
    <React.Fragment>
      <ToastManager />
      <Outlet />
    </React.Fragment>
  );
};
