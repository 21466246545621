import { Col } from '../../../styles/Layout/Col';
import { Input } from '../../StyleGuide/Components/Input';
import { Icon } from '../Icon';
import { Button } from '../../StyleGuide/Components/Button';
import { Span } from '../../../styles/Layout/Span';
import { Img } from '../../StyleGuide/Components/Img';
import React from 'react';
import { Row } from '../../../styles/Layout/Row';

const InputBoxLarge = ({
  showButton,
  width,
  css,
  onChange,
  text = 'search',
}) => {
  return (
    <Col css={{ width: '100%', alignItems: 'center' }}>
      <Row css={{ alignItems: 'center', height: 45, ...css }}>
        <Input onChange={onChange} lg css={{ ...css }} placeholder={text} />
        <Icon
          css={{ position: 'absolute', left: 16, cursor: 'pointer' }}
          src={'/images/icon-search-linear.svg'}
        />
        {showButton && (
          <Button css={{ position: 'absolute', right: 7 }}>
            <Span>Show All</Span>
            <Img src={'/images/icon-down-light.svg'}></Img>
          </Button>
        )}
      </Row>
    </Col>
  );
};

export default InputBoxLarge;
