import { useTranslation } from 'react-i18next';
import { Div, TableContainer } from '../../../styles/Layout/Div';
import { Icon } from '../../Shared/Icon';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Row } from '../../../styles/Layout/Row';
import { Col } from '../../../styles/Layout/Col';
import { Img } from '../../StyleGuide/Components/Img';

export const SellCardList = () => {
  return (
    <Row
      css={{
        gap: 30,
        justifyContent: 'center',
        alignItems: 'center',
        '@tb': {
          maxWidth: 900,
          padding: '0 16px',
        },
        '@mb': { gap: 16 },
      }}
    >
      <WayCard icon={'/images/icon-bank-transfer.svg'} title={'way01'} />
      <WayCard icon={'/images/icon-electronic-money.svg'} title={'way02'} />
      <WayCard icon={'/images/icon-bank-transfer.svg'} title={'way03'} />
      <WayCard icon={'/images/icon-cash-delivery.svg'} title={'way04'} />
      <WayCard icon={'/images/icon-gift-card.svg'} title={'way05'} />
    </Row>
    // <TableContainer css={{
    //     gridTemplateColumns: '1fr 1fr 1fr 1fr',
    //     gridTemplateRows: 'auto',
    //     gap: '0.25rem',
    //     alignItems: 'center',
    //     height: 'auto',
    //     borderRadius: 6,
    //     padding: 30,
    //     backgroundColor: '#fff',
    //     borderBottom: '1px solid $opacity',
    //     '@tb': {
    //         maxWidth: 900,
    //         padding: '0 16px',
    //     }
    // }}>
    //
    //     <Row css={{height: 70, padding: 10}}>
    //         <Img src={"/images/01. Alipay.png"} css={{height: 60, padding: 10}}/>
    //     </Row>
    //     <Img src={"/images/02. Coinbase.png"} css={{height: 70, padding: 10}}/>
    //     <Img src={"/images/03. Kraken.png"} css={{height: 70, padding: 10}}/>
    //     <Img src={"/images/04. OKX.png"} css={{height: 70, padding: 10}}/>
    //     <Img src={"/images/05. KUcoin.png"} css={{height: 70, padding: 10}}/>
    //     <Row css={{height: 70, padding: 10}}>
    //         <Img src={"/images/06. Apple Pay.png"} css={{height: 60, padding: 10}}/>
    //     </Row>
    //     <Img src={"/images/07. Gate.io.png"} css={{height: 70, padding: 10}}/>
    //     <Img src={"/images/08. CoinTR.png"} css={{height: 70, padding: 10}}/>
    //     <Img src={"/images/09. Bitstamp.png"} css={{height: 70, padding: 10}}/>
    //     <Img src={"/images/10. Coins.ph.png"} css={{height: 70, padding: 10}}/>
    //     <Img src={"/images/11. Bitfinex.png"} css={{height: 70, padding: 10}}/>
    //     <Img src={"/images/12. BitFlyer.png"} css={{height: 70, padding: 10}}/>
    // </TableContainer>
  );
};
export const BuyCardList = () => {
  return (
    <Row
      css={{
        gap: 30,
        justifyContent: 'center',
        alignItems: 'center',
        '@tb': {
          maxWidth: 900,
          padding: '0 16px',
        },
        '@mb': { gap: 16 },
      }}
    >
      <WayCard icon={'/images/icon-gift-card.svg'} title={'way05'} />
      <WayCard icon={'/images/icon-cash-delivery.svg'} title={'way04'} />
      <WayCard icon={'/images/icon-bank-transfer.svg'} title={'way03'} />
      <WayCard icon={'/images/icon-electronic-money.svg'} title={'way02'} />
      <WayCard icon={'/images/icon-bank-transfer.svg'} title={'way01'} />
    </Row>
  );
};

const WayCard = ({ icon, title }) => {
  const { t } = useTranslation('main');

  return (
    <Div
      css={{
        width: '255px',
        height: '290px',
        borderRadius: '6px',
        border: '1px solid $opacity',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        paddingTop: 30,
        paddingBottom: 30,
        '@mb': {
          width: '90%',
          height: '250px',
        },
      }}
    >
      <Col
        css={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icon src={icon} />
        <Txt
          h5
          css={{
            marginTop: 20,
            marginBottom: 10,
            '@tb': { fontSize: 16 },
          }}
        >
          {t(title)}
        </Txt>
        <Txt
          b5
          css={{
            textAlign: 'center',
            whiteSpace: 'pre-line',
            color: '$subTextGray',
            '@tb': { fontSize: 13 },
          }}
        >
          {t(`${title}_sub`)}
        </Txt>
      </Col>
    </Div>
  );
};
