import { createAction } from 'redux-actions';
import { getAllPaymentMethod } from '../../api/paymentMethodService';

export const updatePaymentMethod = () => {
  return async (dispatch, getState) => {
    try {
      const { loading } = getState().method;
      if (loading) {
        return; // If already loading, don't dispatch another request
      }
      dispatch({ type: paymentMethodUpdateStart });
      let res = await getAllPaymentMethod();
      dispatch({ type: paymentMethodUpdateComplete, payload: res });
    } catch (e) {
      console.log(e);
    }
  };
};

export const paymentMethodUpdateStart = createAction(
  'PAYMENT_METHOD_UPDATE_START',
);

export const paymentMethodUpdateComplete = createAction(
  'PAYMENT_METHOD_UPDATE_COMPLETE',
);
