import { styled } from '../stitches.config';

export const Section = styled('section', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  backgroundColor: '$bg',
});
