import React, { useEffect, useState } from 'react';
import { AnimatedDiv } from '../../../styles/Layout/AnimatedDiv';
import { useSpring, useTransition } from '@react-spring/web';

/**
 * 현재 스크롤 상태에 따라 스케일 변경
 * @param tchildren
 * @param scrollHeight
 * @returns {JSX.Element}
 * @constructor
 */
export const ScrollScaleComponent = ({ children, scrollHeight, css }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const props = useSpring({
    opacity: isScrolled ? 1 : 0,
    y: isScrolled ? 1 : 0,
  });

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrolled(currentScrollY >= scrollHeight);
    };

    handleScroll();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrolled(currentScrollY >= scrollHeight);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollHeight]);

  return (
    <AnimatedDiv
      css={{ ...css }}
      style={{
        alignItems: 'center',
        opacity: props.opacity.to((o) => o),
        transform: props.y.to((o) => `scale(${o})`),
      }}
    >
      {children}
    </AnimatedDiv>
  );
};
