import { Section } from '../../../styles/Layout/Section';
import { styled } from '../../../styles/stitches.config';
import { Row } from '../../../styles/Layout/Row';
import React, { useEffect, useState } from 'react';
import { Button } from '../../StyleGuide/Components/Button';
import { Excoinz } from '../../Shared/Excoinz';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Col } from '../../../styles/Layout/Col';
import { useTranslation } from 'react-i18next';
import { SocialGroup } from './SocialGroup';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '../../Shared/Icon';
import { AnimatedDiv } from '../../../styles/Layout/AnimatedDiv';
import { useSpring } from '@react-spring/web';
import { LangPickerMb } from '../../Shared/LangaugePicker/LangaugePicker.mb';
import useMeasure from 'react-use-measure';
import { ThemeToggle } from '../../Shared/ThemaToggle';

export const FooterMb = () => {
  const { t } = useTranslation('common');
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [ref, { width }] = useMeasure();

  const spring = useSpring({
    height: isLanguageOpen ? 320 : 0,
  });

  return (
    <FooterContainer>
      <Row
        css={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: 16,
          paddingTop: 25,
          '@fold': { width: '100%', justifyContent: 'center' },
        }}
        ref={ref}
      >
        <Col>
          <Excoinz css={{ position: 'static' }} />
        </Col>
        <ThemeToggle />
      </Row>
      <Row
        css={{
          justifyContent: 'flex-end',
          width: '100%',
          gap: 10,
          paddingRight: 16,
          marginBottom: 25,
          flexWrap: 'nowrap',
          '@mb': {
            padding: 16,
          },
          '@fold': { width: '100%', justifyContent: 'center' },
        }}
      >
        <Button
          onClick={() => {
            window.location.href = 'https://help.excoinz.com';
          }}
          bordered
          css={{
            height: 45,
          }}
        >
          {t('faq_helpcenter')}
        </Button>
        <LangPickerMb
          height={45}
          boxWidth={width - 5}
          isLanguageOpen={isLanguageOpen}
          setIsLanguageOpen={setIsLanguageOpen}
        />
      </Row>
      <AnimatedDiv
        style={{
          height: spring.height.to((val) => `${val}px`),
        }}
      />
      <FooterLabel label={t('legal')}>
        <FooterLink url={'/policy/aml'}>{t('policy_aml')}</FooterLink>
        <FooterLink url={'/policy/cookie'}>{t('policy_cookie')}</FooterLink>
        <FooterLink url={'/policy/privacy'}>{t('policy_privacy')}</FooterLink>
        <FooterLink url={'/policy/terms'}>{t('policy_terms')}</FooterLink>
        <FooterLink url={'/policy/vendor'}>{t('policy_vendor')}</FooterLink>
      </FooterLabel>
      <FooterLabel label={t('company')}>
        <FooterLink url={'/about'}>{t('about')}</FooterLink>
        <FooterLink url={'/about?contact=true'}>{t('contact')}</FooterLink>
        {/*<FooterLink url={"/media"}>{t('media')}</FooterLink>*/}
        <FooterLink url={'/afilliate'}>{t('afilliate')}</FooterLink>
      </FooterLabel>
      <FooterLabel label={t('tutorial')} last>
        <OutFooterLink url={'https://youtu.be/iHmH8k_OWCo'}>
          {t('youtube_1')}
        </OutFooterLink>
        <OutFooterLink url={'https://youtu.be/6bkIOtIINqI'}>
          {t('youtube_2')}
        </OutFooterLink>
        <OutFooterLink url={'https://youtu.be/UL15h7sY04k'}>
          {t('youtube_3')}
        </OutFooterLink>
        <OutFooterLink url={'https://youtu.be/djqEZ7TYDos'}>
          {t('youtube_4')}
        </OutFooterLink>
        <OutFooterLink url={'https://youtu.be/KDKZs16r0Rg?si=HwPSS80cNf6KUdwp'}>
          {t('youtube_5')}
        </OutFooterLink>
        <OutFooterLink url={'https://youtu.be/SQn61Wyh084?si=t3-Av6caPUAkBV6C'}>
          {t('youtube_6')}
        </OutFooterLink>
      </FooterLabel>
      <Row
        css={{
          marginTop: 38,
          justifyContent: 'space-between',
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <SocialGroup
          css={{
            width: '100%',
            justifyContent: 'center',
            marginBottom: 35,
          }}
        />
        <Txt
          b7
          css={{
            color: '$subText',
            width: '100%',
            marginBottom: 50,
          }}
        >
          {t('guide')}
        </Txt>
      </Row>
    </FooterContainer>
  );
};

const FooterContainer = styled(Section, {
  background: '$boxBg',
  width: '100%',
  minHeight: 520,
  borderTop: '1px solid $opacity',
});
const A = styled('a', {
  margin: 0,
  fontStyle: 'none',
  textDecoration: 'none',
  padding: '10px 0 0 0 ',
});
const FooterToSend = ({ children, url }) => {
  return (
    <A href={url} css={{}}>
      <Txt
        b3
        css={{
          width: '100%',
          alignItems: 'center',
          cursor: 'pointer',
          color: '$textPlaceholder',
          '&:hover': {
            color: '$primary',
          },
        }}
      >
        {children}
      </Txt>
    </A>
  );
};
const FooterLabel = ({ children, label, last }) => {
  const [open, setOpen] = useState(false);
  const sp = useSpring({
    rotation: open ? 180 : 0,
    height: open ? children.length * 40 : 0,
  });

  let location = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Col
      css={{
        width: '100%',
        borderBottom: last ? '1px solid $opacity' : 'none',
      }}
    >
      <Row
        css={{
          justifyContent: 'space-between',
          height: 55,
          width: '100%',
          alignItems: 'center',
          borderTop: '1px solid $opacity',

          paddingLeft: 16,
          paddingRight: 16,

          '&:active': {
            background: '$bg',
          },
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Txt h5>{label}</Txt>
        <AnimatedDiv
          style={{
            transform: sp.rotation.to((o) => `rotate(${o}deg)`),
          }}
        >
          <Icon src={'/images/icon-down.svg'} />
        </AnimatedDiv>
      </Row>
      {open && (
        <AnimatedDiv
          css={{
            paddingLeft: 16,
            paddingRight: 16,
            gap: 10,
            marginBottom: 25,
            width: '100%',
          }}
          style={{
            height: sp.height.to((o) => o),
          }}
        >
          {children}
        </AnimatedDiv>
      )}
    </Col>
  );
};

const FooterLink = ({ children, url }) => {
  const navigate = useNavigate();

  return (
    <Txt
      b3
      css={{
        paddingTop: 10,
        width: '100%',
        cursor: 'pointer',
        color: '$subText',
        '&:hover': {
          color: '$primary',
        },
      }}
      onClick={() => {
        if (url) {
          navigate(url);
        }
      }}
    >
      {children}
    </Txt>
  );
};
const OutFooterLink = ({ children, url }) => {
  return (
    <Txt
      b3
      css={{
        paddingTop: 10,
        width: '100%',
        cursor: 'pointer',
        color: '$subText',
        '&:hover': {
          color: '$primary',
        },
      }}
      onClick={() => {
        window.open(url, '_blank');
      }}
    >
      {children}
    </Txt>
  );
};
