import React from 'react';
import { Div } from '../../styles/Layout/Div';
import { AnimatedDiv } from '../../styles/Layout/AnimatedDiv';
import { ScrollScaleComponent } from './AnimateComponents/ScrollScaleComponent';
import { Icon } from './Icon';

function ScrollButton(props) {
  return (
    <AnimatedDiv
      css={{
        position: 'fixed',
        width: 50,
        height: 50,
        zIndex: 999,
        bottom: 30,
        right: 30,
        '@mb': {
          display: 'none',
        },
      }}
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
    >
      <ScrollScaleComponent scrollHeight={100}>
        <Div
          css={{
            width: 50,
            height: 50,
            borderRadius: 10,
            backgroundColor: '$bg',
            border: '1px solid $opacity',
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 0.3s ease',
            '&:hover': {
              border: '1px solid $white',
            },
            '&:active': {
              transform: 'scale(0.9)',
            },
          }}
        >
          <Icon src={'/images/icon-up.svg'} alt={'scroll up'} />
        </Div>
      </ScrollScaleComponent>
    </AnimatedDiv>
  );
}

export default ScrollButton;
