import { handleActions } from 'redux-actions';
import { createToast, endToast } from '../actions/toastAction';

export const defaultState = {
  msg: [],
};

export default handleActions(
  {
    [createToast](state, data) {
      return {
        msg: [
          ...state.msg,
          {
            id: Math.floor(Math.random() * 1000000000),
            ...data.payload,
          },
        ],
      };
    },
    [endToast](state, data) {
      let id = data.payload;
      return {
        msg: state.msg.filter((item) => item.id !== id),
      };
    },
  },
  defaultState,
);
