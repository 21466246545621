import React, { useEffect, useState } from 'react';
import { Div } from '../../../../styles/Layout/Div';
import { Icon } from '../../../Shared/Icon';

function NotiButtonMobile({ setVisibleNoti, visibleNoti, setVisibleUser }) {
  useEffect(() => {
    return () => setVisibleNoti(false);
  }, []);

  return (
    <Div>
      <Div
        css={{
          width: 45,
          height: 45,

          borderRadius: 10,
          border: '1px solid $opacity',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            border: '1px solid $border',
          },
        }}
        onClick={() => {
          setVisibleNoti(!visibleNoti);
          setVisibleUser(false);
        }}
      >
        <Icon src={'/images/icon-bell.svg'} />
      </Div>
    </Div>
  );
}

export default NotiButtonMobile;
