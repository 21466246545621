import { styled } from '../stitches.config';

export const Div = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: '$text',
  variants: {
    indicator: {
      true: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        cursor: 'pointer',
        border: '2px solid $border',
        opacity: 0.4,
        zIndex: 20,
        '&:hover': {
          border: 'none',
          background: '$primary',
          opacity: 1,
        },
        '@mb': {
          width: 16,
          height: 16,
        },
      },
    },
  },
});

export const TableContainer = styled('div', {
  display: 'grid',
  maxWidth: '100%',
  width: '100%',
  height: 60,
});
