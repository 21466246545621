import { Page } from '../../styles/Layout/Page';
import { Col } from '../../styles/Layout/Col';
import { Row } from '../../styles/Layout/Row';
import { Img } from '../StyleGuide/Components/Img';
import { LangPickerDt } from '../Shared/LangaugePicker/LangaugePicker.dt';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../StyleGuide/Components/Button';
import { Txt } from '../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import { Div } from '../../styles/Layout/Div';
import LottieAnim from '../Shared/LottieAnim';
import { useMediaQuery } from '../Shared/useMediaQuery';

export const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const isDesktop = useMediaQuery('dt');

  return (
    <Page css={{ minHeight: '100vh', width: '100%' }}>
      <Col
        css={{
          maxWidth: 1110,
          width: '100%',
          marginTop: 80,
          alignItems: 'center',
          gap: 100,
          '@tb': {
            justifyContent: 'center',
            alignItems: 'center',
            width: '90%',
          },
          '@mb': {
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 16,
            width: '90%',
            gap: 80,
          },
        }}
      >
        <Row
          css={{
            width: 'inherit',
            justifyContent: 'space-between',
            alignItems: 'center',
            '@tb': {},
            '@mb': { width: '100%' },
          }}
        >
          <Row
            onClick={() => navigate('/')}
            css={{ justifyContent: 'center', alignItems: 'center', gap: 10 }}
          >
            <Img
              css={{ cursor: 'pointer' }}
              src={'/images/excoinz-symbol.svg'}
            />
            <Img
              css={{ cursor: 'pointer' }}
              src={'/images/excoinz-logotype.svg'}
            />
          </Row>
          <LangPickerDt height={45} />
        </Row>

        <Row
          css={{
            width: 'inherit',
            gap: 30,
            justifyContent: 'space-between',
            alignItems: 'center',
            '@tb': { justifyContent: 'center' },
            '@mb': { justifyContent: 'center' },
          }}
        >
          <Col css={{ maxWidth: 445 }}>
            <Txt
              css={{
                textAlign: 'left',
                whiteSpace: 'pre-line',
                '@mb': { fontSize: 22 },
              }}
              h3
            >
              {t('notfound_txt')}
            </Txt>
            <Txt
              css={{
                textAlign: 'left',
                whiteSpace: 'pre-line',
                color: '$lightGray',
                marginTop: 50,
              }}
              b3
            >
              {t('notfound_txt2')}
            </Txt>
            <Button
              lg
              onClick={() => navigate('/')}
              css={{
                minWidth: 445,
                marginTop: 30,
                '@mb': { minWidth: 328 },
              }}
            >
              {t('notfound_home')}
            </Button>
          </Col>
          <LottieAnim
            src={'/lottie/error-hero.json'}
            width={isDesktop ? 635 : 450}
          />
          {/*<Img css={{maxWidth: 635, width: '90%'}} src={'/images/error-hero.png'}/>*/}
        </Row>
      </Col>
    </Page>
  );
};
