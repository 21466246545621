import React, { useEffect, useState } from 'react';
import { Row } from '../../../../styles/Layout/Row';
import { Txt } from '../../../StyleGuide/Components/Txt';
import { AnimatedDiv } from '../../../../styles/Layout/AnimatedDiv';
import { useGesture } from '@use-gesture/react';
import { useDispatch, useSelector } from 'react-redux';
import { Img } from '../../../StyleGuide/Components/Img';
import {
  updateAssetInfo,
  updateBalance,
} from '../../../../redux/actions/assetAction';
import Price from '../../../Shared/Price';
import { Col } from '../../../../styles/Layout/Col';
import { useNavigate } from 'react-router-dom';

function AssetView(props) {
  const [visible, setVisible] = useState(false);
  const [hover, setHover] = useState(false);
  const { balance, list: assetList } = useSelector((state) => state?.asset);
  const [totalUsd, setTotalUsd] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currency = useSelector((state) => state?.asset?.currency);
  const isLogin = useSelector((state) => state.user.isLogin);

  useEffect(() => {
    if (isLogin === false) {
      return;
    }

    dispatch(updateBalance());
    dispatch(updateAssetInfo());
  }, [isLogin]);

  useEffect(() => {
    console.log('calc total start');
    let total = 0;
    if (!!balance === false || !!assetList === false) {
      return;
    }

    let assetsKey = Object.keys(assetList);
    console.log('assetsKey', assetsKey.length);

    balance?.map((item) => {
      let amount = item?.balance ?? 0;

      let key = assetsKey.find((key) => {
        return assetList[key]?.assetId === item?.assetId;
      });
      let price = assetList[key]?.price ?? 0;
      total += amount * price;
    });
    setTotalUsd(total);
  }, [balance, assetList, visible]);

  const bind = useGesture({
    onHover: () => {
      setHover(true);
    },
    onHoverEnd: () => {
      setHover(false);
    },
  });

  return (
    <React.Fragment>
      <Row
        css={{
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 5,
        }}
        onClick={() => {
          setHover(!hover);
        }}
        {...bind()}
      >
        <Txt
          d5
          css={{
            color: '$primary',
            letterSpacing: '1.6px',
          }}
          onClick={() => {
            navigate('/wallet');
          }}
        >
          {visible === true
            ? (totalUsd / currency?.price)?.toLocaleString() +
              ` ${currency?.symbol}`
            : '******'}
        </Txt>
        <div
          style={{
            marginTop: visible ? 3 : 0,
          }}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          {visible ? (
            <Img
              css={{ width: 16, height: 16 }}
              src={'/images/icon-eye-on-orange.svg'}
            />
          ) : (
            <Img
              css={{ width: 16, height: 16 }}
              src={'/images/icon-eye-off-orange.svg'}
            />
          )}
        </div>
      </Row>
      {hover && visible && (
        <AnimatedDiv
          css={{
            position: 'absolute',
            top: 50,
            right: 0,
            width: 260,
            minHeight: 100,
            borderRadius: 6,
            background: '$bg',
            border: '1px solid $opacity',
            paddingTop: 5,
            paddingBottom: 5,
            zIndex: 90,
            '@mb': {
              right: -50,
            },
          }}
        >
          {balance &&
            balance?.map((item, index) => {
              return (
                <AssetRow
                  asset={assetList[item?.assetId]}
                  balance={item?.balance}
                  key={`navAssetRow${index}`}
                />
              );
            })}
        </AnimatedDiv>
      )}
    </React.Fragment>
  );
}

const AssetRow = ({ asset, balance }) => {
  balance = Number(balance)?.toFixed(6).toLocaleString();
  let newBalance = Number(balance?.split('.')[0]).toLocaleString();
  newBalance +=
    balance?.split('.')[1].split('0')[0] &&
    '.' + balance?.split('.')[1].split('0')[0];
  return (
    <Row
      css={{
        width: '100%',
        height: 50,
        padding: 10,
        paddingLeft: 15,
        paddingRight: 15,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Row
        css={{
          alignItems: 'center',
          gap: 10,
        }}
      >
        <Img src={`/images/assets/${asset?.name}.svg`} width={24} height={24} />
        <Txt
          b7
          css={{
            color: '$subText',
          }}
        >
          {asset?.name}
        </Txt>
      </Row>
      <Col
        css={{
          alignItems: 'flex-end',
        }}
      >
        <Txt
          css={{
            textAlign: 'left',
            flex: 1,
          }}
          e3
        >
          {newBalance} {asset?.symbol}
        </Txt>
        <Price
          css={{
            color: '$subText',
            textAlign: 'right',
            flex: 1,
          }}
          asset={asset}
          amount={balance}
          mark={true}
          symbol
          b7
        />
      </Col>
    </Row>
  );
};

export default AssetView;
