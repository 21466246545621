import React from 'react';
import { Txt } from '../StyleGuide/Components/Txt';
import { convertToCurrency, toLocaleString } from '../../util';
import { useSelector } from 'react-redux';

function Price({
  amount,
  asset = {
    price: 1,
    symbol: 'USD',
    unit: '$',
  },
  mark = false,
  color = '$navSubText',
  css,
  unit,
  symbol,
  currencyAsset,
  ...otherProps
}) {
  let currency = useSelector((state) => state.asset.currency);
  if (!!currencyAsset === true) {
    currency = currencyAsset;
  }
  let value = convertToCurrency(amount, asset?.price, currency);

  return (
    <Txt css={{ whiteSpace: 'nowrap', color: color, ...css }} {...otherProps}>
      {`${mark ? '≈' : ''} ${toLocaleString(value)} ${symbol ? currency?.symbol : ''} ${unit ? currency?.unit : ''}`}
    </Txt>
  );
}

export default Price;
