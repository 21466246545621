import React from 'react';
import { Img } from '../../StyleGuide/Components/Img';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Div } from '../../../styles/Layout/Div';
import { Col } from '../../../styles/Layout/Col';
import { Button } from '../../StyleGuide/Components/Button';
import { Row } from '../../../styles/Layout/Row';
import { Icon } from '../../Shared/Icon';
import { usePaymentMethod } from '../../Shared/usePaymentMethod';
import OfferPriceComponent from './OfferPriceComponent';
import { useSelector } from 'react-redux';
import LottieIcon from '../../Shared/LottieIcon';
import goldVendor from '../../../lottie/icon-grade-gold.json';
import diamondVendor from '../../../lottie/icon-grade-diamond.json';

function MainOfferCard({ item, salesType, onClick }) {
  const assets = useSelector((state) => state.asset.list);
  const asset = assets[item?.assetId];
  const payment = usePaymentMethod(item?.methodId);

  const upperCasedSalesType = salesType.toUpperCase();
  const upperCasedName = asset?.name.toUpperCase();

  function textLengthHandle(text) {
    return text?.length > 12 ? text.slice(0, 12) + '...' : text;
  }
  return (
    <Div
      onClick={onClick}
      css={{
        background: '$bg',
        width: '255px',
        border: '1px solid $opacity',
        borderRadius: 10,
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: 'auto',
        '@mb': {
          // width: '80%',
        },
        '@tb': {
          minWidth: 150,
          maxWidth: 300,
          width: '255px',
        },
        '@fold': {
          width: '90%',
        },
        '&:hover': {
          filter: 'brightness(0.8)',
        },
        '&:active': {
          filter: 'brightness(0.8)',
        },
      }}
    >
      <Col
        css={{
          width: '100%',
          padding: 20,
          alignItems: 'center',
          '@mb': { padding: 20 },
        }}
      >
        <Row
          css={{
            gap: 20,
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            '@fold': {
              gap: 5,
            },
          }}
        >
          <Txt
            css={{
              '@fold': {
                fontSize: 10,
              },
            }}
            h6
          >
            {asset?.symbol}
          </Txt>
          <Icon
            css={{
              width: 24,
              height: 24,
              '@fold': {
                width: 16,
                height: 16,
              },
            }}
            src={'/images/icon-repeat.svg'}
          />
          <Txt
            css={{
              minWidth: '100px',
              fontSize: 12,
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              flex: 1,
              '@fold': {
                fontSize: 10,
                minWidth: '50px',
              },
            }}
            h6
          >
            {textLengthHandle(payment?.name)}
          </Txt>
        </Row>
        <Div css={{ width: '100%', alignItems: 'center', paddingTop: 30 }}>
          <Img
            css={{ width: 65, height: 65, borderRadius: 30 }}
            src={item?.icon}
          />
        </Div>
        <Row
          css={{
            alignItems: 'center',
            gap: 5,
            cursor: 'pointer',
            paddingTop: 20,
            paddingBottom: 6,
          }}
        >
          <Txt css={{ color: '$primary' }} e1>
            {item?.name}
          </Txt>
          <Img src={`/images/flags/${item?.countryLangCode}.svg`} />
          {item?.vendorLevel === 1 && <LottieIcon animationData={goldVendor} />}
          {item?.vendorLevel === 2 && (
            <LottieIcon animationData={diamondVendor} />
          )}
        </Row>
        {/*<SeenTimeItem lastAccess={new Date(item?.updateAt).getTime()}/>*/}
        <Div
          css={{
            padding: '30px 0',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <OfferPriceComponent item={item} asset={asset} type={salesType} />
        </Div>
      </Col>
      <Col css={{ width: '100%' }}>
        <Button
          css={{
            height: 45,
            width: '100%',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          }}
        >
          {upperCasedSalesType + ' ' + upperCasedName}
        </Button>
      </Col>
    </Div>
  );
}

export default MainOfferCard;
