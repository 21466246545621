import { createStitches } from '@stitches/react';
const darkThemeConfig = require('./config/dark.json');
const lightThemeConfig = require('./config/light.json');

export const mediaConfig = {
  fold: '(max-width: 280px)',
  mb: '(max-width: 640px)',
  tb: '(min-width: 641px) and (max-width: 1260px)',
  dt: '(min-width: 1260px)',
};

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  media: mediaConfig,
});

export const globalStyles = globalCss({
  '*': { margin: 0, padding: 0, boxSizing: 'border-box' },
  body: { backgroundColor: '#fff' },
});

export const loadingRotate = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

export const darkTheme = createTheme('dark-theme', darkThemeConfig);
export const lightTheme = createTheme('light-theme', lightThemeConfig);
