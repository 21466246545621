import React from 'react';
import { Row } from '../../../styles/Layout/Row';
import { Txt } from '../../StyleGuide/Components/Txt';

function CountBadge({ color = '$primary', txtColor = '$bg', count, css }) {
  if (count === 0) return null;

  return (
    <Row
      css={{
        backgroundColor: color,
        paddingLeft: 5,
        paddingRight: 5,
        gap: 10,
        borderRadius: 9,
        ...css,
      }}
    >
      <Txt
        b7
        css={{
          color: txtColor,
        }}
      >
        {count}
      </Txt>
    </Row>
  );
}

export default CountBadge;
