import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReviewCard } from './ReviewCard';
import { useMediaQuery } from '../../Shared/useMediaQuery';
import { Row } from '../../../styles/Layout/Row';
import { Icon } from '../../Shared/Icon';
import { styled } from '../../../styles/stitches.config';

function ReviewSliderContainer({ width }) {
  const isDesktop = useMediaQuery('dt');
  const isTablet = useMediaQuery('tb');
  const nameList = ['Mateo', 'Musa', 'Nare', 'Wing', 'Aom', 'Antonio'];

  function tbCardCtn() {
    if (width > 920) return 3;
    else return 2;
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isDesktop ? 3 : isTablet ? tbCardCtn() : 1,
    autoplay: true,
    autoplaySpeed: 3500,
    centerMode: true,
    centerPadding: 0,
    nextArrow: (
      <Icon
        src={'/images/icon-next.svg'}
        css={{
          cursor: 'pointer',
          '&:hover': {
            filter: 'brightness(0.8)',
          },
          '&:active': {
            filter: 'brightness(0.6)',
          },
        }}
      />
    ),
    prevArrow: (
      <Icon
        src={'/images/icon-prev.svg'}
        css={{
          cursor: 'pointer',
          '&:hover': {
            filter: 'brightness(0.8)',
          },
          '&:active': {
            filter: 'brightness(0.6)',
          },
        }}
      />
    ),
  };

  return (
    <Row
      css={{
        maxWidth: '1000px',
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ExSlider {...settings} style={{ width: '100%' }}>
        {nameList.map((el, i) => {
          return (
            <ReviewCard
              nameList={nameList}
              key={i}
              index={i}
              element={el}
              alt={i}
              icon={`/images/avatar-${el}.png`}
            />
          );
        })}
      </ExSlider>
    </Row>
  );
}

const ExSlider = styled(Slider, {
  '& .slick-list': {
    width: '100%',
    margin: '0 auto',
  },
  '& .slick-list .slick-track': {
    display: 'flex',
    gap: 10,
  },
  '& .slick-slide div': {
    margin: '0 auto',
    cursor: 'pointer',
  },
  '& .slick-dots': {
    bottom: -65,
  },

  '& ul li button:before': {
    color: '#F7941D !important',
  },
  '& .slick-prev::before': {
    opacity: 0,
    display: 'none',
  },
  '& .slick-next::before': {
    opacity: 0,
    display: 'none',
  },
});

export default ReviewSliderContainer;
