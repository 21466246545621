import { request } from './base/apiCore';

export const getAssetFromId = async (id) => {
  console.log('getAssetFromId', id);
  return await request({
    method: 'GET',
    url: '/asset',
    params: {
      assetId: id,
    },
  });
};

export const getTransactionalAsset = async () => {
  return await request({
    method: 'GET',
    url: '/asset/transactional',
  });
};

export const getLegalAsset = async () => {
  return await request({
    method: 'GET',
    url: '/asset/legal',
  });
};

export const getAssetPrice = async (assetId) => {
  return await request({
    method: 'GET',
    url: '/asset/price',
    params: {
      assetId: assetId,
    },
  });
};
