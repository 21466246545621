import React from 'react';
import { Div } from '../../../styles/Layout/Div';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import { ScrollAlphaComponent } from '../../Shared/AnimateComponents/ScrollAlphaComponent';
import { Col } from '../../../styles/Layout/Col';
import { useMediaQuery } from '../../Shared/useMediaQuery';
import { Video } from '../../Shared/Video';
import MediaSliderContainer from './MediaSliderContainer';

export const MediaSection = () => {
  const { t } = useTranslation('main');
  const isMobile = useMediaQuery('mb');

  return (
    <Div
      css={{
        width: '100%',
        background: '$boxBg',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '@tb': { paddingTop: 100 },
        '@mb': {
          paddingTop: 60,
        },
      }}
    >
      <ScrollAlphaComponent
        scrollStart={isMobile ? 1900 : 890}
        scrollEnd={isMobile ? 2200 : 1400}
      >
        <Col
          css={{
            width: '90%',
            maxWidth: '1110px',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Txt h2 css={{ '@tb': { fontSize: 30 } }}>
            {t('media')}
          </Txt>
          <Txt
            b2
            css={{
              marginTop: 15,
              marginBottom: 40,
              color: '$subTextGray',
              '@tb': { fontSize: 17 },
              '@mb': {
                width: '90%',
              },
            }}
          >
            {t('media_sub')}
          </Txt>
          <Video
            controls
            css={{
              borderRadius: 5,
              maxWidth: '1110px',
              width: '90%',
              height: 'auto',
              zIndex: 10,
              '@mb': {
                width: '100%',
              },
            }}
          >
            <source src="/video/promotion-video.mp4" type="video/mp4" />
          </Video>
          <MediaSliderContainer />
        </Col>
        <Div
          css={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: 300,
            background: '$bg',
          }}
        />
      </ScrollAlphaComponent>
    </Div>
  );
};
