import React from 'react';
import { Div } from '../../../styles/Layout/Div';
import { ScrollScaleComponent } from '../../Shared/AnimateComponents/ScrollScaleComponent';
import { Txt } from '../../StyleGuide/Components/Txt';
import { OfferBox } from './OfferBox';
import { useTranslation } from 'react-i18next';
import useMeasure from 'react-use-measure';
import { useMediaQuery } from '../../Shared/useMediaQuery';

export const OfferSection = ({ tbPadding }) => {
  const { t } = useTranslation('main');
  const [ref, { width }] = useMeasure();

  return (
    <Div
      ref={ref}
      css={{
        width: '100%',
        paddingTop: 120,
        alignItems: 'center',
        zIndex: 10,
        '@mb': {
          padding: '50px 16px 26px 16px',
        },
        ...tbPadding,
      }}
    >
      <ScrollScaleComponent scrollHeight={0}>
        <Txt
          h1
          css={{
            zIndex: 1,
            '@tb': { fontSize: 35 },
            '@mb': { fontSize: 28 },
          }}
        >
          {t('catchprize')}
        </Txt>
        <Txt
          css={{
            color: '$subText',
            marginTop: 35,
            '@tb': { fontSize: 20 },
            '@mb': { fontSize: 16 },
          }}
          b1
        >
          {t('catchprize_sub')}
        </Txt>
      </ScrollScaleComponent>
      <ScrollScaleComponent
        css={{ width: '100%', '@mb': { width: '100%', paddingBottom: 190 } }}
        scrollHeight={0}
      >
        <OfferBox width={width} />
      </ScrollScaleComponent>
    </Div>
  );
};
