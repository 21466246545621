import { useSpring } from '@react-spring/web';
import { AnimatedDiv } from '../../../styles/Layout/AnimatedDiv';
import { Row } from '../../../styles/Layout/Row';
import { Txt } from '../../StyleGuide/Components/Txt';

export const Toggle = ({
  value,
  setValue,
  callback,
  size = 'm',
  leftLabel,
  rightLabel,
}) => {
  const sizeStyle = {
    s: {
      l_01: 15,
      l_02: 2,
      width: 30,
      height: 16,
      ball: 12,
      ballTop: 1.5,
    },
    m: {
      l_01: 25,
      l_02: 3,
      width: 50,
      height: 26,
      ball: 20,
      ballTop: 3,
    },
  };
  const style = sizeStyle[size];

  const sp = useSpring({
    left: value ? style['l_01'] : style['l_02'],
    color: value ? '#F7941D' : '#E0E0E7',
  });

  return (
    <Row
      css={{
        alignItems: 'center',
        gap: 10,
      }}
    >
      {leftLabel && (
        <Txt
          e2
          css={{
            color: value ? '$BoxLine' : '$text',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!!setValue === true) {
              setValue(false);
            }
          }}
        >
          {leftLabel}
        </Txt>
      )}
      <AnimatedDiv
        css={{
          background: '$primary',
          width: style['width'],
          height: style['height'],
          margin: 0,
          borderRadius: style['height'] * 0.5,
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (!!setValue === true) {
            setValue(!value);
          }
          if (!!callback === true) {
            callback();
          }
        }}
        style={{
          background: sp.color.to((o) => o),
        }}
      >
        <AnimatedDiv
          css={{
            position: 'absolute',
            background: '$white',
            width: style['ball'],
            height: style['ball'],
            borderRadius: style['ball'] * 0.5,
            top: style['ballTop'],
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25);',
          }}
          style={{
            left: sp.left.to((o) => o),
          }}
        />
      </AnimatedDiv>
      {rightLabel && (
        <Txt
          e2
          css={{
            color: value ? '$text' : '$BoxLine',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!!setValue === true) {
              setValue(true);
            }
          }}
        >
          {rightLabel}
        </Txt>
      )}
    </Row>
  );
};
