import { Icon } from './Icon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Excoinz = ({ css }) => {
  const navigate = useNavigate();

  return (
    <Icon
      src={'/images/excoinz-logo.svg'}
      css={{
        cursor: 'pointer',
        position: 'absolute',
        left: 20,
        ...css,
      }}
      onClick={() => {
        navigate('/');
      }}
    />
  );
};
