import { handleActions } from 'redux-actions';
import { offModal, onModal } from '../actions/modalAction';

export const defaultState = {
  modals: [],
  modalProps: [],
};

export default handleActions(
  {
    [onModal](state, data) {
      return {
        modals: [...state.modals, data.payload],
        modalProps: [...state.modalProps, data.payload.props],
      };
    },
    [offModal](state) {
      return {
        modals: state.modals.slice(0, state.modals.length - 1),
        modalProps: state.modalProps.slice(0, state.modalProps.length - 1),
      };
    },
  },
  defaultState,
);
