import React, { useEffect, useRef, useState } from 'react';
import { getOfferCardList } from '../../../api/offerService';
import { styled } from '../../../styles/stitches.config';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Icon } from '../../Shared/Icon';
import { useMediaQuery } from '../../Shared/useMediaQuery';
import MainOfferCard from './MainOfferCard';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from '../../Shared/LoadingComponent';
import { Col } from '../../../styles/Layout/Col';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useTranslation } from 'react-i18next';
import { Row } from '../../../styles/Layout/Row';

function OfferCardSlider({
  isToggle,
  keyAssetId,
  salesType,
  tagTitle,
  width,
  isLoading,
  setIsLoading,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [page, setPage] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [offerData, setOfferData] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const isDesktop = useMediaQuery('dt');
  const isTablet = useMediaQuery('tb');

  const ref = useRef();

  function tbCardCtn() {
    if (width > 900) return 3;
    else if (width > 535) return 2;
    // else if (width < 1078 && width > 735)
    //     return 2;
    else return 1;
  }

  function getSlideCount() {
    return isDesktop ? 4 : isTablet ? tbCardCtn() : 1;
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: getSlideCount(),
    autoplay: false,
    lazyLoad: 'anticipated',
    centerPadding: 0,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
      if (current !== next && next === offerData?.length - 4) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    nextArrow: (
      <Icon
        src={'/images/icon-nextbutton.svg'}
        css={{
          cursor: 'pointer',
          '&:hover': {
            filter: 'brightness(0.8)',
          },
          '&:active': {
            filter: 'brightness(0.6)',
          },
        }}
      />
    ),
    prevArrow: (
      <Icon
        src={'/images/icon-prevbutton.svg'}
        css={{
          cursor: 'pointer',
          '&:hover': {
            filter: 'brightness(0.8)',
          },
          '&:active': {
            filter: 'brightness(0.6)',
          },
        }}
        style={{
          right: '0',
        }}
      />
    ),
  };

  function onClickNavigate(el) {
    navigate(`/offer/detail/${el?.offerId}`);
  }

  useEffect(() => {
    setIsLoading1(true);
    if (isLoading1) return;
    getCardListData(true, 1).then((r) => {
      setIsLoading1(false);
    });
  }, [salesType]);

  useEffect(() => {
    setIsLoading1(true);
    if (!hasMore) return;
    if (isLoading1) return;
    getCardListData(false, page).then((r) => {
      setIsLoading1(false);
    });
  }, [page]);

  async function getCardListData(isClear, page) {
    let searchOption = {
      page,
      salesType,
    }; // page, salesType,keyAssetId
    if (!!keyAssetId === true) {
      searchOption['keyAssetId'] = keyAssetId;
    }

    let list = await getOfferCardList(searchOption);
    if (list?.length === 0) {
      setHasMore(false);
    }
    if (list === false) {
      list = [];
    }
    console.log('list');
    if (isClear) {
      setOfferData(list);
    } else {
      setOfferData([...offerData, ...list]);
    }
  }

  return (
    <Row
      css={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {offerData?.length <= getSlideCount() ? (
        <Row
          css={{
            gap: 20,
            margin: 20,
            flexWrap: 'nowrap',
          }}
        >
          {offerData?.map((el, i) => {
            return (
              <MainOfferCard
                onClick={() => onClickNavigate(el)}
                salesType={salesType}
                key={`${el}-${i}`}
                item={el}
              />
            );
          })}
        </Row>
      ) : (
        <StyleSlider {...settings} style={{ width: '100%' }} ref={ref}>
          {offerData?.length > 0 &&
            offerData?.map((el, i) => {
              return (
                <MainOfferCard
                  onClick={() => onClickNavigate(el)}
                  salesType={salesType}
                  key={`${el}-${i}`}
                  item={el}
                />
              );
            })}
        </StyleSlider>
      )}

      {isLoading1 && (
        <LoadingComponent css={{ top: 50, position: 'absolute' }} />
      )}
      {isLoading1 === false && offerData?.length === 0 && (
        <Col
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: 110,
            gap: 5,
            marginBottom: 50,
          }}
        >
          <Icon src={'/images/icon-search-result.svg'} />
          <Txt b4>{t('no_result')} </Txt>
        </Col>
      )}
    </Row>
  );
}

const StyleSlider = styled(Slider, {
  padding: '20px 1px',
  '& .slick-list': {
    width: '100%',
    // margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .slick-list .slick-track': {
    display: 'flex',
    flexShrink: 0,
    gap: 10,
    '@tb': {
      gap: 5,
    },
  },
  '& .slick-slide': {
    width: '100%',
  },
  '& .slick-slide div': {
    position: 'relative',
    margin: '0 auto',
    cursor: 'pointer',
  },
  '& .slick-prev::before': {
    opacity: 0,
    display: 'none',
  },
  '& .slick-next::before': {
    opacity: 0,
    display: 'none',
    right: 0,
  },
  '& .slick-cloned.slick-center': { transform: 'scale(1) !important' },
  '& .slick-prev': {
    position: 'absolute',
    left: -8,
    zIndex: 2,
    width: 42,
    height: 42,
  },
  '& .slick-next': {
    position: 'absolute',
    right: -13,
    zIndex: 2,
    width: 42,
    height: 42,
  },
  '& .slick-slide .slick-active': {
    width: '220px',
  },
  '@tb': {
    padding: '20px 0px',
    '& .slick-prev': {
      left: -6,
      zIndex: 2,
      width: 42,
      height: 42,
    },
    '& .slick-next': {
      right: -10,
      zIndex: 2,
      width: 42,
      height: 42,
    },
  },
  '@mb': {
    padding: '20px 0px',
    '& .slick-prev': {
      left: 15,
      zIndex: 2,
      width: 42,
      height: 42,
    },
    '& .slick-next': {
      right: 10,
      zIndex: 2,
      width: 42,
      height: 42,
    },
  },
  '@fold': {
    '& .slick-prev': {
      left: -4,
      zIndex: 2,
      width: 42,
      height: 42,
    },
    '& .slick-next': {
      right: -11,
      zIndex: 2,
      width: 42,
      height: 42,
    },
  },
});

export default OfferCardSlider;
