import { forwardRef, useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import { useSpring } from '@react-spring/web';
import { Div } from '../../styles/Layout/Div';
import { Row } from '../../styles/Layout/Row';
import { AnimatedDiv } from '../../styles/Layout/AnimatedDiv';
import { Txt } from '../StyleGuide/Components/Txt';
import CountBadge from './SearchPaymentMethod/CountBadge';

function Tab({
  alignLeft,
  children,
  css,
  commonCss,
  titleTxtCss,
  color = '$primary',
  containerCss,
  init = 0,
  animated = true,
  tabChangeAction = (tab) => {},
}) {
  const [state, setState] = useState(init);
  const [parentRef, pRect, forceRefresh] = useMeasure();
  const [ref, rect, childRefresh] = useMeasure();

  const sp = useSpring({
    left: rect.left,
  });

  useEffect(() => {
    forceRefresh();
    tabChangeAction(state);
  }, [state]);

  return (
    <Div css={containerCss} ref={parentRef}>
      <Row
        css={{
          position: 'relative',
          width: '100%',
          justifyContent: alignLeft ? 'null' : 'center',
          alignItems: 'center',
          marginBottom: 30,
          borderBottom: '1px solid $mypageLine',
          ...css,
          '@mb': { width: '100%', justifyContent: 'center' },
        }}
      >
        {children.map((child, index) => {
          if (index === state) {
            return (
              <TabHeader
                fontSize={child.props?.fontSize}
                ref={ref}
                key={index}
                id={index}
                title={child.props.title}
                titleTxtCss={titleTxtCss}
                commonCss={commonCss}
                cnt={child.props.cnt}
                clickHandler={child.props.clickHandler}
                animated={animated}
                state={state}
                setState={setState}
                color={color}
              />
            );
          } else {
            return (
              <TabHeader
                fontSize={child.props?.fontSize}
                key={index}
                id={index}
                title={child.props.title}
                cnt={child.props.cnt}
                commonCss={commonCss}
                clickHandler={child.props.clickHandler}
                titleTxtCss={titleTxtCss}
                animated={animated}
                state={state}
                setState={setState}
                color={color}
              />
            );
          }
        })}
        {animated && (
          <AnimatedDiv
            style={{
              left: sp.left.to((x) => x - pRect.left),
            }}
            css={{
              position: 'absolute',
              width: rect.width,
              top: rect.height + 18,
              height: 2,
              background: color,
            }}
          />
        )}
      </Row>
      {children[state]}
    </Div>
  );
}

const TabHeader = forwardRef(
  (
    {
      fontSize,
      clickHandler = () => {},
      title,
      id,
      state,
      setState,
      color,
      cnt = 0,
      animated,
      titleTxtCss,
      commonCss,
    },
    ref,
  ) => {
    const common = {
      margin: animated ? '10px 0' : '0px',
      padding: animated ? 10 : 20,
      paddingLeft: 30,
      paddingRight: 30,
      cursor: 'pointer',
      alignItems: 'center',
      gap: 5,
      ...commonCss,
      '@mb': {
        paddingLeft: 20,
        paddingRight: 20,
        ...commonCss,
      },
    };

    const active = {
      ...common,
      // borderBottom: '2px solid $orange',
      borderBottom: animated ? 'none' : '2px solid $primary',
    };
    const disable = {
      ...common,
    };

    function click(id) {
      clickHandler();
      setState(id);
    }

    return (
      <Row
        css={state === id ? active : disable}
        onClick={() => click(id)}
        ref={ref}
      >
        <Txt
          h5
          css={{
            color: state === id ? color : '$textDisable',
            fontSize: fontSize,
            userSelect: 'none',
            ...titleTxtCss,
            '@mb': {
              fontSize: 14,
              fontFamily: 'SpoqaHanSansNeo-Bold',
              fontStyle: 'normal',
              fontWeight: 700,
              /* identical to box height, or 21px */
              fontFeatureSettings: "'pnum' on, 'lnum' on",
              ...titleTxtCss,
            },
            '@tb': {
              fontSize: 16,
              fontFamily: 'SpoqaHanSansNeo-Bold',
              fontStyle: 'normal',
              fontWeight: 700,
              /* identical to box height, or 21px */
              fontFeatureSettings: "'pnum' on, 'lnum' on",
            },
          }}
        >
          {title}
        </Txt>
        {!!cnt && (
          <CountBadge
            count={cnt}
            color={state === id ? '$primary' : '$textDisable'}
          />
        )}
      </Row>
    );
  },
);

function Item({ title, content }) {
  return content;
}

Tab.Item = Item;

export default Tab;
