import React from 'react';
import { Section } from '../../../../styles/Layout/Section';
import { styled } from '../../../../styles/stitches.config';
import { Row } from '../../../../styles/Layout/Row';
import { Img } from '../../../StyleGuide/Components/Img';
import { Col } from '../../../../styles/Layout/Col';
import { Txt } from '../../../StyleGuide/Components/Txt';
import { Icon } from '../../../Shared/Icon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const WalletContents = () => {};
export const MenuContents = () => {
  const { t } = useTranslation('common');
  return (
    <ContentsContainer>
      <ContentsItem
        title={t('about').toUpperCase()}
        icon={'/images/icon-checklist-dark.svg'}
        url={'/about'}
        first={true}
      />
      <ContentsItem
        title={t('vendor')}
        icon={'/images/icon-partnership-dark.svg'}
        url={'/vendor'}
      />
      <ContentsItem
        title={t('afilliated')}
        icon={'/images/icon-refer-dark.svg'}
        url={'/afilliate'}
      />
      <ContentsItem
        title={t('card')}
        icon={'/images/icon-credit-card-dark.svg'}
        url={'/card'}
      />
      <ContentsItem
        title={t('payment-program').toUpperCase()}
        icon={'/images/icon-all-payment-dark.svg'}
        url={'/payment-program'}
        last={true}
      />
    </ContentsContainer>
  );
};

export const BuyContents = () => {
  const { t } = useTranslation('common');
  const list = useSelector((state) => state.asset.list);
  const keys = Object.keys(list);
  return (
    <ContentsContainer>
      {keys.map((item, index) => {
        return (
          <ContentsItem
            icon={`/images/assets/${list[item].name}.svg`}
            title={t('buy01', { coin: list[item].name.toUpperCase() })}
            sub={t('buy01_sub', { coin: list[item].name })}
            url={`/offer/buy/${list[item].name.toLowerCase()}`}
            first={index === 0}
            last={index === keys.length - 1}
          />
        );
      })}
    </ContentsContainer>
  );
};

export const SellContents = () => {
  const { t } = useTranslation('common');
  const list = useSelector((state) => state.asset.list);
  const keys = Object.keys(list);
  return (
    <ContentsContainer>
      {keys.map((item, index) => {
        return (
          <ContentsItem
            icon={`/images/assets/${list[item].name}.svg`}
            title={t('sell01', { coin: list[item].name.toUpperCase() })}
            sub={t('sell01_sub', { coin: list[item].name })}
            url={`/offer/sell/${list[item].name.toLowerCase()}`}
            first={index === 0}
            last={index === keys.length - 1}
            key={index + new Date().toString()}
          />
        );
      })}
    </ContentsContainer>
  );
};

export const ConvertContents = () => {
  const { t } = useTranslation('common');

  return (
    <ContentsContainer>
      <ContentsItem
        icon={'/images/icon-bitcoin-dark.svg'}
        title={t('convert01')}
        sub={t('convert01_sub')}
        url={'/offer/convert/btc'}
        first={true}
      />
      <ContentsItem
        icon={'/images/icon-tether-dark.svg'}
        title={t('convert02')}
        sub={t('convert02_sub')}
        url={'/offer/convert/usdt'}
      />
      <ContentsItem
        icon={'/images/icon-usd-coin-dark.svg'}
        title={t('convert03')}
        sub={t('convert03_sub')}
        url={'/offer/convert/usdc'}
        last={true}
      />
    </ContentsContainer>
  );
};

const ContentsItem = ({ icon, title, sub, first, last, url, scale = 1 }) => {
  const navigate = useNavigate();

  return (
    <ContentsItemContainer
      css={{
        borderTopLeftRadius: first ? 6 : 0,
        borderTopRightRadius: first ? 6 : 0,
        borderBottomLeftRadius: last ? 6 : 0,
        borderBottomRightRadius: last ? 6 : 0,
      }}
      onClick={() => {
        navigate(url);
      }}
    >
      <Row
        css={{
          alignItems: 'center',
        }}
      >
        <Img
          src={icon}
          css={{
            width: 38.5,
            height: 38.5,
            marginRight: 13.75,
            scale: scale,
          }}
        />
        <Col>
          <Txt d5>{title}</Txt>
          <Txt
            b5
            css={{
              color: '$navSubText',
            }}
          >
            {sub}
          </Txt>
        </Col>
      </Row>
      <Icon src={'/images/icon-next.svg'} />
    </ContentsItemContainer>
  );
};

const ContentsContainer = styled(Section, {
  width: '450px',
  border: '1px solid $opacity',
  borderRadius: 6,
});

const ContentsItemContainer = styled(Row, {
  width: '100%',
  height: 78,
  paddingLeft: 16.75,
  paddingRight: 20,
  paddingTop: 19.75,
  paddingBottom: 19.75,
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',

  '&:hover': {
    background: '$hoverBg',
    border: '1px solid $opacity',
  },
});
