import { Row } from '../../../styles/Layout/Row';
import { Icon } from '../Icon';
import React, { useEffect, useState } from 'react';
import { Img } from '../../StyleGuide/Components/Img';
import { CountryButton } from '../CountryButton/CountryButton';
import { Section } from '../../../styles/Layout/Section';
import { Col } from '../../../styles/Layout/Col';
import InputBoxLarge from '../InputBoxLarge/InputBoxLarge';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Div } from '../../../styles/Layout/Div';
import { useTranslation } from 'react-i18next';

export const CountryPicker = ({
  css,
  setValue,
  list,
  setList,
  country,
  setCountry,
  setInputValue,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [on, setOn] = useState(false);

  const keys = Object.keys(list);

  return (
    <CountryButton
      setOn={setOn}
      on={on}
      left={-15}
      css={css}
      contents={
        <CountryButtonContainer
          setOn={setOn}
          setInputValue={setInputValue}
          keys={keys}
          list={list}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          setCountry={setCountry}
        />
      }
    >
      <Img
        src={`/images/flags/${country?.countryCode}.svg`}
        css={{
          width: 24,
          height: 24,
          borderRadius: 5,
        }}
      />
    </CountryButton>
  );
};

const CountryButtonContainer = ({
  setOn,
  setInputValue,
  keys,
  list,
  setSearchQuery,
  searchQuery,
  searchResults,
  setSearchResults,
  setCountry,
}) => {
  const { t } = useTranslation('common');

  function onChange(e) {
    setSearchQuery(e.target.value);
  }

  // 검색어 기능
  const filteredData = list.filter((item) => {
    let checkSlug = item?.slug
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    let checkName = item?.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    let checkCountryCode = item?.countryCode
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    let checkCountryPhoneCode = item?.countryPhoneCode
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return checkSlug || checkName || checkCountryCode || checkCountryPhoneCode;
  });

  useEffect(() => {
    return () => setSearchQuery('');
  }, []);

  return (
    <Section
      css={{
        maxWidth: 445,
        background: '$bg',
        border: '1px solid $opacity',
        padding: 15,
        borderRadius: 6,
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginTop: 10,
        alignItems: 'center',
        justifyContent: 'center',
        '@mb': {
          maxWidth: 328,
        },
        '@fold': {
          maxWidth: 250,
        },
      }}
    >
      <InputBoxLarge
        css={{ width: '100%', maxWidth: 445 }}
        value={searchQuery}
        onChange={onChange}
      />
      <Col
        css={{
          flexWrap: 'nowrap',
          height: 250,
          overflowY: 'scroll',
          width: '100%',
          maxWidth: 445,
          marginTop: 15,
          alignItems: 'center',
          '@mb': {
            height: 200,
          },
        }}
      >
        {searchQuery === ''
          ? list?.map((el, i) => {
              return (
                <Row
                  onClick={() => {
                    setCountry(el);
                    setOn(false);
                  }}
                  css={{
                    padding: 10,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxWidth: '100%',
                    width: 445,
                    height: 50,
                    borderRadius: 10,
                    cursor: 'pointer',
                    '&:hover': {
                      background: '$boxHoverBg',
                    },
                  }}
                  key={i}
                >
                  <Img
                    css={{ width: 24, height: 24, borderRadius: 5 }}
                    src={`/images/flags/${el.countryCode}.svg`}
                  />
                  {/*<Div b4 css={{position: "absolute", left: 45,}}>*/}
                  <Txt css={{ position: 'absolute', left: 45 }} b4>
                    {el.name}
                  </Txt>
                  {/*</Div>*/}
                  <Txt css={{ textAlign: 'center' }} b4>
                    {'+' + el.countryPhoneCode}
                  </Txt>
                </Row>
              );
            })
          : filteredData?.map((el, i) => {
              return (
                <Row
                  onClick={() => {
                    setCountry(el);
                    setOn(false);
                  }}
                  css={{
                    padding: 10,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxWidth: '100%',
                    width: 445,
                    height: 50,
                    borderRadius: 10,
                    cursor: 'pointer',
                    '&:hover': {
                      background: '$boxHoverBg',
                    },
                  }}
                  key={i}
                >
                  <Img
                    css={{ width: 24, height: 24, borderRadius: 5 }}
                    src={`/images/flags/${el.countryCode}.svg`}
                  />
                  <Div b4 css={{ position: 'absolute', left: 45 }}>
                    <Txt>{el.name}</Txt>
                  </Div>
                  <Txt b4 css={{ marginRight: 10 }}>
                    {'+' + el.countryPhoneCode}
                  </Txt>
                </Row>
              );
            })}
        {filteredData.length === 0 && searchQuery !== '' && (
          <Col
            css={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 5,
              width: 445,
              height: '100%',
            }}
          >
            <Icon
              css={{ width: 60, height: 60 }}
              src={'/images/icon-search-result.svg'}
            />
            <Txt css={{ color: '$subText' }} b4>
              No search results for "{searchQuery}"
            </Txt>
          </Col>
        )}
      </Col>
    </Section>
  );
};
