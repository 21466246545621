import { useState, useEffect } from 'react';
import { mediaConfig } from '../../styles/stitches.config';

export function useMediaQuery(query) {
  if (mediaConfig.hasOwnProperty(query) === false)
    throw new Error(`useMediaQuery: ${query} is not defined in mediaConfig`);

  const [matches, setMatches] = useState(
    window.matchMedia(mediaConfig[query]).matches,
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaConfig[query]);
    const documentChangeHandler = () => setMatches(mediaQueryList.matches);

    mediaQueryList.addListener(documentChangeHandler);

    return () => {
      mediaQueryList.removeListener(documentChangeHandler);
    };
  }, [query]);

  return matches;
}
