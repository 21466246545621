import { createAction } from 'redux-actions';
import { getUserWebNotification } from '../../api/userService';
import { readAllNotificationRequest } from '../../api/notificationService';

export const loadNotification = () => {
  return async (dispatch, getState) => {
    try {
      const { loading } = getState().method;
      if (loading) {
        return;
      }
      let res = await getUserWebNotification();
      dispatch({ type: initNotification, payload: res });
    } catch (e) {
      console.log(e);
    }
  };
};

export const readAllNotificationAction = () => {
  return async (dispatch, getState) => {
    try {
      const { loading } = getState().method;
      if (loading) {
        return;
      }

      console.log('readAllNotificationAction');
      await readAllNotificationRequest();
      dispatch({ type: readAllNotification });
    } catch (e) {
      console.log(e);
    }
  };
};

export const moreNotification = (page) => {
  return async (dispatch, getState) => {
    try {
      const { loading } = getState().method;
      if (loading) {
        return;
      }
      let res = await getUserWebNotification(page);
      dispatch({ type: addNotification, payload: res });
    } catch (e) {
      console.log(e);
    }
  };
};

export const initNotification = createAction('INIT_NOTIFICATION');
export const addNotification = createAction('ADD_NOTIFICATION');
export const readNotification = createAction('READ_NOTIFICATION');
export const readAllNotification = createAction('READ_ALL_NOTIFICATION');
