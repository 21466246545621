import React, { useEffect } from 'react';
import {
  darkTheme,
  globalStyles,
  lightTheme,
} from '../../styles/stitches.config';
import { useSelector } from 'react-redux';

export default function ThemeProvider({ children }) {
  const theme = useSelector((state) => state.theme.type);
  globalStyles();

  return (
    <div className={theme === 'light' ? lightTheme : darkTheme}>{children}</div>
  );
}
