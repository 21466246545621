import axios from 'axios';
import { refreshToken } from '../authService';

export const apiURL = 'https://livechat.excoinz.com';
export const fileURL = 'https://www.excoinz.com';
export const chatURL = 'https://livechat.excoinz.com';
export const webURL  = 'https://www.excoinz.com';

let isRefreshing = false;
let refreshSubscribers = [];

let client = axios.create({
  baseURL: apiURL,
  credentials: true,
  headers: {
    'x-lang': 'en',
  },
  validateStatus: function (status) {
    return status <= 401;
  },
});

// 리프레쉬 토큰 요청이 완료되면 대기 중인 요청들을 실행
function onRefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
}

// 리프레쉬 토큰 요청 중일 때 대기 중인 요청을 추가
function addSubscriber(callback) {
  refreshSubscribers.push(callback);
}

client.interceptors.response.use(async (res) => {
  const { config, status } = res;
  console.log('interceptors.response', res.status, config.url);

  if (status === 401 && !!config._retry === false) {
    // _retry 플래그를 사용해 이미 재시도한 요청인지 확인
    const originalRequest = config;
    config._retry = true; // 재시도 플래그 설정

    if (!isRefreshing) {
      isRefreshing = true;
      refreshToken()
        .then((token) => {
          if (!!token === false) {
            throw new Error('refresh token error');
          }

          isRefreshing = false;
          onRefreshed(token);
          refreshSubscribers = [];
        })
        .catch((err) => {
          // 리프레쉬 토큰 요청 실패 시, 오류 처리 로직 추가
          console.error('Refresh token request failed:', err);
          window.location.reload();
        });
    }

    const retryOriginalRequest = new Promise((resolve, reject) => {
      addSubscriber((token) => {
        console.log(token);
        originalRequest.headers['authorization'] =
          `Bearer ${token?.accessToken}`;
        resolve(client(originalRequest));
      });
    });

    return retryOriginalRequest;
  } else if (status === 500) {
    return Promise.reject('too many request');
  }
  return res;
});
const onSuccess = function (response) {
  return response.data;
};

const onError = function (error) {
  return false;
};

export const request = async function (options) {
  return client(options).then(onSuccess).catch(onError);
};

export const ACCESS_TOKEN_KEY = 'ex_a_token';
export const REFRESH_TOKEN_KEY = 'ex_r_token';

export const setToken = (token) => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token?.accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, token?.refreshToken);
  client.defaults.headers.common['authorization'] =
    `Bearer ${token?.accessToken}`;
};

export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const clearToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  client.defaults.headers.common['authorization'] = undefined;
};

const initToken = () => {
  let access = window.localStorage.getItem(ACCESS_TOKEN_KEY);
  client.defaults.headers.common['authorization'] = `Bearer ${access}`;
};

export const changeServerLang = (lang) => {
  console.log('changeServerLang', lang);
  client.defaults.headers.common['x-lang'] = lang;
};

initToken();

export default client;
