import { handleActions } from 'redux-actions';
import { changeDarkMode, changeLightMode } from '../actions/themeAction';

export const defaultState = {
  type: 'dark',
};

export default handleActions(
  {
    [changeLightMode](state, data) {
      return {
        type: 'light',
      };
    },
    [changeDarkMode](state, data) {
      return {
        type: 'dark',
      };
    },
  },
  defaultState,
);
