import React from 'react';
import { Button } from '../../StyleGuide/Components/Button';
import { LoadingIndicator } from '../LoadingIndicator';
import { Row } from '../../../styles/Layout/Row';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useSelector } from 'react-redux';

function NetworkButton({ children, onClick, css, ...props }) {
  const loading = useSelector((state) => state.loading.isLoading);

  return (
    <Button
      disabled={loading}
      {...props}
      onClick={async () => {
        await onClick();
      }}
      css={css}
    >
      {loading ? (
        <Row>
          <Txt css={{ color: '$darkGray' }}>{children}</Txt>
          <LoadingIndicator css={{ position: 'absolute', left: 210 }} />
        </Row>
      ) : (
        children
      )}
    </Button>
  );
}

export default NetworkButton;
