import { createAction } from 'redux-actions';
import { getAssetPrice, getTransactionalAsset } from '../../api/assetService';
import { getUserBalance } from '../../api/walletService';
import { updateUserCurrencyComplete } from './userAction';

export const updateBalance = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getUserBalance();
      dispatch({ type: updateBalanceComplete, payload: res });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateAssetInfo = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: assetUpdateStart });
      let res = await getTransactionalAsset();
      dispatch({ type: assetUpdateComplete, payload: res });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateCurrency = (id) => {
  return async (dispatch) => {
    try {
      let res = await getAssetPrice(id);
      dispatch({ type: updateCurrencyComplete, payload: res });
      dispatch({
        type: updateUserCurrencyComplete,
        payload: { assetId: id, symbol: res[0]?.symbol },
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateBalanceComplete = createAction('ASSET_UPDATE_BALANCE');

export const assetUpdateStart = createAction('ASSET_UPDATE_START');

export const assetUpdateComplete = createAction('ASSET_UPDATE_COMPLETE');

export const updateCurrencyComplete = createAction(
  'ASSET_UPDATE_CURRENCY_COMPLETE',
);
