import { Col } from '../../../styles/Layout/Col';
import { Txt } from '../../StyleGuide/Components/Txt';
import { Row } from '../../../styles/Layout/Row';
import { Icon } from '../../Shared/Icon';
import InputPasswordBox from '../../Shared/InputPasswordBox/InputPasswordBox';
import { useMediaQuery } from '../../Shared/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import NetworkButton from '../../Shared/Buttons/NetworkButton';
import InputBox from '../../Shared/InputBox/InputBox';
import { Span } from '../../../styles/Layout/Span';
import { loginExcoinz } from '../../../api/userService';
import { useDispatch } from 'react-redux';
import { endLoading, startLoading } from '../../../redux/actions/loadingAction';
import { userLoginFromJwt } from '../../../redux/actions/userAction';
import { createToast } from '../../../redux/actions/toastAction';
export default function Login({ width }) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneNumberRegex =
    /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;
  const navigate = useNavigate();

  const isDesktop = useMediaQuery('dt');
  const { t } = useTranslation('auth');

  const [id, setId] = useState('');
  const [pwd, setPwd] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const idRef = useRef(id);
  const pwdRef = useRef(pwd);

  async function callLoginProcess() {
    if (loginValidate() === false) {
      return;
    }
    dispatch({ type: startLoading });

    await login(idRef.current, pwdRef.current);

    // if (window?.captchaObj) {
    //     window?.captchaObj?.showCaptcha();
    // } else {
    //     dispatch({type:createToast, payload:{
    //             msg: 'not captcha object',
    //             duration: 2000,
    //             success:false,
    //         }});
    //     return false;
    // }
  }

  async function login(id, pwd) {
    //let result = window.captchaObj.getValidate();
    let success = await loginExcoinz(idRef.current, pwdRef.current, '');
    dispatch({ type: endLoading });
    console.dir(success);
    if (!!success?.accessToken === false) {
      dispatch({
        type: createToast,
        payload: {
          msg: t('not_allowed_feedback'),
          duration: 2000,
          success: false,
        },
      });
    } else {
      dispatch(userLoginFromJwt(success));
    }
  }

  function loginValidate() {
    if (id.length < 1) {
      dispatch({
        type: createToast,
        payload: {
          msg: t('not_input_email'),
          duration: 2000,
          success: false,
        },
      });
      return false;
    }
    if (pwd.length < 8) {
      dispatch({
        type: createToast,
        payload: {
          msg: t('over_eight_pass'),
          duration: 2000,
          success: false,
        },
      });
      return false;
    }
    return true;
  }

  function inputEmailChange(pwd) {
    setPwd(pwd);
    pwdRef.current = pwd;
    if (pwd.length >= 6) {
      setError('');
    }
  }

  return (
    <Row
      css={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Col
        css={{
          width: '100%',
          maxWidth: 445,
          '@mb': { maxWidth: '100%' },
        }}
      >
        <Txt
          css={{
            width: '100%',
            textAlign: 'left',
            '@mb': {
              fontSize: 22,
              textAlign: 'left',
            },
          }}
          h3
        >
          {t('login_title')}
        </Txt>
        <Row
          css={{
            borderRadius: 10,
            border: '1px solid $primary',
            marginTop: 20,
            gap: 10,
            width: '100%',
            // width: '90%',
            minHeight: 68,
            padding: 15,
            flexWrap: 'nowrap',
          }}
        >
          <Icon
            css={{
              width: 16,
              height: 16,
            }}
            src={'/images/icon-info.svg'}
          />
          <Col>
            <Txt
              css={{
                textAlign: 'left',
                color: '$scrollColor',
                '@mb': { textAlign: 'start' },
              }}
              b5
            >
              {t('important')}
            </Txt>
            <Span
              css={{
                marginLeft: 3,
                fontWeight: 700,
                color: '$deepGrayToWhite',
              }}
            >
              https://www.excoinz.com/
            </Span>
          </Col>
        </Row>

        {
          // || !emailRegex.test(id) || !phoneNumberRegex.test(id) && (id && id.length > 0) && (pwd && pwd.length > 0)
          (id.length > 0 || pwd.length > 0) &&
            !emailRegex.test(id) &&
            !phoneNumberRegex.test(id) && (
              <Row
                css={{
                  borderRadius: 10,
                  border: '1px solid $lightRed',
                  background: 'rgba(255, 121, 124, 0.15);',
                  marginTop: 20,
                  gap: 10,
                  width: '100%',
                  minhHeight: 68,
                  padding: 15,
                }}
              >
                <Txt
                  css={{
                    textAlign: 'left',
                    color: '$subText',
                    '@mb': { textAlign: 'start' },
                  }}
                  b5
                >
                  {t('valid_email')}
                </Txt>
              </Row>
            )
        }

        {/*email*/}
        <Col
          css={{
            marginTop: 20,
            gap: 4,
            width: '100%',
          }}
        >
          <Txt css={{ marginLeft: 15 }} b6>
            {t('phone_email')}
          </Txt>
          <InputBox
            id={id}
            setId={(data) => {
              setId(data);
              idRef.current = data;
            }}
            css={{
              maxWidth: 445,
              width: '100%',
              '@mb': { maxWidth: '100%' },
            }}
          />
        </Col>
        {/*password*/}
        <Col
          css={{
            marginTop: 20,
            gap: '4px',
            width: '100%',
          }}
        >
          <Txt css={{ marginLeft: 15 }} b6>
            {t('password')}
          </Txt>
          <InputPasswordBox
            setError={setError}
            error={error}
            width={445}
            mbWidth={328}
            password={pwd}
            pressEnter={callLoginProcess}
            setPassword={inputEmailChange}
          />
        </Col>

        <Txt
          onClick={() => navigate('/login/forgot')}
          css={{
            marginTop: 15,
            color: '$primary',
            cursor: 'pointer',
          }}
          e2
        >
          {t('forgot')}
        </Txt>
        <NetworkButton
          onClick={callLoginProcess}
          css={{
            maxWidth: 445,
            width: '100%',
            marginTop: 30,
            '@mb': { maxWidth: '100%' },
          }}
          lg
        >
          {t('login')}
        </NetworkButton>
        <Row
          css={{
            marginTop: 15,
            gap: 5,
          }}
        >
          <Txt css={{ color: '$subText' }} b4>
            {t('signin_guide')}
          </Txt>
          <Txt
            onClick={() => navigate('/register')}
            css={{
              color: '$primary',
              cursor: 'pointer',
              '@mb': { fontSize: 15 },
            }}
            e2
          >
            {t('create_account')}
          </Txt>
        </Row>
      </Col>
      {/*<Captcha captchaId={"97674208fffdc1fe1f67d85ee2e4b46d"}*/}
      {/*         cancelCallback={() => {*/}
      {/*             dispatch({type: endLoading})*/}
      {/*         }}*/}
      {/*         successCallback={login}/>*/}
    </Row>
  );
}
